import classnames from 'classnames';
import FormField from '../component/field-form';
import PropTypes from 'prop-types';
import ResultField from '../component/field-result';
import React, { Component } from 'react';

const FIELDS = {
    'Field': FormField,
    'Result': ResultField
};

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isValid: this.props.isValid,
            validated: this.props.validated
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            isValid: nextProps.isValid,
            validated: nextProps.validated
        });
    }


    // Render
    renderActions() {
        if (this.props.isEditable && this.props.isOpen === false) {
            return (
                <div role="group" className="actions">
                    {/* <button type="button" className="button quinary medium">Remove</button> */}
                    <button type="button" className="button secondary medium" onClick={this.props.onEdit.bind(this)}>Edit</button>
                </div>
            );
        }
    }

    renderFields() {
        const FieldType = FIELDS[this.props.fieldType];

        return this.props.fields.map((props, index) => {
            // This function is passed as a prop to hide specific fields
            if (this.props.showField(props)) {
                return (<FieldType key={index} {...props} />);
            }
        });
    }

    renderForm() {
        if (this.props.isOpen && this.props.isEditable) {
            return (
                <div className="section-content">
                    <div className="inner full-width || constrain-width medium">
                        <div className="form">
                            <div role="group" className="fieldset">{this.renderFields()}</div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderHeader() {
        return (
            <header role="header" className="section-intro" id={this.props.id}>
                <div className="inner">
                    <h2 className="title centered || thin">{this.props.title}</h2>
                </div>
                {this.renderActions()}
            </header>
        );
    }

    render() {
        let sectionClass = classnames('page-section', {
            'is-open': this.props.isOpen,
            'is-valid': this.state.validated && this.state.isValid,
            'is-invalid': this.state.validated && this.state.isValid === false
        });

        if (this.props.isShowing) {
            return (
                <section className={sectionClass} id={this.props.id}>
                    {this.renderHeader()}
                    {this.renderForm()}
                </section>
            );
        }

        return null;
    }
}

Form.propTypes = {
    fields: PropTypes.array.isRequired,
    fieldType: PropTypes.string,
    id: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    isOpen: PropTypes.bool,
    isShowing: PropTypes.bool,
    isValid: PropTypes.bool,
    onEdit: PropTypes.func,
    showField: PropTypes.func,
    title: PropTypes.string.isRequired,
    validated: PropTypes.bool
};

Form.defaultProps = {
    fieldType: 'Field',
    isEditable: true,
    isOpen: true,
    isShowing: true,
    isValid: false,
    onEdit: function () { }, // eslint-disable-line
    showField: function () { return true; }, // eslint-disable-line
    validated: false
};

export default Form;
