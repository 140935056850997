import DialogActions from '../action/dialog-actions';
import { FORM_CONTROLS_HOOK } from '../config/app';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class FormControls extends Component {
    constructor(props) {
        super(props);
    }


    // Helpers
    back() {
        this.props.history.goBack();
    }


    // Handlers
    handleCancel() {
        DialogActions.confirm('You will lose any unsaved changes.', this.back.bind(this));
    }


    // Renders
    renderActions() {
        return (
            <div className="inner || constrain-width">
                <div className="button-group">
                    {this.renderCancel()}
                    {this.renderContinue()}
                    {this.renderSave()}
                    {this.renderDelete()}
                </div>
            </div>
        );
    }

    renderCancel() {
        if (this.props.types.indexOf('cancel') > -1) { // eslint-disable-line
            if (this.props.hasOwnProperty('handleCancel')) {
                return (<button type="button" className="item || link" onClick={this.props.handleCancel.bind(this)}>Cancel</button>);
            } else {
                return (<button type="button" className="item || link" onClick={this.handleCancel.bind(this)}>Cancel</button>);
            }
        }
    }

    renderContinue() {
        if (this.props.types.indexOf('continue') > -1) { // eslint-disable-line
            return (<button type="submit" className="item || button primary">Continue</button>);
        }
    }

    renderDelete() {
        // TODO: TNZSLA-70: Temp fix is to disable delete button , Wouter/Bettina to confirm best course of action in deleting users
        // return <p>fdgdgfd</p>;

        if (this.props.types.indexOf('delete') > -1) { // eslint-disable-line
            return (<button type="button" className="item || button warning" onClick={this.props.handleDelete.bind(this)}>Deactivate</button>);
        }
    }

    renderSave() {
        if (this.props.types.indexOf('save') > -1) { // eslint-disable-line
            return (<button type="submit" className="item || button primary">Save</button>);
        }
    }

    render() {
        return (
            <div className="page-actions" data-hook={FORM_CONTROLS_HOOK}>
                { this.renderActions() }
            </div>
        );
    }
}

FormControls.propTypes = {
    handleDelete: PropTypes.func,
    types: PropTypes.array
};

FormControls.defaultProps = {
    handleDelete: function() {} // eslint-disable-line no-empty-function
};

export default withRouter(FormControls);
