import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class ButtonDropdown extends Component {
    constructor(props) {
        super(props);

        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            isOpen: false
        };
    }


    // Mounting
    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick);
    }


    // Handlers
    handleClick(action) {
        // Close dropdown
        this.setState({ isOpen: false });

        // Fire function
        if (typeof action === 'function') action();

        // Assume redirect link
        if (typeof action === 'string') this.props.history.push(action);
    }

    handleOutsideClick(event) {
        let target = event.target;

        if (this.state.isOpen && !target.hasAttribute('data-ignore')) {
            this.setState({ isOpen: false });
        }
    }

    handleToggle(event) {
        event.preventDefault();

        // Toggle is open state
        this.setState({ isOpen: !this.state.isOpen });
    }


    // Render
    renderButtons() {
        let buttonList = [],
            { buttons } = this.props;

        buttons.forEach((item, index) => {
            buttonList.push(<button type="button" className="item" key={index} onClick={this.handleClick.bind(this, item.action)}>{item.label}</button>);
        });

        return buttonList;
    }

    renderIcon() {
        let { icon } = this.props;
        if (icon !== '') {
            return (
                <svg viewBox="0 0 40 40" width="40" height="40" className="icon" data-ignore="true">
                    <use xlinkHref={`/assets/icons/_sprite.svg#${icon}`} data-ignore="true"/>
                </svg>
            );
        }

        return (
            <svg viewBox="0 0 40 40" width="40" height="40" className="icon chevron" data-ignore="true">
                <use xlinkHref="/assets/icons/_sprite.svg#chevron" data-ignore="true" />
            </svg>
        );
    }

    renderPrimaryButton() {
        let buttonClass = 'button || main-button',
            { action, label } = this.props;

        if (label === '') {
            buttonClass += ' no-label';
        }

        return (<button type="button" className={buttonClass} onClick={this.handleClick.bind(this, action)}><span className="button-label">{label}</span></button>);
    }

    renderTrigger() {
        if (this.props.buttons.length > 0) { // eslint-disable-line no-magic-numbers
            return (
                <button type="button" role="button" className="trigger" onClick={this.handleToggle.bind(this)} data-ignore="true">
                    {this.renderIcon()}
                </button>
            );
        }
    }

    render() {
        let containerClass = classnames({
            'button-dropdown': true,
            'is-open': this.state.isOpen,
            [`${this.props.class}`]: this.props.class
        });

        if (this.state.isOpen) {
            window.addEventListener('click', this.handleOutsideClick);
        } else {
            window.removeEventListener('click', this.handleOutsideClick);
        }

        return (
            <div className={containerClass}>
                <div className="trigger-wrapper">
                    {this.renderPrimaryButton()}
                    {this.renderTrigger()}
                </div>
                <div role="group" className="buttons">{this.renderButtons()}</div>
            </div>
        );
    }
}

ButtonDropdown.propTypes = {
    action: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string
    ]),
    buttons: PropTypes.array,
    history: PropTypes.object,
    class: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string
};

ButtonDropdown.defaultProps = {
    action: function() {}, // eslint-disable-line no-empty-function
    buttons: [],
    history: {},
    icon: '',
    label: ''
};

export default withRouter(ButtonDropdown);
