import classnames from 'classnames';
import closest from '../../utility/closest';
import DialogActions from '../../action/dialog-actions';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import NotificationActions from '../../action/notification-actions';
import Permission from '../../service/permission-service';
import PropTypes from 'prop-types';
import { request } from '../../utility/request';
import { responseHasErrors } from '../../utility/form';
import UserActions from '../../action/user-actions';
import UserStore from '../../store/user-store';
import { Link, NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class Header extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = this.initialState();
    }

    initialState() {
        const { user } = UserStore.getState();

        return {
            currentOrganisation: user ? user.organisation : null,
            email: '',
            error: '',
            loading: false,
            isLoggedIn: UserStore.isLoggedIn(),
            password: '',
            userOrganisations: user ? user.userOrganisations : []
        };
    }

    // Mounting
    componentDidMount() {
        UserStore.listen(this.onChange);
    }

    componentWillUnmount() {
        UserStore.unlisten(this.onChange);
    }


    // Event
    onChange() {
        const isLoggedIn = UserStore.isLoggedIn();
        const { user } = UserStore.getState();

        if (user) {
            this.setState({
                currentOrganisation: user.organisation,
                userOrganisations: user.userOrganisations,
                isLoggedIn: isLoggedIn,
            });
        } else {
            this.setState({
                isLoggedIn
            });
        }
    }

    // Handler
    /**
     * Handler for Logout button.
     */
    handleLogout() {
        this.props.history.push('/confirm-logout');
    }

    handlePublishAllResults() {

        request(ENDPOINTS.RESULTS_PUBLISH_RETRIEVE_MONTHS, false, 'GET')
            .then((response) => response.json())
            .then((response) => {
                var month = response.data[0].name;
                var html = (<span dangerouslySetInnerHTML={{ __html: 'All approved results from <b style="color: red;">' +
                        month + '</b> will be published and the reporting period will be <b style="color: red;">permanently closed</b>. ' +
                        'Once closed, <b style="color: red;">no further results can be added</b>.' }} />);
                DialogActions.confirm(html, this.publishAllResults.bind(this), false);
            })
    }

    handleSwitchOrganisations(organisationId) {
        const userId = UserStore.getState().user.id;

        const payload = {
            current_organisation_id: organisationId // eslint-disable-line camelcase
        };

        request(`${ENDPOINTS.USER}/${userId}`, payload, 'PATCH')
            .then((response) => response.json())
            .then((response) => {
                const { message, data } = response;

                if (data && data.id === userId) {
                    UserActions.updateUser(data);
                }
            });
    }

    publishAllResults() {
        request(ENDPOINTS.RESULTS_PUBLISH_ALL, false, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // Add delay
                setTimeout(() => {
                    DialogActions.close(); // Remove loading

                    if (!responseHasErrors(response)) {
                        this.props.history.push(location.pathname); // Reload

                        NotificationActions.push({
                            message: response.message,
                            delay: 1000
                        });
                    }
                }, FAUX_LOADING_TIME);
            })
            .catch(() => {
                DialogActions.close(); // Remove loading

                this.props.history.push(location.pathname); // Reload

                NotificationActions.push({
                    message: 'Request timeout. Please refresh and try again.',
                    delay: 1000,
                    type: 'warning'
                });
            });
    }

    toggleClass(event, className, elementClass) {
        let target = closest(event.target, elementClass);

        if (target.classList.contains(className)) {
            target.classList.remove(className);
        } else {
            target.classList.add(className);
        }
    }

    // Render
    renderCreateResult() {
        if (this.state.isLoggedIn && Permission.check('Results: Create')) {
            return (
                <Link className="button primary || create" to="/result/new" key="create">
                    <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                        <use xlinkHref="/assets/icons/_sprite.svg#plus" />
                    </svg>
                    Create result
                </Link>
            );
        }
    }

    renderDropdown(title, options, isPrimary = false) {
        let buttonClassName = 'button medium block || trigger',
            dropdownClassName = 'dropdown';

        if (isPrimary) {
            buttonClassName = 'button primary || trigger';
            dropdownClassName = 'dropdown primary';
        }

        return (
            <div className={dropdownClassName}>
                <button className={buttonClassName}>
                    <span className="title">{title}</span>
                    <svg viewBox="0 0 40 40" width="16" height="16" className="svg || chevron" aria-hidden="true" focusable="false">
                        <use xlinkHref="/assets/icons/_sprite.svg#chevron"></use>
                    </svg>
                </button>
                <nav role="navigation" className="children">
                    <div className="wrapper">
                        {options}
                    </div>
                </nav>
            </div>
        );
    }

    renderFormErrors() {
        if (this.state.error) {
            return (
                <div className="form-errors">
                    <p className="error">{this.state.error}</p>
                </div>
            );
        }
    }

    /**
     * Renders the dropdown for switching Organisations.
     * @returns {*}
     */
    renderOrganisationSwitcher() {
        // Temporarily disabled.
        if (this.state.isLoggedIn) {
            const { currentOrganisation, userOrganisations } = this.state;

            if (userOrganisations.length > 0) { // eslint-disable-line no-magic-numbers
                const dropdownTitle = currentOrganisation.sub_organisation.name;
                const organisationsAsOptions = userOrganisations.map((organisation) => {
                    const subOrganisation = organisation.sub_organisation;
                    return (
                        <p className="item" key={subOrganisation.id} onClick={() => this.handleSwitchOrganisations(organisation.id)}>
                            {subOrganisation.name}
                        </p>
                    );
                });

                return (
                    this.renderDropdown(dropdownTitle, organisationsAsOptions, true)
                );
            }
        }
    }

    renderSignOutButton() {
        if (this.state.isLoggedIn) {
            return (<button className="button primary || logout" key="logout" onClick={this.handleLogout.bind(this)}>Logout</button>);
        }
    }

    renderNavigation() {
        if (this.state.isLoggedIn && this.props.location.pathname != '/confirm-logout') {
            return (
                <nav className="navigation">
                    <NavLink className="item is-active" activeClassName="is-active" to="/dashboard/page/1">My media coverage</NavLink>
                </nav>
            );
        }
    }

    renderUserActions() {

        if (this.state.isLoggedIn) {
            let links = [];

            if (Permission.check(['Users: Manage all', 'Users: Manage my organisation'])) {
                links.push(<NavLink className="item" to="/users" activeClassName="is-active" key="users">Users</NavLink>);
            }

            if (Permission.check('Organisations: View all') || Permission.check('Organisations: Manage all')) {
                links.push(<NavLink className="item" to="/organisations" activeClassName="is-active" key="organisations">Organisations</NavLink>);
            }

            if (Permission.check('Organisations: Manage all')) {
                links.push(<NavLink className="item" to="/operators/pending" activeClassName="is-active" key="pending-operators">Pending operators</NavLink>);
            }

            if (Permission.check('Results: Save as Published')) {
                links.push(<button className="button septenary || item" onClick={this.handlePublishAllResults.bind(this)} key="publish-results">Release results</button>);
            }

            if (links.length > 0) { // eslint-disable-line

                const dropdownTitle = 'Admin';

                // Add dashboard link to the first in the list only if there are others
                links.unshift(<NavLink className="item" to="/dashboard" activeClassName="is-active" key="dashboard">Dashboard</NavLink>);

                return this.renderDropdown(dropdownTitle, links);
            }
        }
    }

    render() {
        let headerClass = classnames('header', {
            'logged-in' : this.state.isLoggedIn
        });

        return (
            <header className={headerClass} role="banner">
                <div className="bottom">
                    <div>
                        <Link className="logo" to="/dashboard/page/1">
                            <img src="/assets/images/pure-new-zealand-logo.svg" />
                            <h5 className="title">My Tourism New Zealand</h5>
                        </Link>
                    </div>

                    {this.renderNavigation()}

                    {this.props.location.pathname != '/confirm-logout' && (
                        <div role="group" className="button-group">
                            {this.renderUserActions()}
                            {this.renderCreateResult()}
                            {this.renderOrganisationSwitcher()}
                            {this.renderSignOutButton()}
                        </div>
                    )}
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    history: PropTypes.object
};

export default withRouter(Header);
