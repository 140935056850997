import { ENDPOINTS } from '../config/api';
import FilterActions from '../action/filter-actions';
import FilterStore from '../store/filter-store';
import { formatFilters } from '../utility/fields';
import moment from 'moment';
import NotificationActions from '../action/notification-actions';
import Permissions from '../service/permission-service';
import PropTypes from 'prop-types';
import { request } from '../utility/request';
import Select from 'react-select';
import { KEYWORD, ORGANISATION, SEARCH_BY_OPTIONS } from '../config/app';
import React, { Component } from 'react';

const DATE_PLACEHOLDER_LABEL = '-';
const DEFAULT_SELECT_OPTION = { name: 'All', id: '' };
const FORMAT_MONTH = 'MMM';
const FORMAT_YEAR = 'YYYY';
const INITIAL_YEAR = 2014;
const MONTHS_IN_YEAR = 12;

const ORGANISATIONS_LIMIT = 9999;

const FIELD_GROUPS = [
    {
        label: 'From',
        fields: ['monthFrom', 'yearFrom']
    },
    {
        label: 'To',
        fields: ['monthTo', 'yearTo']
    }
];

class Filters extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            data: {
                market: [DEFAULT_SELECT_OPTION],
                resultType: [DEFAULT_SELECT_OPTION],
                rto: [DEFAULT_SELECT_OPTION]
            },
            fields: FilterStore.getState().filters
        };
    }


    // Mounting
    componentDidMount() {
        // Result types
        this.getOptionsFromApi(ENDPOINTS.LIST_RESULT_TYPES, 'resultType');

        // Markets
        this.getOptionsFromApi(ENDPOINTS.LIST_MARKETS, 'market');

        // Regions
        if (this.shouldShowRegionFilter()) {
            this.getOptionsFromApi(ENDPOINTS.LIST_RTOS, 'rto');
        }

        // Operators
        if (this.shouldShowOrganisationFilter()) {
            this.populateSelectOptions();
        }
    }

    getOptionsFromApi(url, label) {
        request(url)
            .then((response) => response.json())
            .then((response) => {
                const { data } = response;

                if (data) {
                    this.formatDataFromApi(data, label);
                }
            });
    }

    populateSelectOptions() {
        return request(`${ENDPOINTS.LIST_ORGANISATIONS}?limit=${ORGANISATIONS_LIMIT}&is_verified=true`)
            .then((response) => response.json())
            .then((response) => {
                let { data } = this.state;

                if (data) {
                    data.organisations = response.data.map(({ id, sub_organisation: subOrganisation }) => {
                        const { name } = subOrganisation;

                        return {
                            value: id,
                            label: name
                        };
                    });

                    this.setState({
                        data
                    });
                }
            });
    }

    prepareFilters() {
        let filters = formatFilters(this.state.fields);

        if (!filters) {
            NotificationActions.push({
                message: 'Invalid dates selected. Please correct and try again.',
                type: 'error'
            });

            return;
        }

        return filters;
    }


    // Event
    onSubmit() {
        let filters = this.prepareFilters();

        if (filters) {
            // Trigger props callback
            this.props.onSubmit(filters);
        }
    }


    // Handler
    handleChange(event) {
        let field = event.target.getAttribute('name'),
            { fields } = this.state,
            value = event.target.value;

        fields[field] = value;

        this.setState({ fields: fields });
        // Save the fields to filters local storage
        FilterActions.save(fields);
    }

    handleSelectChange(value) {
        const { fields } = this.state;

        fields.organisation = value;

        this.setState({
            fields
        });

        FilterActions.save(fields);
    }

    handleSearchByChange(event) {
        const { fields } = this.state;

        switch (event.target.value) {
            case KEYWORD:
                fields.organisation = '';
                break;
            case ORGANISATION:
                fields.search = '';
                break;
            default:
                break;
        }

        this.setState({
            fields
        });

        FilterActions.save(fields);
        this.handleChange(event);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.onSubmit.bind(this);
    }


    // Helpers
    formatDataFromApi(apiData, type) {
        let { data } = this.state,
            options = apiData;

        // Add default option to front
        options.unshift(DEFAULT_SELECT_OPTION);

        // Assign market data
        data[type] = options;

        this.setState({ data: data });
    }

    selectedOption(type) {
        return FilterStore.getState().filters[type];
    }

    shouldShowOrganisationFilter() {
        return (Permissions.check('Results: Edit all') || Permissions.check('Results: Edit my organisation'));
    }

    shouldShowRegionFilter() {
        return (Permissions.check('Results: View region filter'));
    }

    // Render
    renderDateFilters() {
        // const fields = Object.keys(this.state.fields);
        return FIELD_GROUPS.map((fieldGroup, index) => {
            let { fields, label } = fieldGroup,
                returnFields;

            // Loop through fields
            returnFields = fields.map((field, index) => {
                let options;

                if (field.includes('year')) {
                    options = this.renderYearOptions();
                } else {
                    options = this.renderMonthOptions();
                }

                return (
                    <select className="input || select" onChange={this.handleChange} value={this.state.fields[field]} name={field} key={index}>
                        {options}
                    </select>
                );
            });

            return (
                <div className="field-group || split-select || hide-to-desktop" key={index}>
                    <label className="label">{label}</label>
                    {returnFields}
                </div>
            );
        });
    }

    renderOptions(array) {
        return array.map(function(item, index) {
            let value = item;

            // Pass no value for defaults
            if (value === DATE_PLACEHOLDER_LABEL) {
                value = '';
            }

            return (
                <option key={index} value={value}>{item}</option>
            );
        });
    }

    renderOptionsFromData(type) {
        let data = this.state.data[type],
            options = [];

        // Map options
        data.map((item, index) => {
            options.push(<option value={item.id} key={index}>{item.name}</option>);
        });

        return options;
    }

    renderMonthOptions() {
        let count = 0,
            months = [DATE_PLACEHOLDER_LABEL];

        while (count < MONTHS_IN_YEAR) {
            months.push(moment().month(count++).format(FORMAT_MONTH));
        }

        return this.renderOptions(months);
    }

    renderYearOptions() {
        let count = 0,
            total = moment().format('YYYY') - INITIAL_YEAR,
            years = [DATE_PLACEHOLDER_LABEL];

        while (count <= total + 1) { // eslint-disable-line no-magic-numbers
            years.push(moment().year(INITIAL_YEAR + count++).format(FORMAT_YEAR));
        }

        return this.renderOptions(years);
    }

    renderSearchByOptions() {
        return SEARCH_BY_OPTIONS.map((item, index) => {
            return (
                <option value={item.id} key={index}>{item.name}</option>
            );
        });
    }

    renderSearchBy() {
        if (this.shouldShowOrganisationFilter()) {
            return (
                <div className="field-group expand || search">
                    <label className="label">Search by</label>
                    <div className="select-by-wrapper">
                        <select
                            className="Select input || select"
                            classNamePrefix="Select"
                            name="searchBy"
                            onChange={this.handleSearchByChange.bind(this)}
                            value={this.selectedOption('searchBy')}>
                            {this.renderSearchByOptions()}
                        </select>
                    </div>
                    {this.state.fields.searchBy === KEYWORD ?
                        <input
                            type="text"
                            className="Select input search-input"
                            classNamePrefix="Select"
                            name="search"
                            placeholder={SEARCH_BY_OPTIONS[KEYWORD].description}
                            onChange={this.handleChange}
                            value={this.state.fields.search}
                        />
                        :
                        <Select
                            className="Select input search-input || select"
                            classNamePrefix="Select"
                            name="organisation"
                            value={this.selectedOption('organisation')}
                            onChange={this.handleSelectChange.bind(this)}
                            options={this.state.data.organisations}
                            placeholder={SEARCH_BY_OPTIONS[ORGANISATION].description}
                        />
                    }
                </div>
            );
        }

        return (
            <div className="field-group expand || search">
                <input
                    type="text"
                    className="input"
                    placeholder="Search headline, author, media, event, film"
                    name="search"
                    onChange={this.handleChange}
                    value={this.state.fields.search}
                />
            </div>
        );
    }

    renderRegionFilter() {
        if (this.shouldShowRegionFilter()) {
            return (
                <div className="field-group || region || hide-to-desktop">
                    <label className="label">Region</label>
                    <select className="input || select" name="rto" onChange={this.handleChange} value={this.selectedOption('rto')}>{this.renderOptionsFromData('rto')}</select>
                </div>
            );
        }
    }

    render() {
        return (
            <form className="filter-bar" onSubmit={this.handleSubmit.bind(this)}>
                <div className="field-group || type || hide-to-desktop">
                    <label className="label">Type</label>
                    <select className="input || select" name="resultType" onChange={this.handleChange} value={this.selectedOption('resultType')}>{this.renderOptionsFromData('resultType')}</select>
                </div>
                <div className="field-group || market || hide-to-desktop">
                    <label className="label">Market</label>
                    <select className="input || select" name="market" onChange={this.handleChange} value={this.selectedOption('market')}>{this.renderOptionsFromData('market')}</select>
                </div>
                {this.renderSearchBy()}
                {this.renderRegionFilter()}
                {this.renderDateFilters()}
                <div className="field-group || premium || hide-to-desktop">
                    <label className="label">Premium</label>
                    <select className="input || select" name="premium" onChange={this.handleChange} value={this.selectedOption('premium')}>
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field-group || impact || hide-to-desktop">
                    <label className="label">P&amp;P</label>
                    <select className="input || select" name="impact" onChange={this.handleChange} value={this.selectedOption('impact')}>
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div className="field-group || search || hide-from-desktop">
                    <button className="button primary || submit" type="submit" onClick={this.onSubmit.bind(this)}>
                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                            <use xlinkHref="/assets/icons/_sprite.svg#search"></use>
                        </svg>
                        <span className="button-label">Search</span>
                    </button>
                </div>
            </form>
        );
    }
}

Filters.propTypes = {
    onSubmit: PropTypes.func
};

Filters.defaultProps = {
    onSubmit: function() {} // eslint-disable-line
};

export default Filters;
