import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import PropTypes from 'prop-types';
import { renderFormErrors } from '../../utility/render';
import { processErrors, processRequest, request } from '../../utility/request';
import React, { Component } from 'react';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = this.intialState();
    }

    intialState() {
        const query = new URLSearchParams(this.props.location.query);

        return {
            token: this.props.match.params.token,
            email: query.get('email'),
            password: '',
            passwordConfirmation: '',
            errors: [],
            loading: false
        };
    }


    // Handlers
    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handlePasswordConfirmationChange(event) {
        this.setState({ passwordConfirmation: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.loading) return false;

        this.setState({ errors: [], loading: true });

        let submitData = {
            token: this.state.token,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation // eslint-disable-line camelcase
        };

        request(ENDPOINTS.PASSWORD_RESET, submitData, 'POST')
            .then((response) => ({ data: response.json(), ok: response.ok, next: '/password/reset/success' }))
            .then(processRequest.bind(this))
            .catch(processErrors.bind(this));
    }


    render() {
        let formClass = 'form compact';
        if (this.state.loading) formClass += ' is-loading';

        return (
            <DocumentTitle title="Reset Password">
                <main role="main" className="main">
                    <div className="main-inner">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="badge || new-password"></div>
                            <h1 className="style-h3 || title">Create new password</h1>
                        </div>

                        {/* Page Content */}
                        <div className="page-content">
                            <div className="constrain-width tiny">

                                {/* Forgot password */}
                                <form className={formClass} onSubmit={this.handleSubmit.bind(this)}>
                                    {renderFormErrors(this.state.errors)}
                                    <div role="group" className="fieldset">
                                        <div className="field">
                                            <label className="label">New password</label>
                                            <input type="password" className="input" value={this.state.password} onChange={this.handlePasswordChange.bind(this)} autoFocus required />
                                        </div>
                                        <div className="field">
                                            <label className="label">Confirm new password</label>
                                            <input type="password" className="input" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange.bind(this)} required />
                                        </div>
                                    </div>
                                    <hr/>
                                    <p className="text">Your new password must include one capital letter and one number.</p>
                                    <div className="actions">
                                        <button type="submit" className="button primary">Save</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ResetPassword.propTypes = ({
    location:   PropTypes.object,
    match:     PropTypes.object
});

export default ResetPassword;
