import HTTP_CODES from '../config/api';
import UserActions from '../action/user-actions';

/**
 * Request function - wrapper around fetch()
 * that adds default options and token
 *
 * @param  {string} url             URL to fetch()
 * @param  {object} [payload={}]    Body to send in GET request
 * @param  {String} [method='GET']  HTTP method to use
 *
 * @return {Promise}
 */
export function request(url, payload = false, method = 'GET') {
    let options,
        result;

    // Set default options
    options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: method,
        mode: 'cors',
        cache: 'default'
    };

    // JSONify the payload if it exists
    if (payload) {
        options.body = JSON.stringify(payload);
    }

    result = fetch(url, options);

    return result
        .then((response) => {
            return response;
        });
}


/**
 * Set errors state with values from request
 *
 * @param  {object} error Raw errors from request endpoint
 *
 * @return {void}
 */
export function processErrors(error) {
    this.setState({ errors: [error], loading: false });
}


/**
 * Process raw request result and set state if needed
 *
 * @param  {object} response Object with { data: Promise, ok: Boolean, next: String}
 *
 * @return {Boolean}
 */
export function processRequest(response) {
    if (response.ok) {
        this.props.history.push(response.next);

        return true;
    }

    if (response.status === HTTP_CODES.UNAUTHORIZED) {
        UserActions.logout();
    }

    response.data.then((data) => {
        this.setState({ errors: data.errors.fields, loading: false });
    });

    return false;
}
