import Auth from '../service/auth-service';
import DocumentTitle from '../utility/document-title';
import PropTypes from 'prop-types';
import UserStore from '../store/user-store';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class Home extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        if (!UserStore.isLoggedIn()) {
            // If React does not have a session yet, fetch IDP session (if any)
            // This method also updates the UserStore, which contains React's session.
            Auth.getUser();
        }

        this.state = this.initialState();
    }

    /**
     * Resets state to initial values.
     * @returns Object
     */
    initialState() {
        return {
            backgroundImageClass: this.getBackgroundImageClass(),
            isLoggedIn: UserStore.isLoggedIn(),
        };
    }

    // Mounting
    componentDidMount() {
        UserStore.listen(this.onChange);
    }

    componentWillUnmount() {
        UserStore.unlisten(this.onChange);
    }

    // Event
    onChange() {
        const isLoggedIn = UserStore.isLoggedIn();

        // If Home.isLoggedIn is false, but the UserStore.isLoggedIn is now true,
        // then it was a successful login.
        if (!this.state.isLoggedIn && isLoggedIn) {
            this.props.history.push('/dashboard');
        }

        this.setState({
            isLoggedIn
        });
    }

    // Helper
    /**
     * Renders the landing page blurb and button that will redirect to IDP.
     * @returns {JSX.Element|*}
     */
    renderLandingDialog() {
        return (
            <div>
                <h4 className="sub-heading">Log In to view your media coverage</h4>
                <div className="actions">
                    <a href="/saml2/mytnz/login"><button className="button primary alt">Log in</button></a>
                    <a href="/user/password"><p className="text">Can't log in?</p></a>
                </div>
            </div>
        );
    }

    renderDialog() {
        if (!this.state.isLoggedIn) {
            return (
                <div className="form">
                    {this.renderLandingDialog()}
                </div>
            );
        }
    }

    getBackgroundImageClass() {
        let generated,
            max = 4,
            min = 1;

        generated = Math.floor(Math.random() * (max - min) + min);

        return `image-${generated}`;
    }

    render() {
        return (
            <DocumentTitle>
                <main role="main" className={`main || home ${this.state.backgroundImageClass}`}>
                    <div className="main-inner">
                        <h3 className="title">Welcome to <br/>My Tourism New Zealand</h3>
                        <div className="introduction">
                          {this.renderDialog()}
                        </div>
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

Home.propTypes = {
    history: PropTypes.object
};

export default withRouter(Home);
