/**
 * Force download of a specific file using the URL
 *
 * @param  {string} fileUrl String of the file URL
 */
export function downloadFile(fileUrl) {
    let body = document.querySelector('body'),
        iframe;

    // Iframe added to body to force download
    iframe = document.createElement('iframe');

    // Setup iframe attributes
    iframe.setAttribute('style', 'display: none; visibility: none; opacity: 0; height: 0; width: 0; border: none;');
    iframe.setAttribute('class', 'iframe-download');
    iframe.setAttribute('src', fileUrl);

    // Append to body
    body.appendChild(iframe);
}
