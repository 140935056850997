import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const ANIMATION_IN_DELAY = 5;
const ANIMATION_OUT_DELAY = 200; // Matches CSS transition timing in _tool-tip.scss

class ToolTip extends Component {
    constructor(props) {
        super(props);

        this.state = this.intialState();
    }

    intialState() {
        return {
            animating: false,
            click: false,
            status: 'is-hidden',
        };
    }


    // Event
    open() {
        if (!this.state.animating) {
            this.setState({
                status: 'is-faux-visible',
                animating: true
            });

            setTimeout(() => {
                this.setState({
                    status: 'is-visible',
                    animating: false
                });
            }, ANIMATION_IN_DELAY);
        }
    }

    close() {
        if (!this.state.animating) {
            this.setState({
                status: 'is-faux-hidden',
                animating: true
            });

            setTimeout(() => {
                // Reset state
                this.setState(this.intialState());
            }, ANIMATION_OUT_DELAY);
        }
    }


    // Handler
    handleClick() {
        // Toggle true/false to track click
        this.setState({ click: !this.state.click });

        if (this.state.status === 'is-hidden') {
            this.open();
        } else {
            this.close();
        }
    }

    handleMouseEnter() {
        if (this.state.status === 'is-hidden' && !this.state.click) {
            this.open();
        }
    }

    handleMouseLeave() {
        if (this.state.status === 'is-visible' && !this.state.click) {
            this.close();
        }
    }


    // Render
    render() {
        let toolTipClass = classnames({
            'tool-tip': true,
            [this.props.position]: true,
            [this.props.size]: true,
            [this.props.type]: true
        });

        return (
            <div className={toolTipClass} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
                <button type="button" className="trigger" onClick={this.handleClick.bind(this)}>
                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg || icon" aria-hidden="true" focusable="false">
                        <title>Help</title>
                        <use xlinkHref="/assets/icons/_sprite.svg#help"></use>
                    </svg>
                </button>
                <div className={`tool-tip-content ${this.state.status}`}>
                    <div className="inner-content">{this.props.content}</div>
                </div>
            </div>
        );
    }
}

ToolTip.propTypes = {
    content: PropTypes.string.isRequired,
    position: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string
};

ToolTip.defaultProps = {
    position: 'left',
    size: '',
    type: 'default'
};

export default ToolTip;
