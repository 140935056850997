import Alt from '../flux';
import DialogActions from '../action/dialog-actions';

class DialogStore {
    constructor() {
        this.state = this.getDefaultStateFromConfig();

        // Bind the form actions
        this.bindListeners({
            handleClose: DialogActions.CLOSE,
            handleConfirm: DialogActions.CONFIRM,
            handleOpen: DialogActions.OPEN,
        });
    }


    // Helper
    getDefaultStateFromConfig() {
        return {
            continueFunc: function() {}, // eslint-disable-line no-empty-function
            data: {}, // Used to transfer data to custom dialogs
            forceHide: true,
            continueText: 'Ok',
            heading: 'Are you sure?',
            id: '', // Use this parameter for specific dialogs
            isLoading: false,
            isOpen: false,
            isVisible: false,
            message: '',
        };
    }


    // Handler
    handleConfirm(config) {
        let newState = this.getDefaultStateFromConfig(); // Reset state every time

        // Transfer state
        newState.message = config.message;
        newState.continueFunc = config.continueFunc;
        newState.forceHide = config.forceHide;
        newState.type = config.type;

        // Open
        newState.isOpen = true;
        newState.show = true;

        this.setState(newState);
    }

    handleOpen(config) {
        let newState = this.getDefaultStateFromConfig(); // Reset state every time

        // Transfer state
        newState.id = config.id;
        newState.data = config.data;
        newState.permissions = config.permissions;

        // Open
        newState.isOpen = true;
        newState.show = true;

        this.setState(newState);
    }

    handleClose() {
        // Hide
        this.setState({
            isLoading: false,
            isOpen: false
        });
    }
}

export default Alt.createStore(DialogStore, 'DialogStore');
