import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { renderFormErrors } from '../../utility/render';
import { processErrors, processRequest, request } from '../../utility/request';
import React, { Component } from 'react';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = this.intialState();
    }

    intialState() {
        const query = new URLSearchParams(this.props.location.query);

        return {
            firstName: query.get('firstName'),
            lastName: query.get('lastName'),
            email: query.get('email'),
            password: '',
            passwordConfirmation: '',
            errors: [],
            loading: false
        };
    }


    // Handlers
    handleFirstNameChange(event) {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChange(event) {
        this.setState({ lastName: event.target.value });
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handlePasswordConfirmationChange(event) {
        this.setState({ passwordConfirmation: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.loading) return false;

        this.setState({ errors: [], loading: true });

        let data = {
            first_name: this.state.firstName, // eslint-disable-line camelcase
            last_name: this.state.lastName, // eslint-disable-line camelcase
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation, // eslint-disable-line camelcase
            invitation_token: this.props.params.token // eslint-disable-line camelcase
        };

        request(ENDPOINTS.REGISTER, data, 'POST')
            .then((response) => ({ data: response.json(), ok: response.ok, next: '/' }))
            .then(processRequest.bind(this))
            .catch(processErrors.bind(this));
    }


    render() {
        let formClass = 'form compact';
        if (this.state.loading) formClass += ' is-loading';

        return (
            <DocumentTitle title="Register">
                <main role="main" className="main">
                    <div className="main-inner">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="badge || new-account"></div>
                            <h1 className="style-h3 || title">Register</h1>
                        </div>

                        {/* Page Content */}
                        <div className="page-content">
                            <div className="constrain-width tiny">

                                {/* Forgot password */}
                                <form className={formClass} onSubmit={this.handleSubmit.bind(this)}>
                                    {renderFormErrors(this.state.errors)}
                                    <div role="group" className="fieldset">
                                        <div className="field">
                                            <label className="label">First name</label>
                                            <input type="text" className="input" value={this.state.firstName} onChange={this.handleFirstNameChange.bind(this)} required />
                                        </div>
                                        <div className="field">
                                            <label className="label">Last name</label>
                                            <input type="text" className="input" value={this.state.lastName} onChange={this.handleLastNameChange.bind(this)} required />
                                        </div>
                                        <div className="field">
                                            <label className="label">Email</label>
                                            <input type="email" className="input" value={this.state.email} disabled />
                                        </div>
                                        <div className="field">
                                            <label className="label">Password</label>
                                            <input type="password" className="input" value={this.state.password} onChange={this.handlePasswordChange.bind(this)} autoFocus required />
                                        </div>
                                        <div className="field">
                                            <label className="label">Confirm password</label>
                                            <input type="password" className="input" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange.bind(this)} required />
                                        </div>
                                        <div className="field">
                                            <input type="checkbox" name="terms" id="terms" required />
                                            <label className="inline-label" htmlFor="terms">I accept the <Link to="terms-of-use">Terms and Conditions</Link>.</label>
                                        </div>
                                    </div>
                                    <hr/>
                                    <p className="text">Your password must include one capital letter, one lowercase letter, one number, one special character and be at least 12 characters.</p>
                                    <div className="actions">
                                        <button type="submit" className="button primary">Save</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ResetPassword.propTypes = ({
    location:   PropTypes.object,
    params:     PropTypes.object
});

export default ResetPassword;
