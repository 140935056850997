export const PENDING_OPERATORS = {
    view: 'operators/pending',
    tableHeaderData: [
        {
            cell: 'headline',
            data: {
                value: 'Name'
            }
        },
        {
            cell: 'default',
            data: {
                value: ''
            }
        }
    ]
};

export const ORGANISATIONS = {
    view: 'organisations',
    tableHeaderData: [
        {
            cell: 'default',
            data: {
                value: 'Name'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Type'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Region(s)'
            }
        },
        {
            cell: 'default',
            data: {
                value: ''
            }
        }
    ]
};

export const RESULTS = {
    view: 'dashboard',
    tableHeaderData: [
        {
            cell: 'default',
            data: {
                value: 'Headline'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Publication'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Market'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Media'
            }
        },
        {
            cell: 'number',
            data: {
                value: 'Reach'
            }
        },
        {
            cell: 'number',
            data: {
                value: 'EAV (NZD)'
            }
        },
        {
            cell: 'sort',
            data: {
                value: 'Live Date'
            }
        },
        {
            cell: 'select',
            data: {
                title: 'Status',
                values: [
                    { title: 'All', value: '' },
                    { title: 'Approved', value: 'approved' },
                    { title: 'Published', value: 'published' },
                    { title: 'Ready', value: 'ready_for_review' },
                    { title: 'Draft', value: 'draft' },
                ]
            }
        }
    ]
};

export const USERS = {
    view: 'users',
    tableHeaderData: [
        {
            cell: 'default',
            data: {
                value: 'Name'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Email'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Organisation'
            }
        },
        {
            cell: 'default',
            data: {
                value: 'Type'
            }
        },
        {
            cell: 'select',
            data: {
                title: 'Status',
                values: [
                    { title: 'All', value: '' },
                    { title: 'Deactivated', value: 'disabled' },
                    { title: 'Enabled', value: 'enabled' },
                    { title: 'Pending', value: 'pending' },
                ]
            }
        }
    ]
};
