import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Page extends Component {
    render() {
        return (
            <div className="page-single">

                {/* Page Header */}
                <div className="page-header">
                    <h1 className="style-h3 || title">{this.props.title}</h1>
                </div>

                {/* Page Content */}
                <div className="page-content">
                    <div className="constrain-width">
                        {this.props.children}
                    </div>
                </div>

            </div>
        );
    }
}

Page.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element
};

export default Page;
