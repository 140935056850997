import ButtonDropdown from '../../component/button-dropdown';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import FilterActions from '../../action/filter-actions';
import FilterStore from '../../store/filter-store';
import { formatFilters } from '../../utility/fields';
import List from '../../component/list';
import Permission from '../../service/permission-service';
import { prepareListEndpoint } from '../../utility/list';
import PropTypes from 'prop-types';
import { queryPrefix } from '../../utility/format';
import { request } from '../../utility/request';
import UserFilters from '../../component/user-filters';
import { USERS } from '../../config/tables';
import UserStore from '../../store/user-store';
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

const STARTING_PAGE = '1';
class ListUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            currentPage: null,
            loading: true,
            endpointParams: '',
            pagination: {}
        };

        this.onChange = this.onChange.bind(this);
    }


    // Life Cycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { number } = nextProps.match.params;

        this.populateData(number);
    }

    UNSAFE_componentWillMount() {
        const { number } = this.props.match.params;

        this.populateData(number);

        UserStore.listen(this.onChange);
    }

    componentWillUnmount() {
        UserStore.unlisten(this.onChange);
    }


    // Handlers
    handleSubmit(filters) {
        const endpointParams = this.prepareEndpointParams(filters);

        this.setState({
            endpointParams: endpointParams,
        }, () => {
            // Reset Page to first
            this.props.history.push('/users/page/1');
        });

        this.populateData(STARTING_PAGE, filters);
    }

    handleStatusHeaderClick(value) {
        const fields = FilterStore.getState().filters;

        fields.userStatus = value;

        FilterActions.save(fields);
        this.handleSubmit(fields);
    }


    // Event
    onChange() {
        const currentOrganisationId = UserStore.getState().user.organisationId;

        if (this.state.userOrganisationId !== currentOrganisationId) {
            this.reloadData();
            this.setState({
                userOrganisationId: currentOrganisationId
            });
        }
    }


    // Helpers
    prepareEndpointParams(filters) {
        const { search, user, userStatus } = filters;
        let endpointParams = '';

        // Add organisation
        if (search) {
            endpointParams += `${queryPrefix(endpointParams)}organisation=${search.value}`;
        }

        // Add user
        if (user) {
            endpointParams += `${queryPrefix(endpointParams)}user=${user.value}`;
        }

        // Add status filter
        if (userStatus) {
            endpointParams += `${queryPrefix(endpointParams)}user_status=${userStatus}`;
        }

        return endpointParams;
    }

    reloadData() {
        this.populateData(1); // eslint-disable-line
    }

    populateData(number) {
        // Get the latest endpoint params
        const endpointParams = this.getEndpointParams();
        const endpointPrepared = prepareListEndpoint(endpointParams, this.state.limit, number);

        this.setState({ currentPage: parseInt(number), loading: true });


        // Make request
        request(ENDPOINTS.LIST_USERS + endpointPrepared)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.data) {
                    const { data, meta } = response;

                    this.setState({
                        data: data,
                        eav: meta.eav,
                        pagination: meta.pagination,
                        total: meta.stories_generated || meta.pagination.total
                    }, () => {
                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, FAUX_LOADING_TIME);
                    });
                }
            });
    }

    getEndpointParams() {
        const filters = formatFilters(FilterStore.getState().filters);
        const preparedEndpointParams = this.prepareEndpointParams(filters);

        // Check if the state endpoint params matches the filters in local storage
        if (this.state.endpointParams !== preparedEndpointParams) {
            // Update the state endpoint params with the updated local storage filters
            this.setState({
                endpointParams: preparedEndpointParams,
            });
        }

        return preparedEndpointParams;
    }


    // Render
    renderCreateButtons() {
        if (Permission.check('Users: Manage all')) {
            const buttonConfig = {
                label: 'Create TNZ user',
                action: '/users/new-tnz',
                buttons: [
                    {
                        label: 'Create organisation user',
                        action: '/users/new-org'
                    }
                ]
            };

            return (<ButtonDropdown {...buttonConfig} />);
        }

        if (Permission.check('Users: Manage my organisation')) {
            return (<Link role="button" className="button primary" to="/users/new">Create user</Link>);
        }
    }

    render() {
        return (
            <DocumentTitle title="Users">
                <main role="main" className="main || dashboard">
                    <div className="main-inner">

                        <div className="subpage-banner">
                            <div className="banner-actions">
                                <div className="inner || constrain-width large">

                                    <div className="title">
                                        <h1 className="style-h3 medium">Users</h1>
                                    </div>

                                    <UserFilters onSubmit={this.handleSubmit.bind(this)}/>

                                    {this.renderCreateButtons()}

                                </div>
                            </div>
                        </div>

                        <List
                            onSubmit={this.handleSubmit.bind(this)}
                            data={this.state.data}
                            format="users"
                            header={USERS.tableHeaderData}
                            onStatusHeaderClick={this.handleStatusHeaderClick.bind(this)}
                            number={this.state.currentPage}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            view={USERS.view} />
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ListUsers.propTypes = {
    match: PropTypes.object
};

export default withRouter(ListUsers);
