import Alt from '../flux';

class ResultActions {
    submit(status = true) {
        return status;
    }

    focus(id = false) {
        return id;
    }

    update(field, value) {
        return {
            id: field,
            value: value
        };
    }

    error(field, error) {
        return {
            id: field,
            error: error
        };
    }

    upload(value = false) {
        return value;
    }
}

export default Alt.createActions(ResultActions);
