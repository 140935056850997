import { hot } from 'react-hot-loader/root';
import AirlineEdit from './view/airline/edit';
import AirlineNew from './view/airline/new';
import AuthService from './service/auth-service';
import Base from './view/base';
import ConfirmLogout from './view/confirm-logout';
import Dashboard from './view/dashboard';
import EventOrganiserEdit from './view/event-organiser/edit';
import EventOrganiserNew from './view/event-organiser/new';
import ForgotPassword from './view/user/forgot-password';
import ForgotPasswordSuccess from './view/user/forgot-password-success';
import { GA_UA_CODE } from './config/app';
import Home from './view/home';
import NotFound from './view/not-found';
import OffshoreAgencyEdit from './view/offshore-agency/edit';
import OffshoreAgencyNew from './view/offshore-agency/new';
import OperatorEdit from './view/operator/edit';
import OperatorNew from './view/operator/new';
import OrganisationsList from './view/organisations/list';
import PendingOperatorsList from './view/operator/list-pending';
import PrivacyPolicy from './view/page/privacy-policy';
import PrivacyPolicyUK from './view/page/privacy-policy-uk';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Register from './view/user/register';
import ResetPassword from './view/user/reset-password';
import ResetPasswordSuccess from './view/user/reset-password-success';
import RestoreScroll from './component/restore-scroll';
import ResultEdit from './view/result/edit';
import ResultType from './view/result-type';
import ResultView from './view/result/view';
import RTOEdit from './view/rto/edit';
import RTONew from './view/rto/new';
import StyleGuide from './view/style-guide';
import svg4everybody from 'svg4everybody';
import TermsOfUse from './view/page/terms-of-use';
import UserEdit from './view/user/edit';
import UserNew from './view/user/new';
import UserNewOrganisation from './view/user/new-org';
import UserNewTNZ from './view/user/new-tnz';
import UsersList from './view/user/list';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { browserHistory } from '~history';
import 'babel-polyfill';


svg4everybody();

/*
    Google Analytics
 */
ReactGA.initialize(GA_UA_CODE);

/*
    Route middleware
 */
function logPageView() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
}

function GAPageView() {
    const history = useHistory();

    useEffect(() => {
        logPageView();
        history.listen(logPageView);
    });

    return <></>;
}

function AuthCheck(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        AuthService.check().then((authenticated) => {
            setIsAuthenticated(authenticated);
        });
    }, []);

    if (isAuthenticated === null) {
        return null;
    }

    if (isAuthenticated === false) {
        return <Redirect to="/" />;
    }

    return <>{props.children}</>;
}

function HandleUnauthenticatedLogout() {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isMRPLoginPage, setIsMRPLoginPage] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const checkAuth = async () => {
            if (window.location.pathname === '/' && document.querySelector('a[href="/saml2/mytnz/login"]') !== null) {
                setIsMRPLoginPage(true);
                return;
            }

            try {
                const response = await AuthService.check();
                if(response.ok === undefined) return;
                const jsonResponse = await response.json();
                setIsAuthenticated(jsonResponse.authenticated);
            } catch (error) {
                console.error(error);
            }
        };

        checkAuth();
    }, [location, isMRPLoginPage]);

    if (isAuthenticated === false) {
        if (window.location.pathname !== '/' || !isMRPLoginPage) {
            Object.keys(localStorage).forEach((key) => {
                if (!key.startsWith('REACT_QUERY')) {
                    localStorage.removeItem(key);
                }
            });

            window.location.href = '/logout-redirect';
        }
    }

    return null;
}

function App() {
    return (
        <RestoreScroll history={browserHistory}>
            <BrowserRouter>
                <GAPageView />
                <HandleUnauthenticatedLogout />
                <Base>
                    <Switch>
                        {/* Home */}
                        <Route exact path="/" component={Home} />

                        {/* Airline */}
                        <Route
                            path="/airline/new"
                            render={(props) => (
                                <AuthCheck>
                                    <AirlineNew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            exact
                            path="/airline/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <AirlineEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Dashboard */}
                        <Redirect exact from="/dashboard" to="/dashboard/page/1" />
                        <Route
                            path="/dashboard/page/:number"
                            render={(props) => (
                                <AuthCheck>
                                    <Dashboard {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Event Organiser */}
                        <Route
                            path="/event-organiser/new"
                            render={(props) => (
                                <AuthCheck>
                                    <EventOrganiserNew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            exact
                            path="/event-organiser/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <EventOrganiserEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Logout */}
                        <Route
                            path="/confirm-logout"
                            render={(props) => (
                                <AuthCheck>
                                    <ConfirmLogout {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Offshore Agency */}
                        <Route
                            path="/offshore-agency/new"
                            render={(props) => (
                                <AuthCheck>
                                    <OffshoreAgencyNew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            exact
                            path="/offshore-agency/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <OffshoreAgencyEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Operators */}
                        <Redirect exact from="/operators/pending" to="/operators/pending/page/1" />
                        <Route
                            path="/operators/pending/page/:number"
                            render={(props) => (
                                <AuthCheck>
                                    <PendingOperatorsList {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/operators/new"
                            render={(props) => (
                                <AuthCheck>
                                    <OperatorNew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/operators/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <OperatorEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Organisations */}
                        <Redirect exact from="/organisations" to="/organisations/page/1" />
                        <Route
                            path="/organisations/page/:number"
                            render={(props) => (
                                <AuthCheck>
                                    <OrganisationsList {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Password */}
                        <Route path="/password/forgot" component={ForgotPassword} />
                        <Route path="/password/forgot/success" component={ForgotPasswordSuccess} />
                        <Route path="/password/reset/success" component={ResetPasswordSuccess} />
                        <Route path="/password/reset/:token" component={ResetPassword} />

                        {/* Privacy Policy */}
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/privacy-policy-uk" component={PrivacyPolicyUK} />

                        {/* Register */}
                        <Route path="/register/:token" component={Register} />

                        {/* Result */}
                        <Route
                            path="/result/new"
                            render={(props) => (
                                <AuthCheck>
                                    <ResultType {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/view/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <ResultView {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/result/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <ResultEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* RTO */}
                        <Route
                            path="/rto/new"
                            render={(props) => (
                                <AuthCheck>
                                    <RTONew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            exact
                            path="/rto/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <RTOEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* Privacy Policy */}
                        <Route path="/terms-of-use" component={TermsOfUse} />

                        {/* Style Guide */}
                        <Route path="/style-guide" component={StyleGuide} />

                        {/* Users */}
                        <Redirect exact from="/users" to="/users/page/1" />
                        <Route
                            path="/users/page/:number"
                            render={(props) => (
                                <AuthCheck>
                                    <UsersList {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/users/new-org"
                            render={(props) => (
                                <AuthCheck>
                                    <UserNewOrganisation {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/users/new-tnz"
                            render={(props) => (
                                <AuthCheck>
                                    <UserNewTNZ {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/users/new"
                            render={(props) => (
                                <AuthCheck>
                                    <UserNew {...props} />
                                </AuthCheck>
                            )}
                        />
                        <Route
                            path="/users/:id"
                            render={(props) => (
                                <AuthCheck>
                                    <UserEdit {...props} />
                                </AuthCheck>
                            )}
                        />

                        {/* (default) */}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Base>
            </BrowserRouter>
        </RestoreScroll>
    );
}

// <Redirect from="/dashboard" to="/dashboard/page/1" />
// <Route exact path="/xxx" render={() => <span>XXX</span>} />
// <Route path="/dashboard/page/:number" component={Dashboard} />
// <Route path="*" component={NotFound} />

AuthCheck.propTypes = {
    children: PropTypes.object,
};

export default hot(App);
