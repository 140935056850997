import Alt from '../flux';
import { getAllFields } from '../utility/fields';
import ResultActions from '../action/result-actions';
import { ALLOW_NONE, ALLOW_NONE_SUFFIX, OPTIONAL_FIELDS } from '../config/result';

class ResultStore {
    constructor() {
        this.state = this.getDefaultStateFromConfig();

        this.bindListeners({
            handleFieldError: ResultActions.ERROR,
            handleFocus: ResultActions.FOCUS,
            handleSubmit: ResultActions.SUBMIT,
            handleFieldUpdate: ResultActions.UPDATE,
            handleUploadUpdate: ResultActions.UPLOAD
        });

        this.exportPublicMethods({
            isSubmitting: this.isSubmitting
        });
    }


    // Default state
    getDefaultStateFromConfig() {
        let fieldIds = [],
            fields = {},
            validation = {},
            validationIds = [];

        fieldIds = getAllFields();
        validationIds = fieldIds.slice();

        fieldIds.map((id) => {
            fields[id] = '';
        });

        validationIds.map((id) => {
            if (OPTIONAL_FIELDS.indexOf(id) === -1) { // eslint-disable-line no-magic-numbers
                validation[id] = false;
            } else {
                validation[id] = true;
            }
        });

        return {
            fields: fields,
            submitting: false,
            validation: validation
        };
    }


    // Accessors
    isSubmitting() {
        return Boolean(this.state.submitting);
    }


    // Handler
    handleFieldError(field) {
        // Update fields using reference id in state[fields]
        this.state.validation[field.id] = field.error;
    }

    handleFieldUpdate(field) {
        let fieldId,
            fieldValue,
            noneCheckBoxValidation = this.state.validation[field.id + ALLOW_NONE_SUFFIX],
            noneCheckBoxValue = this.state.fields[field.id + ALLOW_NONE_SUFFIX];

        if (field.value === undefined || field.value === null) {
            field.value = '';
        }

        // Update field
        this.state.fields[field.id] = field.value;

        // Update validation
        this.state.validation[field.id] = false;

        // Make sure fields that aren't required aren't validated
        if (OPTIONAL_FIELDS.indexOf(field.id) !== -1) { // eslint-disable-line no-magic-numbers
            this.state.validation[field.id] = true;
            return;
        }

        // If is allow none and checked remove validation on other field
        if (field.id.indexOf(ALLOW_NONE_SUFFIX) !== -1) { // eslint-disable-line no-magic-numbers
            fieldId = field.id.replace(ALLOW_NONE_SUFFIX, '');

            // Pass validation if true
            if (field.value === true) {
                this.state.validation[field.id] = true;
                this.state.validation[fieldId] = true;
            }

            // Force error if false and field not valid
            if (field.value === false) {
                this.state.validation[fieldId] = false;

                // Change the validation on the field if not empty
                fieldValue = this.state.fields[fieldId];
                if (fieldValue !== '' && fieldValue !== null && fieldValue.length !== 0) { // eslint-disable-line no-magic-numbers
                    this.state.validation[fieldId] = true;
                }
            }
        }

        // If the none checkbox validation and field is true
        if (ALLOW_NONE.indexOf(field.id) !== -1 && noneCheckBoxValue && noneCheckBoxValidation) { // eslint-disable-line no-magic-numbers
            this.state.validation[field.id] = true;
        }

        if (field.value !== '' && field.value !== null && field.value.length !== 0 && noneCheckBoxValidation !== false) { // eslint-disable-line
            this.state.validation[field.id] = true;
        }
    }

    handleFocus(id) {
        this.state.focus = id;
    }

    handleSubmit(status) {
        this.state.submitting = status;
    }

    handleUploadUpdate(value) {
        this.state.uploading = value;
    }
}

export default Alt.createStore(ResultStore, 'ResultStore');
