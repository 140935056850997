import Alt from '../flux';
import UserActions from '../action/user-actions';

class UserStore {
    constructor() {
        let permissions = null,
            user = null;

        if (localStorage.getItem('permissions')) {
            permissions = JSON.parse(localStorage.getItem('permissions'));
        }

        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }

        this.state = {
            permissions: permissions,
            user: user,
            token: localStorage.getItem('token')
        };

        this.bindListeners({
            handleUpdateUser: UserActions.UPDATE_USER,
            handleUpdateToken: UserActions.UPDATE_TOKEN,
            handleLogout: UserActions.LOGOUT,
        });

        this.exportPublicMethods({
            isLoggedIn: this.isLoggedIn
        });
    }


    // Accessors
    isLoggedIn() {
        return Boolean(this.state.user && this.state.user.email);
    }


    // Handler
    handleUpdateUser(userObject) {
        let current_organisation,
            permissions,
            user;

        current_organisation = userObject.current_organisation;
        if (current_organisation !== null) {
            current_organisation = userObject.current_organisation;
        }

        user = {
            email: userObject.email,
            firstName: userObject.first_name,
            id: userObject.id,
            lastName: userObject.last_name,
            organisation: current_organisation,
            organisationId: null,
            userOrganisations: userObject.organisations,
        };

        // Check through multiple roles
        permissions = [];
        userObject.roles.forEach((element) => {
            for (let permission of element.permissions) {
                permissions.push(permission.name);
            }
        });

        // Save to state
        this.state.permissions = permissions;
        this.state.user = user;

        // Save to local storage
        try {
            localStorage.setItem('permissions', JSON.stringify(permissions));
            localStorage.setItem('user', JSON.stringify(user));
        } catch (exception) { // Catch needed for Mobile Safari + Jest
            console.warn('Local storage exception', exception); // eslint-disable-line no-console
        }
    }

    handleUpdateToken(token) {
        this.state.token = token;

        if (token === null) {
            localStorage.removeItem('permissions');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        } else {
            try {
                localStorage.setItem('token', token);
            } catch (exception) { // Catch needed for Mobile Safari + Jest
                console.warn('Local storage exception', exception); // eslint-disable-line no-console
            }
        }
    }

    /**
     * Handler for logging out.
     */
    handleLogout() {
        this.state.user = null;
        this.state.permissions = null;
        this.handleUpdateToken(null);
    }
}

export default Alt.createStore(UserStore, 'UserStore');
