import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { Link } from 'react-router-dom';
import { renderFormErrors } from '../../utility/render';
import { processErrors, processRequest, request } from '../../utility/request';
import React, { Component } from 'react';

class ForgotPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            errors: [],
            loading: false
        };
    }


    // Handlers
    handleChange(event) {
        this.setState({ email: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.loading) return false;

        this.setState({ errors: [], loading: true });

        request(ENDPOINTS.PASSWORD_FORGOT, { email: this.state.email }, 'POST')
            .then((response) => ({ data: response.json(), ok: response.ok, next: '/password/forgot/success' }))
            .then(processRequest.bind(this))
            .catch(processErrors.bind(this));
    }


    render() {
        let formClass = 'form compact';

        if (this.state.loading) formClass += ' is-loading';

        return (
            <DocumentTitle title="Forgot Password">
                <main role="main" className="main">
                    <div className="main-inner">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="badge || forgot-password"></div>
                            <h1 className="style-h3 || title">I forgot my password</h1>
                        </div>

                        {/* Page Content */}
                        <div className="page-content">
                            <div className="constrain-width tiny">

                                {/* Forgot password */}
                                <form className={formClass} onSubmit={this.handleSubmit.bind(this)}>
                                    {renderFormErrors(this.state.errors)}
                                    <div role="group" className="fieldset">
                                        <div className="field">
                                            <label className="label">Email</label>
                                            <input type="email" className="input" value={this.state.email} onChange={this.handleChange.bind(this)} placeholder="Type your email address" autoFocus required/>
                                            <p className="note">Enter your email address and we will send you a reset password link.</p>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <button type="submit" className="button primary">Request new password</button>
                                        <p className="text"><Link to="/">Cancel</Link></p>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

export default ForgotPassword;
