/**
 * Prepare List API endpoints with pagination and limit parameters
 *
 * @param  {string} endpoint API URL to query
 * @param  {number} limit    Limit of results
 * @param  {number} currentPageNumber   Current page number
 *
 * @return {string}
 */
export function prepareListEndpoint(endpoint, limit, currentPageNumber) {
    let currentPage = parseInt(currentPageNumber),
        newEndpoint = endpoint;

    if (newEndpoint.indexOf('?') === -1) { // eslint-disable-line no-magic-numbers
        newEndpoint += '?';
    } else {
        newEndpoint += '&';
    }

    if (limit) {
        newEndpoint += `limit=${limit}&`;
    }

    if (currentPage) {
        newEndpoint += `page=${currentPage}`;
    }

    return newEndpoint;
}
