import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer" role="contentinfo">
                <div className="bootstrap-wrapper">
                    <div className="row">
                        <div className="col-sm-4 col-offset-3 col-lg-3 footer-section">
                            <h3>Help</h3>
                            <hr/>
                            <ul>
                                <li><Link to="/terms-of-use">Terms of Use</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-lg-3 footer-section">
                            <h3>Other sites</h3>
                            <hr/>
                            <ul>
                                <li><a href="https://www.newzealand.com/int/" target="_blank" rel="noopener noreferrer">newzealand.com</a></li>
                                <li><a href="https://www.tourismnewzealand.com/" target="_blank" rel="noopener noreferrer">Corporate website</a></li>
                                <li><a href="https://register.newzealand.com/" target="_blank" rel="noopener noreferrer">Tourism Business Database</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-lg-3 footer-section footer-logo-container">
                            <img src="/assets/images/logo.svg"/>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
