import DocumentTitle from '../../utility/document-title';
import Page from '../../component/page';
import React from 'react';
import useScrollToTop from '../../component/scroll-to-top';

function Content() {
    useScrollToTop();
    return (
        <DocumentTitle title="Privacy Policy">
            <Page title="Privacy Policy">
                <div>
                    <p>This privacy policy explains how the New Zealand Tourism Board, a New Zealand Crown entity that trades as Tourism New Zealand ('<strong>we</strong>' or '<strong>us</strong>') collects, stores, uses, and discloses any personal information that you provide to us when using this website (Media Results Portal). We may also collect, use, and disclose information to the extent applicable law does not prohibit it. This privacy policy is subject to, and must be read in conjunction with, our <a href="https://www.newzealand.com/int/utilities/terms-of-use/">Terms of Use</a>.</p>

                    <p>If you are accessing this site from the United Kingdom or the European Union, please refer to the following <a href="/privacy-policy-uk">privacy policy</a>.</p>

                    <h5>Information we collect.</h5>

                    <h5>Personal Information</h5>

                    <h5>Tourism New Zealand requires your personal information only in limited circumstances when you:</h5>
                    <ul>
                        <li>Register for an account.</li>
                        <li>Submit an enquiry through the contact us page</li>
                        <li>Submit an online form, request information, complete a survey or application through the website or otherwise submit information to us through the website or post or upload to the website</li>
                        <li>Provide feedback or report a problem with this website</li>
                    </ul>

                    <p>You may decide not to provide some or any of your personal information to us. However, if you do not provide it, we may not be able to provide you with access to certain information or services.</p>

                    <h5>Automated collection</h5>

                    <p>Additionally, when you visit, navigate through, and interact with this website or third-party sites and mobile applications on which we advertise, we use automated data collection technology (such as usage monitoring software, cookies, and sessions) to collect, match, and store certain information about your visit. Please see the <a href="https://www.newzealand.com/int/utilities/cookies/">Cookie Use Statement</a> for more information on the types of such technology that we use and the purposes we use them for. If you disable cookies it may limit your access to some of the website's content and features. You can choose to refuse cookies by turning them off in your browser and/or deleting them from your hard drive. You do not need to have cookies turned on to use our website, but your experience may be affected.</p>

                    <h5>Advertising and analytics services provided by partners</h5>
                    <p>We advertise in a number of ways, including online through managed social media presences, and on other unaffiliated sites and mobile applications. To understand how our advertising campaigns are performing, and better understand the visitors to our website we work with partners who provide us advertising and analytics services. We collect information via, and share information with, our advertising and analytics service providers to help us understand how users (including you) interact with our services, our advertising on unaffiliated third-party sites and mobile applications, and to serve advertising on our behalf across the internet, derive analytics and measure the performance of these advertisements. We and our service providers may collect and match anonymised identifiers assigned to your personal information collected in accordance with and for the purposes set out in this privacy policy, when you visit our site and unaffiliated third-party sites and mobile applications on which we advertise. Please see the <a href="https://www.newzealand.com/int/utilities/cookies/">Cookie Use Statement</a> for more information on which partners we use and to learn more about your privacy choices.</p>

                    <h5>Social Media and APIs</h5>
                    <p>Our website includes social media features such as the Facebook like button, Twitter, Google+ and widgets, such as the share button or interactive mini-programs that run on our site. These features may collect your IP address, which pages you visit on our site, and how long for. If you're a member of a social media site, the interfaces may allow the social media site to connect your visits to this site with other personal information. Social media features and widgets are either hosted by a third party or hosted directly on our website. Your interactions with these features are governed by the privacy policy of the third party providing it and to the maximum extent permitted by law we are not responsible for the actions of those third parties.</p>
                    <ul>
                        <li>We may provide an application programming interface (API) to enable third party applications to interface with our websites. Some applications enable you to interact with us through the API in a way that requires you to log in. To do this, most of these applications will direct you through a process where you are able to let the application connect to your account.</li>
                        <li>If you allow an application to connect to your account on our websites, including if you set up your account on our website using an API with a third party social media platform, that application will be able to access information that you can see when you are logged into our websites. You should only allow applications you trust to access your account on our websites.</li>
                        <li>If you set up your account on our websites using an API with a third party social media platform, you also consent to us obtaining and using your information from such platform.</li>
                    </ul>

                    <h5>How your information is used</h5>
                    <p>We may use your personal information for any purpose which is stated to you at the time of collection or that you otherwise authorise and to:</p>
                    <ul>
                        <li>verify your identity and to assist you if you forget your username or password for any of the services we provide to you via the Internet;</li>
                        <li>provide, or assist in providing, information and services requested by you;</li>
                        <li>communicate with you for general contact or feedback enquiries.</li>
                        <li>analyse usage of this website;</li>
                        <li>provide you with further information, news, and promotional material;</li>
                        <li>carry out advertising and marketing activities  and other promotional and publicity purposes, including direct marketing, market research, analytics, and surveys;</li>
                        <li>to personalise our communications with you, and show you advertising and information through our website and other mediums that is most relevant to you and your interests;</li>
                        <li>improve the content of this website and to customise this website to your preferences;</li>
                        <li>track the effectiveness of our advertising by sharing and receiving anonymised data with our conversion partners; and</li>
                        <li>combine it with information about you that is publicly available and/or our third-party service providers have (such as your email address), for the purposes set out in this privacy policy.</li>
                    </ul>

                    <p>So, for example:</p>

                    <ul>
                        <li><strong>e-newsletters:</strong> the personal data collected as part of registration for our e-newsletter will be used to personalise our e-newsletter to you. That data may be used alongside your website behaviour to further personalise your user experience on our website, and in our advertising programs.</li>
                    </ul>

                    <p>Your personal information will be made available internally for the above purposes and we may also disclose your personal information to trusted third parties or subcontractors, who have agreed to treat your personal information in accordance with this privacy policy, for similar purposes. Because we operate internationally, the recipients referred to above may be located outside the jurisdiction in which you are located. Your personal information may be transferred to, stored, and processed in New Zealand and other countries that are regarded as ensuring an adequate level of protection for personal information. However, we will not sell, rent, or lease your personal information to third parties.</p>

                    <p>So, for example:</p>

                    <ul>
                        <li><strong>Cookies and IDs:</strong> We use cookies and anonymised IDs (e.g., hashed email or phone numbers) to personalise content and ads, to provide social media features and to analyse our traffic and the effectiveness of our advertising, including sharing such anonymised data with our partners.</li>
                        <li>We also share information about your use of our website with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services. You consent to our cookies if you continue to use our website.</li>
                    </ul>

                    <p>We will only use or disclose personal information that you have provided to us, or which we have obtained about you for the above-mentioned purposes, or:</p>

                    <ul>
                        <li>if you have authorised us to do so;</li>
                        <li>if we believe that the use or disclosure is reasonably necessary to assist a law enforcement agency or an agency responsible for national security in the performance of their functions;</li>
                        <li>if we believe that the use or disclosure is reasonably necessary to enforce any legal rights we may have, or is reasonably necessary to protect the rights, property and safety of us, our customers, or others; or</li>
                        <li>if we are authorised, required or permitted by law to disclose the information.</li>
                    </ul>

                    <h5>Storage and security</h5>
                    <p>Personal information collected on this website is collected and held by Tourism New Zealand. We will take reasonable efforts to protect personal information that is held by us from loss, misuse, unauthorised access, disclosure, alteration, or destruction. However, no method is completely secure, and while we take reasonable measures we cannot completely ensure or promise the security of any information we collect from you.</p>

                    <h5>Third party advertising and links to websites</h5>
                    <p>This website contains hyperlinks to third party websites. Some of these links may request or record information from users or use cookies or other methods to collect information from you. We have no control over and are not responsible for the content of such websites, or for the manner in which those websites collect, hold, use, and distribute any personal information you provide. When visiting a third party website either from hyperlinks displayed on this website, or otherwise, we encourage you to review the privacy statements of those websites so that you can understand how the personal information you provide will be will collected, held, used, and distributed.</p>

                    <h5>Competitions</h5>
                    <p>We will collect personal information provided by you if you participate in our promotions and competitions. We collect this information in order to conduct the promotion or competition. We may disclose such information to third parties, such as agents, contractors, service providers and prize suppliers to conduct the promotion or competition. We may also use and disclose this information as set out in this privacy policy. Entry is conditional on providing this information. If the information is not fully supplied the entry may be void.</p>
                    <p>If you provide us with the names and contact details of any other people that you would like to have participate in our contests or promotions, we will also collect this information. You promise that you have their permission to provide us with this information and for us to use if for this purpose.</p>

                    <h5>Commercial electronic messages</h5>
                    <p>When you submit an online form to provide us with your personal information (including electronic contact details), to the extent that form is not in itself a request by you for us to send you commercial electronic messages, we will ask you if you would like to receive commercial electronic messages (e.g., texts and emails) from us for any of the purposes set out in this privacy policy. You can opt-out of receiving commercial electronic messages from us at any time using the "unsubscribe" link provided in our messages (where relevant), or by sending an email to <a href="mailto:privacypolicy@tnz.govt.nz">privacypolicy@tnz.govt.nz</a>.</p>
                    <p>Similarly, the subscription to our e-newsletter may be terminated by you at any time by using the 'Unsubscribe' link found in the relevant TNZ email. Your consent to the storage of personal information, which you have given for receiving the newsletter, may be revoked at any time (again by using the Unsubscribe' link found in the TNZ email).</p>

                    <h5>Data Access and Correction</h5>
                    <p>Under the Privacy Act 2020 (and other applicable laws) you have rights of access to and correction of personal information that we hold about you. This can be done by contacting us at <a href="mailto:privacypolicy@tnz.govt.nz">privacypolicy@tnz.govt.nz</a>.</p>
                    <p>To ensure that the personal information we hold about you is accurate and current, please notify us of any changes to your personal information as soon as possible.</p>

                    <h5>Changes to our Privacy Policy</h5>
                    <p>We reserve the right, at our discretion, to update or revise this privacy policy at any time. Changes to this privacy policy will take effect immediately once published on this website. Please check this privacy policy regularly for modifications and updates.</p>

                    <h5>Contact Details</h5>
                    <p>If you have any questions about our privacy policy, or any other related matter, please feel free to contact us. You can reach us by contacting our privacy officer at <a href="mailto:privacypolicy@tnz.govt.nz">privacypolicy@tnz.govt.nz</a>.</p>
                    <p>This privacy policy was last updated on 15 December 2021.</p>
                </div>
            </Page>
        </DocumentTitle>
    );
}

export default Content;
