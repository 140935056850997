import { ENDPOINTS } from './api';

export const ADMIN_USER = {
    id: 'admin-user',
    title: 'Create new admin user',
    fields: [
        {
            // This 'id' is being converted into an object on submission
            id: 'userObject_first_name',
            label: 'First name',
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            // This 'id' is being converted into an object on submission
            id: 'userObject_last_name',
            label: 'Last name',
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            // This 'id' is being converted into an object on submission
            id: 'userObject_email',
            label: 'Email',
            type: 'email',
            placeholder: 'Add their email address'
        }
    ]
};

export const EXISTING_USER = {
    id: 'existing-user',
    title: 'Assign an existing user as admin',
    fields: [
        {
            id: 'userObject_existing_email',
            label: 'Search by Email',
            type: 'select-async',
            placeholder: 'Start typing to search',
            options: {
                array: false
            },
            endpoint: ENDPOINTS.LIST_USERS_OPTIONS
        }
    ]
};

export const AIRLINE = {
    id: 'airline',
    title: 'Create new airline',
    fields: [
        {
            id: 'name',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Name'
        }
    ]
};

export const EVENT_ORGANISER = {
    id: 'event-organiser',
    title: 'Create event',
    fields: [
        {
            id: 'name',
            label: 'Name',
            required: true,
            type: 'text'
        }
    ]
};

export const OFFSHORE_AGENCY = {
    id: 'offshore-agency',
    title: 'Create offshore agency',
    fields: [
        {
            id: 'name',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Name'
        },
        {
            id: 'market_ids',
            label: 'Markets',
            required: true,
            type: 'select',
            placeholder: 'Start typing to search or select multiple markets',
            options: {
                array: true,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_COUNTRIES
        }
    ]
};

export const OPERATOR = {
    id: 'operator',
    title: 'Create operator',
    fields: [
        {
            id: 'name',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Name'
        },
        {
            id: 'region_ids',
            label: 'Regions',
            required: true,
            type: 'select',
            placeholder: 'Start typing to search or select multiple regions',
            options: {
                array: true,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_REGIONS
        }
    ]
};

export const RTO = {
    id: 'rto',
    title: 'Edit RTO',
    fields: [
        {
            id: 'name',
            label: 'Name',
            placeholder: 'Name',
            required: true,
            type: 'text'
        },
        {
            // This 'id' is being converted into an object on submission
            id: 'region_name',
            label: 'Region',
            placeholder: 'Region name',
            required: true,
            type: 'text',
        }
    ]
};

export const RTO_NEW = {
    id: 'rto',
    title: 'Create RTO',
    fields: [
        {
            id: 'name',
            label: 'Name',
            placeholder: 'Name',
            required: true,
            type: 'text'
        },
        {
            id: 'region_name',
            label: 'Region',
            placeholder: 'Region name',
            required: true,
            type: 'text'
        }
    ]
};
