import DocumentTitle from '../../utility/document-title';
import React, { Component } from 'react';

class ForgotPasswordSuccess extends Component {
    render() {
        return (
            <DocumentTitle title="Forgot Password">
                <main role="main" className="main">
                    <div className="main-inner">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="badge || forgot-password-success"></div>
                            <h1 className="style-h3 || title">Check your email</h1>
                            <div className="constrain-width tiny">
                                <p className="text">If your email address exists in our database, you will receive an email with instructions to reset your password.</p>
                            </div>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

export default ForgotPasswordSuccess;
