import Alt from '../../flux';
import classnames from 'classnames';
import DialogActions from '../../action/dialog-actions';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import Form from '../../component/form';
import FormActions from '../../action/form-actions';
import FormControls from '../../component/form-controls';
import FormStore from '../../store/form-store';
import NotificationActions from '../../action/notification-actions';
import PropTypes from 'prop-types';
import { request } from '../../utility/request';
import { ADMIN_USER, AIRLINE } from '../../config/organisation';
import { formHasErrors, processDataFromApi, processDataToApi, responseHasErrors } from '../../utility/form';
import React, { Component } from 'react';


class EditOrganisation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: [...AIRLINE.fields],
            loading: true
        };
    }


    // Mounting
    UNSAFE_componentWillMount() {
        // Refresh the store
        Alt.recycle(FormStore);
    }

    componentDidMount() {
        // Get Airline from API
        request(`${ENDPOINTS.AIRLINE}/${this.props.match.params.id}`, false, 'GET')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                let { fields } = this.state,
                    { is_verified: isVerified } = response.data; // eslint-disable-line camelcase

                // Show the add admin user form when not verified
                if (isVerified === false) {
                    fields = [...fields, ...ADMIN_USER.fields];
                }

                setTimeout(() => {
                    this.setState({
                        isVerified: isVerified,
                        fields: fields,
                        loading: false
                    }, () => {
                        let { fields } = this.state;

                        // Pass all fields to the formActions to setup store
                        FormActions.setup(fields);

                        // Call FormActions on each field e.g processResultFromApi
                        processDataFromApi(fields, response.data.data || response.data);
                    });
                }, FAUX_LOADING_TIME);
            });
    }


    // Handlers
    handleCancel() {
        DialogActions.confirm('You will lose any unsaved changes.', this.back.bind(this));
    }

    handleDelete() {
        DialogActions.confirm('You are about to delete this airline', this.delete.bind(this));
    }

    handleSubmit(event) {
        event.preventDefault();

        // Add 'submitting' status
        FormActions.submit();

        // Validate
        if (!formHasErrors(this.state.fields)) {
            this.submit();
            this.props.history.goBack();
        }
    }


    // Helpers
    back() {
        // TODO: Implement a smarter solution
        this.props.history.goBack();
        this.props.history.goBack();
    }

    delete() {
        // Make API request to delete remote resource
        request(`${ENDPOINTS.AIRLINE}/${this.props.match.params.id}`, {}, 'DELETE')
            .then((response) => {
                if (response.ok) {
                    this.props.history.goBack();

                    // Reset local store
                    Alt.recycle(FormStore);

                    // Push a success notification
                    NotificationActions.push('Succesfully deleted airline');

                    return true;
                }

                return false;
            });
    }

    submit() {
        let data;

        // Set loading state
        this.setState({ loading: true });

        // Process the current form data
        data = processDataToApi(this.state.fields);

        // Submit request
        request(`${ENDPOINTS.AIRLINE}/${this.props.match.params.id}`, data, 'PATCH')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    this.setState({ loading: false });
                    if (!responseHasErrors(response)) {
                        this.back();
                    }
                }, FAUX_LOADING_TIME);
            });
    }


    // Renders
    renderAdminUserForm() {
        if (this.state.hasAdminUser === false) {
            return (<Form {...ADMIN_USER}/>);
        }
    }

    render() {
        let buttonTypes = ['save', 'cancel', 'delete'],
            mainClass,
            title = 'Edit Airline';

        mainClass = classnames({
            'main': true,
            'is-loading': this.state.loading
        });

        return (
            <DocumentTitle title={title}>
                <main role="main" className={mainClass}>
                    <div className="main-inner">

                        <form ref="form" action="/" className="editing-form || constrain-width" onSubmit={this.handleSubmit.bind(this)}>
                            <Form {...AIRLINE} title={title}/>
                            {this.renderAdminUserForm()}
                            <FormControls handleCancel={this.handleCancel.bind(this)} handleDelete={this.handleDelete.bind(this)} types={buttonTypes}/>
                        </form>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

EditOrganisation.propTypes = {
    match: PropTypes.object
};

export default EditOrganisation;
