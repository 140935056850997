import { ENDPOINTS } from './api';

export const ALLOW_NONE = ['airlines', 'event_organisers', 'interests', 'motivators', 'operators'];
export const ALLOW_NONE_SUFFIX = '_none';

export const OPTIONAL_FIELDS = ['article_url', 'media', 'video_url'];

export const TOOLTIPS = {
    ASSETS:      'Assets can only be uploaded as either PDF, JPG or JPEG and Max upload file size is 5MB. Broadcast files should be sent directly to your TNZ PR contact.',
    AUTHOR:      'This could be the name of the Journalist, Influencer, TV Producer, Director or main content producer for the media outlet.',
    EAV:         'Equivalent Advertising Value (EAV) is a standard term used by the Public Relations industry to illustrate the value of each story. EAV is calculated based on the cost of placing an advert in the same media with a comparable amount of space. For a syndicated story the EAV is the total EAV of all the stories combined.',
    HIGH_IMPACT: 'This is a High Impact PR project as confirmed by the Global PR Manager.',
    PREMIUM:     'This is a Premium project as confirmed by the Global PR Manager.',
    REACH:       'Reach measures how many people have seen the media coverage. It includes the circulation and readership of a print article, the viewership of a broadcast, the listeners to a radio story and the unique viewers of an online article or social media post. For a syndicated story the reach is the total reach of all the stories combined.',
    SYNDICATED:  '',
    OPERATORS:   'Do not enter place names e.g. \'Milford Track\'. Only enter tourism businesses. If there is no specific operator mentioned, please enter a dash -',
    RTOS:        'RTO region is the Regional Tourism Organisation responsible for promoting a particular NZ region. Refer to the RTO region map for boundaries: http://www.rtonz.org.nz/rto-location-map.html\nIf no specific region is mentioned, please select \'New Zealand\' from the drop down.'
};

export const RESULT_TYPE = {
    id: 'result-type',
    title: 'Choose your result type',
    fields: [
        {
            id: 'result_type',
            type: 'radio',
            values: [
                {
                    label: 'IMP',
                    value: 'imp',
                    description: 'International Media Programme (IMP) visitors are taken on an itinerary of New Zealand. The IMP media then return home to create their story. Also includes Partial Support IMP',
                },
                {
                    label: 'Content',
                    value: 'content',
                    description: 'Media stories about New Zealand without hosting media under the International Media Programme. The results generated are called Content Results.'
                },
                {
                    label: 'Partnerships & Projects ',
                    value: 'partners_and_projects',
                    description: 'PR Stories generated from a combination of PR - Activity that isn’t pure IMP or Content Generation. It may include results from a project that combines media visiting NZ and content and/or partnership. Also includes Major Events and Film results.'
                }
            ]
        }
    ]
};

export const STORY_DETAIL = {
    id: 'story-detail',
    title: 'Story detail',
    fields: [
        {
            label: 'Target audience',
            id: 'audience',
            type: 'select',
            placeholder: 'Start typing to search or select an option',
            options: {
                array: true,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_AUDIENCE
        },
        {
            label: 'RTO region',
            id: 'rtos',
            type: 'select',
            placeholder: 'Start typing to search or select multiple regions',
            options: {
                array: true,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_RTOS,
            toolTip: TOOLTIPS.RTOS
        },
        {
            id: 'operators_none',
            type: 'hidden'
        },
        {
            label: 'Tourism operator(s) mentioned',
            id: 'operators',
            type: 'select',
            placeholder: 'Start typing or select multiple operators',
            options: {
                allowCreate: false,
                array: true,
                isMulti: true,
                includeDeleted: true,
                limit: 3000, // TNZSLA-99 Increased the limit to fetch more operators, patching the issue in which operator names appear as IDs
                isVerified: true
                // ... because they aren't able to map to the results served by an API call.
                // ... Over time, we will have to create an "on-demand" API call that fetches operators based pre-filled entries on an edit screen.
            },
            allowNone: true,
            endpoint: ENDPOINTS.LIST_OPERATORS,
            toolTip: TOOLTIPS.OPERATORS
        },
        {
            id: 'airlines_none',
            type: 'hidden'
        },
        {
            label: 'Did an airline sponsor the trip?',
            id: 'airlines',
            type: 'select',
            placeholder: 'Start typing to search or select multiple airlines',
            options: {
                array: true,
                isMulti: true
            },
            allowNone: true,
            displayOnContent: false,
            endpoint: ENDPOINTS.LIST_AIRLINES
        },
        {
            id: 'event_organisers_none',
            type: 'hidden'
        },
        {
            label: 'Event/Film',
            id: 'event_organisers',
            type: 'select',
            placeholder: 'Start typing to search or select an event',
            options: {
                array: true
            },
            allowNone: true,
            displayOnPnP: false,
            displayOnImp: false,
            displayOnContent: false,
            endpoint: ENDPOINTS.LIST_EVENT_ORGANISERS
        },
        {
            label: 'Event/Film/Partnerships and Projects', /* They want a different title for the P&P type only */
            id: 'event_organisers',
            type: 'select',
            placeholder: 'Start typing to search or select an event',
            options: {
                array: true
            },
            allowNone: true,
            displayOnImp: false,
            endpoint: ENDPOINTS.LIST_EVENT_ORGANISERS
        },
        {
            id: 'interests_none',
            type: 'hidden'
        },
        {
            label: 'Special interest',
            id: 'interests',
            type: 'select',
            placeholder: 'Start typing to search or select an option',
            options: {
                array: true
            },
            allowNone: true,
            displayOnPnP: false,
            endpoint: ENDPOINTS.LIST_INTERESTS
        },
        { // This one is for P&P and does not include hiking. Not a good solution but with the way the FE fields are handled this needs to be BE
            label: 'Special interest',
            id: 'interests',
            type: 'select',
            placeholder: 'Start typing to search or select an option',
            options: {
                array: true
            },
            allowNone: true,
            displayOnContent: false,
            displayOnImp: false,
            endpoint: ENDPOINTS.LIST_INTERESTS_FOR_PARTNERSHIPS_AND_PROJECTS
        },
        {
            id: 'motivators_none',
            type: 'hidden'
        },
        {
            label: 'Motivators',
            id: 'motivators',
            type: 'select',
            placeholder: 'Start typing to search or select an option',
            options: {
                array: true
            },
            allowNone: true,
            endpoint: ENDPOINTS.LIST_MOTIVATORS
        },
        {
            label: 'Partial Support',
            id: 'partial_support',
            type: 'select',
            displayOnPnP: false,
            displayOnContent: false,
            placeholder: 'Select an option',
            values: [
                {
                    value: 0,
                    label: 'No'
                },
                {
                    value: 1,
                    label: 'Yes'
                }
            ]
        },
        {
            label: 'Explore Pass',
            id: 'explore_pass',
            type: 'select',
            displayOnPnP: false,
            displayOnContent: false,
            placeholder: 'Select an option',
            values: [
                {
                    value: 0,
                    label: 'No'
                },
                {
                    value: 1,
                    label: 'Yes'
                }
            ]
        },
        {
            label: 'High Impact',
            id: 'is_high_impact',
            type: 'select',
            placeholder: 'Select an option',
            displayOnImp: false,
            displayOnPnP: false,
            displayOnContent: false,
            values: [
                {
                    value: 0,
                    label: 'No'
                },
                {
                    value: 1,
                    label: 'Yes'
                }
            ]
        },
        {
            label: 'Premium',
            id: 'is_premium',
            type: 'select',
            placeholder: 'Select an option',
            values: [
                {
                    value: 0,
                    label: 'No'
                },
                {
                    value: 1,
                    label: 'Yes'
                }
            ]
        },
    ]
};


export const STORY_DETAIL_PUBLISHED = {
    id: 'story-detail',
    title: 'Story detail',
    fields: [
        {
            label: 'RTO region',
            id: 'rtos',
            type: 'select',
            placeholder: 'Start typing to search or select multiple regions',
            options: {
                array: true,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_RTOS
        },
    ]
};


export const COVERAGE_DETAILS_PUBLISHED = {
    id: 'coverage-details',
    title: 'Coverage details',
    fields: [
        {
            label: 'Estimated advertising value',
            id: 'eav',
            type: 'number',
            placeholder: 'NZD$',
            toolTip: TOOLTIPS.EAV
        }
    ]
};


export const ABOUT_THE_STORY = {
    id: 'about-the-story',
    title: 'About the story',
    fields: [
        {
            label: 'Result type',
            id: 'result_type',
            type: 'select',
            values: [
                {
                    label: 'IMP',
                    value: 'imp',
                    description: 'International Media Programme (IMP) visitors are taken on an itinerary of New Zealand. The IMP media then return home to create their story. Also includes Partial Support IMP',
                },
                {
                    label: 'Content',
                    value: 'content',
                    description: 'Media stories about New Zealand without hosting media under the International Media Programme. The results generated are called Content Results.'
                },
                {
                    label: 'Partnerships & Projects ',
                    value: 'partners_and_projects',
                    description: 'PR Stories generated from a combination of PR - Activity that isn’t pure IMP or Content Generation. It may include results from a project that combines media visiting NZ and content and/or partnership. Also includes Major Events and Film results.'
                }
            ]
        },
        {
            label: 'Author',
            id: 'author',
            type: 'text',
            displayOnContent: false,
            placeholder: 'Add the Author\'s name',
            toolTip: TOOLTIPS.AUTHOR
        },
        {
            label: 'Visit start date',
            id: 'visit_start_date',
            type: 'date',
            displayOnContent: false,
            displayOnPnP: false,
        },
        {
            label: 'Visit end date',
            id: 'visit_end_date',
            type: 'date',
            displayOnContent: false,
            displayOnPnP: false,
        },
        {
            label: 'Story title',
            id: 'story_title',
            type: 'text',
            placeholder: 'Add the story title'
        },
        {
            label: 'Story summary',
            id: 'story_summary',
            type: 'textarea',
            placeholder: 'Add the story summary. Include extra detail for non-English coverage.'
        },
        {
            label: 'Type of content',
            id: 'type_of_content',
            type: 'select',
            displayOnImp: false,
            displayOnPnP: false,
            placeholder: 'Select a type of content',
            values: [
                {
                    value: 'Corporate PR and Trade',
                    label: 'Corporate PR and Trade'
                },
                {
                    value: 'Destination NZ Proactive',
                    label: 'Destination NZ Proactive'
                },
                {
                    value: 'Destination Reactive & Other',
                    label: 'Destination Reactive & Other'
                }
            ]
        },
        {
            label: 'Assets (optional)',
            id: 'media',
            type: 'file',
            output: true,
            options: {
                accept: 'application/pdf'
            },
            toolTip: TOOLTIPS.ASSETS
        },
        {
            label: 'Article url (optional)',
            id: 'article_url',
            type: 'text',
            placeholder: 'http://'
        },
        {
            label: 'Video url (optional)',
            id: 'video_url',
            type: 'text',
            placeholder: 'http://'
        }
    ]
};

export const COVERAGE_DETAILS = {
    id: 'coverage-details',
    title: 'Coverage details',
    fields: [
        {
            label: 'Country of origin/market',
            id: 'country_id',
            type: 'select',
            placeholder: 'Start typing to search or select a country',
            endpoint: ENDPOINTS.LIST_COUNTRIES
        },
        {
            label: 'Media name/outlet',
            id: 'media_name',
            type: 'textarea',
            placeholder: 'Add the media outlet name'
        },
        {
            label: 'Media type',
            id: 'media_type_id',
            type: 'select',
            placeholder: 'Start typing to search or select an option',
            endpoint: ENDPOINTS.LIST_MEDIA_TYPES
        },
        {
            label: 'Live date',
            id: 'live_date',
            type: 'date',
        },
        {
            label: 'Stories generated',
            id: 'stories_generated',
            type: 'number',
            onlyOnSyndicated: true,
        },
        {
            label: 'Estimated advertising value',
            id: 'eav',
            type: 'number',
            placeholder: 'NZD$',
            toolTip: TOOLTIPS.EAV
        },
        {
            label: 'Reach',
            id: 'reach',
            type: 'number',
            toolTip: TOOLTIPS.REACH
        },
        {
            label: 'Include this result in reporting period',
            id: 'reporting_period_id',
            type: 'select',
            endpoint: ENDPOINTS.LIST_REPORTING_PERIOD
        }
    ]
};
