import { request } from '../utility/request';
import UserActions from '../action/user-actions';
import UserStore from '../store/user-store';
import { ENDPOINTS, HTTP_CODES } from '../config/api';

class AuthService {
    check() {
        if (!UserStore.isLoggedIn()) {
            return Promise.resolve(false);
        }

        return request(ENDPOINTS.CHECK_AUTH);
    }

    login() {
        let data = {};

        return request(ENDPOINTS.USER_CURRENT, data, 'GET')
            .then((response) => {
                this.getUser();
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    getUser() {
        return request(ENDPOINTS.USER_CURRENT)
            .then((response) => {
                if (response.status === HTTP_CODES.UNAUTHORIZED) {
                    // All good, we are just no authenticated.
                } else if (response.status === HTTP_CODES.OK) {
                    return response.json();
                }
            })
            .then((payload) => {
                if (payload && payload.hasOwnProperty('data')) {
                    UserActions.updateUser(payload.data);
                }
            });
    }
}

export default new AuthService();
