import { DATE_FORMAT } from '../config/app';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import React, { Component } from 'react';

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.showCurrentDate = this.showCurrentDate.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);

        this.state = {
            day: '',
            error: false,
            focus: false,
            isCalendarOpen: false,
            month: '',
            year: '',
            value: ''
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        let newState = {
            day: '',
            month: '',
            value: nextProps.value,
            year: ''
        };

        if (nextProps.value !== '') {
            newState.day = moment(nextProps.value, DATE_FORMAT.DEFAULT.format).format('DD');
            newState.month = moment(nextProps.value, DATE_FORMAT.DEFAULT.format).format('MM');
            newState.year = moment(nextProps.value, DATE_FORMAT.DEFAULT.format).format('YY');
        }

        this.setState(newState);
    }

    UNSAFE_componentWillMount() {
        let { value } = this.props;

        if (value) {
            this.setState({
                day: moment(value, DATE_FORMAT.DEFAULT.format).format('DD'),
                month: moment(value, DATE_FORMAT.DEFAULT.format).format('MM'),
                value: value,
                year: moment(value, DATE_FORMAT.DEFAULT.format).format('YY')
            });
        }
    }


    // Events
    fireChangeEvent() {
        setTimeout(() => {
            let date = this.state.value,
                format = DATE_FORMAT.DISPLAY_DATE.format,
                result = moment(date, format).toString();

            // Pass value back to onChange callback
            if (!moment(date, format).isValid()) {
                result = '';
            }

            this.props.onChange(result);
        }, 100); // eslint-disable-line
    }

    pickDate() {
        let format = DATE_FORMAT.DISPLAY_DATE.format,
            value = this.getDateString();

        // Check the value format is correct length e.g all fields are full
        if (value.length !== format.length) return;

        // Only set the date when it is valid
        if (!moment(value, DATE_FORMAT.DISPLAY_DATE.format, true).isValid()) {
            this.setState({ error: 'Incorrect date' });

            return;
        }

        this.fireChangeEvent();

        this.setState({
            error: false,
            value: value
        }, this.showCurrentDate(moment(value, DATE_FORMAT.DISPLAY_DATE.format).toDate()));
    }

    showCurrentDate(value) {
        this.refs.daypicker.showMonth(value);
    }


    // Handlers
    handleBlur(event) {
        let target = event.target,
            value = target.value;

        this.setState({ focus: false });

        // Force double digits if applicable
        if (value !== '' && value.length < parseInt(target.getAttribute('data-size'))) {
            target.value = `0${value}`;

            // Passed now update value
            this.setState({
                day: this.refs.day.value,
                month: this.refs.month.value,
                year: this.refs.year.value
            }, this.pickDate());
        }
    }

    handleCaptureClick() {
        this.refs.day.focus();
        this.setState({ focus: true });
    }

    handleChange(event) {
        let target = event.target,
            value = event.target.value;

        // Prevent entering more than 'data-size' attribute
        if (value.length > parseInt(target.getAttribute('data-size'))) {
            target.value = value.substring(0, target.getAttribute('data-size')); // eslint-disable-line no-magic-numbers

            return;
        }

        // Check if result is a number
        if (value !== '' && (isNaN(parseFloat(value)) || !isFinite(value))) {
            target.value = value.substring(0, value.length - 1); // eslint-disable-line no-magic-numbers

            return;
        }

        // Prevent higher number than 'data-max'
        if (parseInt(value) > parseInt(target.getAttribute('data-max'))) {
            target.value = target.getAttribute('data-max');
        }

        // Passed now update value
        this.setState({
            day: this.refs.day.value,
            month: this.refs.month.value,
            year: this.refs.year.value
        }, this.pickDate());
    }

    handleDayClick(day, event) {
        this.setState({
            isCalendarOpen: false,
            error: false,
            value: moment(day).format(DATE_FORMAT.DISPLAY_DATE.format),
            day: moment(day).format('DD'),
            month: moment(day).format('MM'),
            year: moment(day).format('YY')
        });

        this.fireChangeEvent();
    }

    handleFocus() {
        this.setState({ focus: true });
    }

    handleTriggerClick() {
        this.setState({
            isCalendarOpen: !this.state.isCalendarOpen
        });
    }


    // Getters
    getReturnValue() {
        // Check if valid first
        if (!moment(this.state.value, DATE_FORMAT.DISPLAY_DATE.format, true).isValid()) {
            return '';
        }

        return moment(this.state.value, DATE_FORMAT.DISPLAY_DATE.format).format(DATE_FORMAT.RETURN_DATE.format);
    }

    getDateString() {
        return `${this.refs.day.value}/${this.refs.month.value}/${this.refs.year.value}`;
    }


    // Renders
    renderCalendar() {
        let calendarClass = 'calendar',
            selectedDay = moment(this.state.value, DATE_FORMAT.DISPLAY_DATE.format, true).toDate();

        if (this.state.isCalendarOpen) {
            calendarClass += ' is-open';
        }

        return (
            <div className={calendarClass}>
                <DayPicker
                    ref="daypicker"
                    enableOutsideDays
                    initialMonth={ this.state.initialMonth }
                    selectedDays={ (day) => DateUtils.isSameDay(selectedDay, day) }
                    onDayClick={ this.handleDayClick }
                />
            </div>
        );
    }

    renderError() {
        if (this.state.error) return (<p className="error">{this.state.error}</p>);
    }

    render() {
        let fieldClass = 'input || date-picker',
            triggerClass = 'trigger';

        if (this.state.focus) {
            fieldClass += ' is-focused';
        }

        if (this.state.isCalendarOpen) {
            triggerClass += ' is-active';
        }

        return (
            <div>
                <div className={fieldClass}>
                    <div className="capture">
                        <div className="date">
                            <div role="group" className="field-group">
                                <input
                                    ref="day"
                                    type="text"
                                    data-size="2"
                                    data-max="31"
                                    value={ this.state.day }
                                    onChange={ this.handleChange }
                                    onBlur={ this.handleBlur }
                                    onFocus={ this.handleFocus }
                                    placeholder="DD"/>
                                <span className="break">/</span>
                                <input
                                    ref="month"
                                    type="text"
                                    data-size="2"
                                    data-max="12"
                                    value={ this.state.month }
                                    onChange={ this.handleChange }
                                    onBlur={ this.handleBlur }
                                    onFocus={ this.handleFocus }
                                    placeholder="MM"/>
                                <span className="break">/</span>
                                <input
                                    ref="year"
                                    type="text"
                                    data-size="2"
                                    data-max="99"
                                    value={ this.state.year }
                                    onChange={ this.handleChange }
                                    onBlur={ this.handleBlur }
                                    onFocus={ this.handleFocus }
                                    placeholder="YY"/>
                                {/* Hidden */}
                                <input
                                    ref="date"
                                    type="text"
                                    tabIndex="-1"
                                    className="hidden"
                                    value={ this.getReturnValue() }/>
                            </div>
                        </div>
                        <div
                            ref="focusInput"
                            className="focus-input"
                            onClick={ () => { this.handleCaptureClick(); } }>
                        </div>
                        <button
                            ref="trigger"
                            type="button"
                            className={ triggerClass }
                            onClick={ () => { this.handleTriggerClick(); } }>
                            <svg viewBox="0 0 40 40" width="40" height="40" className="svg" aria-hidden="true" focusable="false">
                                <title>Calendar</title>
                                <use xlinkHref="/assets/icons/_sprite.svg#calendar"></use>
                            </svg>
                        </button>
                    </div>
                    { this.renderCalendar() }
                </div>
                { this.renderError() }
            </div>
        );
    }
}

DatePicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string
};

DatePicker.defaultProps = {
    value: ''
};

export default DatePicker;
