import Alt from '../flux';
import NotificationActions from '../action/notification-actions';

class NotificationStore {
    constructor() {
        this.state = this.getDefaultStateFromConfig();

        // Bind the form actions
        this.bindListeners({
            handlePush: NotificationActions.PUSH
        });
    }


    // Helper
    getDefaultStateFromConfig() {
        return {
            delay: 100, // There is a default delay of 100 for rendering on the component too
            isOpen: false,
            isVisible: false,
            message: '',
            timeout: 4000,
            type: '' // success, warning, error
        };
    }


    // Handler
    handlePush(config) {
        let newState = this.getDefaultStateFromConfig(); // Reset state every time

        // Transfer state
        if (config.hasOwnProperty('delay')) newState.delay = config.delay;
        if (config.hasOwnProperty('message')) newState.message = config.message;
        if (config.hasOwnProperty('timeout')) newState.timeout = config.timeout;
        if (config.hasOwnProperty('type')) newState.type = config.type;

        // Open
        this.setState(newState);
    }
}

export default Alt.createStore(NotificationStore, 'NotificationStore');
