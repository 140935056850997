import Alt from '../flux';

class DialogActions {
    close() {
        return true;
    }

    confirm(message, continueFunc, forceHide = true) {
        return {
            forceHide: forceHide,
            message: message,
            continueFunc: continueFunc
        };
    }

    open(id, data = {}, permissions = {}) {
        return {
            data,
            id,
            permissions
        };
    }
}

export default Alt.createActions(DialogActions);
