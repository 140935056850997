import DocumentTitle from '../utility/document-title';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return (
            <DocumentTitle title="Not Found">
                <main role="main" className="main">
                    <div className="main-inner">

                        <div className="page-header">
                            <div className="badge || not-found"></div>
                            <h1 className="style-h3 || title">Page not found</h1>
                            <p>Sorry we couldn't find the page you were looking for. <br/><Link to="/">Return to the homepage</Link></p>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

export default NotFound;
