import FormActions from '../action/form-actions';
import FormStore from '../store/form-store';
import { getFieldIdsFromArray } from '../utility/fields';
import NotificationActions from '../action/notification-actions';

/**
 * Combine an array of errors into one string
 *
 * @param  {array} errors Array of errors for a particular field
 *
 * @return {string}
 */
export function formatFieldErrorFromApi(errors) {
    return errors.join(' ');
}


/**
 * Lookup API field id and return correct form field id
 *
 * @param  {string} fieldName String of the field id returned from API
 *
 * @return {string}
 */
export function formatFieldNameFromApi(fieldName) {
    // Switch fieldnames returned from API
    switch (fieldName) {
        case 'region.name': return 'regionObject_name';
        case 'user.email': return 'userObject_email';
        case 'user.existing_email': return 'userObject_existing_email';
        case 'user.first_name': return 'userObject_first_name';
        case 'user.last_name': return 'userObject_last_name';
        default: return fieldName;
    }
}


/**
 * Check if the form has errors using validation state on the FormStore
 *
 * @param  {array} formFields Array of the fields to check
 *
 * @return {bool}
 */
export function formHasErrors(formFields) {
    let formFieldIds = getFieldIdsFromArray(formFields),
        hasErrors = false,
        validation = FormStore.getState().validation;

    // Check references for each field and it's validation value in the store
    for (let field in validation) {
        if (formFieldIds.indexOf(field) !== -1 && validation[field] === false) { // eslint-disable-line
            hasErrors = true;

            NotificationActions.push({ type: 'error', message: 'All required fields must be completed to save.' });

            break;
        }
    }

    return hasErrors;
}


/**
 * Reformat the API data to update the form fields in the FormStore
 *
 * @param  {array} fields Array of the current fields to update
 * @param  {object} data Data from the API
 */
export function processDataFromApi(fields, data) {
    // Loop through fields updating the form store using data
    fields.map((field) => {
        let { id } = field,
            fieldData = data[id];
        // Get array of market ids
        if (id === 'market_ids') {
            fieldData = [];
            data.markets.forEach((region) => {
                fieldData.push(region.id);
            });
        }

        // Get array of region ids
        if (id === 'region_ids') {
            fieldData = [];
            data.regions.forEach((region) => {
                fieldData.push(region.id);
            });
        }

        // Get region id
        if (id === 'region') {
            fieldData = data.region.id;
        }

        // Get region name
        if (id === 'region_name') {
            fieldData = data.region.name;
        }

        // Setup role name using first role in array
        if (id === 'role') {
            fieldData = data.roles[0].type; // eslint-disable-line no-magic-numbers
        }

        // Setup organisations
        if (id === 'organisation_id') {
            fieldData = [];
            data.organisations.map((organisation) => {
                fieldData.push(organisation.id);
            });
        }

        FormActions.update(id, fieldData);
    });
}


/**
 * Reformat the form data for the API
 *
 * @param  {object} fields Array of the current fields on the form
 *
 * @return {object} formData formated form data
 */
export function processDataToApi(fields) {
    let data = FormStore.getState().fields,
        formData = {};

    // Add user object if any of the user fields are set
    if (data.hasOwnProperty('userObject_email') || data.hasOwnProperty('userObject_first_name') || data.hasOwnProperty('userObject_last_name') || data.hasOwnProperty('userObject_existing_email')) {
        formData.user = {};
    }

    // Add region object if any of the user fields are set
    if (data.hasOwnProperty('regionObject_name')) {
        formData.region = {};
    }

    // Add region object if any of the user fields are set
    if (data.hasOwnProperty('organisation_id')) {
        formData.organisations = [];
    }

    // Add role object if any of the user fields are set
    if (data.hasOwnProperty('role')) {
        formData.role = [];
    }

    // Loop through fields updating the form store using data
    fields.map((field) => {
        let { id } = field;

        switch (id) {
            case 'regionObject_name':
                formData.region.name = data[id]; // eslint-disable-line camelcase
                break;
            case 'userObject_email':
                formData.user.email = data[id];
                break;
            case 'userObject_first_name':
                formData.user.first_name = data[id]; // eslint-disable-line camelcase
                break;
            case 'userObject_existing_email':
                formData.user.existing_email = data[id]; // eslint-disable-line camelcase
                break;
            case 'userObject_last_name':
                formData.user.last_name = data[id]; // eslint-disable-line camelcase
                break;
            case 'organisation_id':
                if (data.hasOwnProperty(id)) {
                    formData.organisations = data[id];
                }
                break;
            case 'role':
                if (data.hasOwnProperty(id)) {
                    formData.role = data[id];
                }
                break;
            default:
                // Assign value by key
                formData[id] = data[id];
                break;
        }
    });

    return formData;
}


/**
 * Check form response and update error state if applicable
 *
 * @param  {object} response Object returned from the API when a form is submitted
 *
 * @return {bool}
 */
export function responseHasErrors(response) {
    // Guard to check if response errors exist
    if (!response.hasOwnProperty('errors') && !response.hasOwnProperty('error')) {
        return false;
    }

    // Handle multiple
    if (response.hasOwnProperty('errors')) {
        let errors = response.errors;

        // Check for field errors
        if (errors.hasOwnProperty('fields')) {
            for (let prop in errors.fields) {
                FormActions.error(formatFieldNameFromApi(prop), formatFieldErrorFromApi(errors.fields[prop]));
            }
        }
    }

    // Handle single
    if (response.hasOwnProperty('error')) {
        let error = response.error;

        NotificationActions.push({
            message: error,
            type: 'error'
        });
    }

    return true;
}
