import { ENDPOINTS } from '../config/api';
import FilterActions from '../action/filter-actions';
import FilterStore from '../store/filter-store';
import { formatFilters } from '../utility/fields';
import PropTypes from 'prop-types';
import { request } from '../utility/request';
import Select from 'react-select';
import React, { Component } from 'react';

const DEFAULT_SELECT_OPTION = { id: '', name: 'All' };

// This component mirrors './filters.jsx' so
// features from there could be ported here
// in the future

class OrganisationFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                region: [DEFAULT_SELECT_OPTION]
            },
            fields: FilterStore.getState().filters
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }


    // Life Cycle
    componentDidMount() {
        // Regions
        this.getOptionsFromApi(ENDPOINTS.LIST_REGIONS);
    }


    // Event
    onSubmit() {
        const filters = this.prepareFilters();

        // Trigger props callback
        this.props.onSubmit(filters);
    }


    // Handler
    handleSelectChange(value) {
        const { fields } = this.state;

        fields.region = value;

        this.setState({ fields });

        FilterActions.save(fields);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.onSubmit();
    }


    // Helpers
    selectedOption(type) {
        return this.state.fields[type];
    }

    getOptionsFromApi(url) {
        request(url)
            .then((response) => response.json())
            .then((response) => {
                const { data } = this.state;

                data.region = response.data.map(({ id, name }) => {
                    return { value: id, label: name };
                });

                this.setState({ data });
            });
    }

    prepareFilters() {
        const filters = formatFilters(this.state.fields);

        const fields = {
            market : '',
            monthFrom : '',
            monthTo : '',
            organisation : '',
            resultType : '',
            rto : '',
            search : '',
            searchBy : '0',
            yearFrom : '',
            yearTo : '',
            premium : '',
            impact : ''
        };

        this.setState({ fields: fields });

        return filters;
    }

    // Render
    render() {
        return (
            <form className="filter-bar" onSubmit={this.handleSubmit}>
                <div className="field-group || type || hide-to-desktop">
                    <Select
                        className="Select input || select"
                        classNamePrefix="Select"
                        name="region"
                        value={this.selectedOption('region')}
                        onChange={this.handleSelectChange}
                        options={this.state.data.region}
                        placeholder={'Enter region'}
                        isClearable={true}
                    />
                </div>
                <div className="field-group">
                    <button className="button primary || submit" type="submit" onClick={this.onSubmit}>
                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                            <use xlinkHref="/assets/icons/_sprite.svg#search"></use>
                        </svg>
                        <span className="button-label">Search</span>
                    </button>
                </div>
            </form>
        );
    }
}

OrganisationFilters.propTypes = {
    onSubmit: PropTypes.func
};

OrganisationFilters.defaultProps = {
    onSubmit: () => null
};

export default OrganisationFilters;
