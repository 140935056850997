import { debounceTime } from 'rxjs/operators';
import PropTypes from 'prop-types';
import React from 'react';
import scrollObservable from '../utility/listener/window-scroll';

const SCROLLING_THROTTLE_TIMEOUT = 300;
const WINDOW_SCROLL_TIMEOUT = 2000;

class RestoreScroll extends React.Component {
    constructor(props) {
        super(props);

        this.windowScroll = null;
    }


    // Lifecycle
    componentDidMount() {
        // Save window position on each route
        this.windowScroll = scrollObservable.pipe(debounceTime(SCROLLING_THROTTLE_TIMEOUT)).subscribe(this.handleScroll.bind(this));

        // Detect Route change and scroll to saved point if it exists
        this.props.history.listen(() => {
            let { history } = this.props.children.props;
            if (history && history.state && history.state.scrollTop) {
                setTimeout(() => {
                    window.scroll(0, history.state.scrollTop); // eslint-disable-line
                }, WINDOW_SCROLL_TIMEOUT);
            }
        });
    }

    componentWillUnmount() {
        this.windowScroll.unsubscribe();
        // TODO this.props.history.unlisten(); is not a valid function and does not seem to necessary.
        // If so please remove.
    }


    // Handlers
    handleScroll() {
        let { history } = this.props.children.props;
        if (history && history.state) {
            const scrollY = window.scrollY;
            const stateData = {
                key: this.props.history.state.key,
                path: window.location.href,
                scrollTop: scrollY
            };

            this.props.history.replaceState(stateData, document.title, document.location.pathname);
        }
    }

    // state = {  }
    render() {
        return this.props.children;
    }
}

RestoreScroll.propTypes = {
    children: PropTypes.element
};

export default RestoreScroll;
