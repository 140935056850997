import Pagination from '../component/pagination';
import PropTypes from 'prop-types';
import Table from '../component/table';
import React, { Component } from 'react';

class List extends Component {
    constructor(props) {
        super(props);

        let { header, limit, number } = this.props;

        this.state = {
            data: this.props.data,
            currentPage: number,
            header: header,
            pagination: this.props.pagination,
            limit: limit,
            loading: this.props.loading
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { data, header, loading, number, pagination } = nextProps;

        // Only update results if different
        this.setState({
            currentPage: number,
            data: data,
            header: header,
            loading: loading,
            pagination: pagination
        });
    }

    handleSubmit(fields) {
        this.props.onSubmit(fields);
    }

    handleStatusHeaderClick(value) {
        this.props.onStatusHeaderClick(value);
    }


    // Render
    render() {
        return (
            <div>
                <Table
                    onSubmit={this.handleSubmit.bind(this)}
                    body={this.state.data}
                    format={this.props.format}
                    header={this.state.header}
                    onStatusHeaderClick={this.handleStatusHeaderClick.bind(this)}
                    loading={this.state.loading}
                    number={this.state.currentPage}
                />
                <Pagination
                    data={this.state.pagination}
                    limit={this.state.limit}
                    number={this.state.currentPage}
                    view={this.props.view}
                />
            </div>
        );
    }
}

List.propTypes = {
    data: PropTypes.array.isRequired,
    format: PropTypes.string, // Links to formatting rows
    header: PropTypes.array.isRequired, // An array of header table cell objects
    limit: PropTypes.number, // Limit of items per page
    loading: PropTypes.bool, // Limit of items per page
    number: PropTypes.number, // Page number
    pagination: PropTypes.object.isRequired,
    view: PropTypes.string.isRequired, // View used to create pagination links
    onSubmit: PropTypes.func,
    onStatusHeaderClick: PropTypes.func,
};

List.defaultProps = {
    onSubmit: function() {}, // eslint-disable-line
};


export default List;
