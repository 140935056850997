import { ENDPOINTS } from '../config/api';
import { request } from './request';
import { useQuery } from '@tanstack/react-query';

const LIMIT = 9999;

/**
 * Function to get users from the API using tanstack/react-query to cache results.
 *
 * @return {object} Object with usersQueryData, error, and isLoading
 */
export function getUsers() {
    const {
        data: usersQueryData,
        error,
        isLoading,
    } = useQuery(['users'], () => {
        return request(`${ENDPOINTS.LIST_USERS}?limit=${LIMIT}`).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        });
    }
    );

    return {
        usersQueryData,
        error,
        isLoading,
    };
}
