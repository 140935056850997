import classnames from 'classnames';
import { FORM_CONTROLS_HOOK } from '../config/app';
import NotificationStore from '../store/notification-store';
import React, { Component } from 'react';

const ANIMATE_OUT_DELAY = 500;

class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = NotificationStore.getState();
    }


    // Mounting
    componentDidMount() {
        NotificationStore.listen(this.onChange.bind(this));
    }

    componentWillUnmount() {
        NotificationStore.unlisten(this.onChange.bind(this));
    }


    // Events
    onChange() {
        this.setState(NotificationStore.getState(), this.show.bind(this));
    }


    // Helpers
    hide() {
        this.setState({ isVisible: false }, () => {
            // Hide once animated delay
            setTimeout(() => {
                this.setState({ isOpen: false });
            }, ANIMATE_OUT_DELAY);
        });
    }

    show() {
        let formControls,
            isOffset = false;

        // Show after timeout
        setTimeout(() => {
            // If form actions are within this view the notifcation will be offset
            formControls = document.querySelectorAll(`[data-hook='${FORM_CONTROLS_HOOK}']`);
            if (formControls.length > 0) { // eslint-disable-line no-magic-numbers
                isOffset = true;
            }

            // Show after animate in delay
            this.setState({ isOffset: isOffset, isOpen: true }, () => {
                setTimeout(() => {
                    this.setState({ isVisible: true, show: false });
                    // Hide after timeout delay
                    setTimeout(this.hide.bind(this), this.state.timeout);
                }, 100); // eslint-disable-line no-magic-numbers
            });
        }, this.state.delay);
    }

    render() {
        let notificationClass,
            { isOffset, isOpen, isVisible, message, type } = this.state;

        notificationClass = classnames('global-notifications', {
            'is-offset' : isOffset,
            'is-open': isOpen,
            'is-visible': isVisible,
            [`type-${type}`]: type
        });

        return (
            <div className={notificationClass}>
                <div className="inner">
                    <div className="constrain-width || large">
                        <p className="item">{message}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Notification;
