import Alt from '../flux';
import FilterActions from '../action/filter-actions';
import { KEYWORD } from '../config/app';
import UserActions from '../action/user-actions';

const INITAL_STATE = {
    filters: {
        monthFrom: '', // moment().format(FORMAT_MONTH),
        yearFrom: '', // moment().format(FORMAT_YEAR),
        monthTo: '', // moment().format(FORMAT_MONTH),
        yearTo: '', // moment().format(FORMAT_YEAR),
        search: '',
        resultType: '',
        market: '',
        rto: '',
        searchBy: KEYWORD,
        organisation: '',
        region: '',
        user : '',
        premium : '',
        impact : '',
        sortOrder : 'desc',
        userStatus : '',
        resultStatus : '',
    }
};

class FilterStore {
    constructor() {
        let filters = localStorage.getItem('filters');

        this.state = INITAL_STATE;

        if (filters) {
            // Set state filters to match local storage filters when set
            this.state = {
                filters: JSON.parse(filters)
            };
        }

        this.bindListeners({
            save: FilterActions.SAVE,
            clear: UserActions.LOGOUT
        });
    }

    // Function to store filters in local storage
    save(filtersObject) {
        // Save to state
        this.state.filters = filtersObject;

        // Save to local storage
        try {
            localStorage.setItem('filters', JSON.stringify(filtersObject));
        } catch (exception) { // Catch needed for Mobile Safari + Jest
            console.warn('Local storage exception', exception); // eslint-disable-line no-console
        }
    }

    clear() {
        // Remove stored filters from local storage and reset state filters
        localStorage.removeItem('filters');
        this.setState(INITAL_STATE);
    }
}

export default Alt.createStore(FilterStore, 'FilterStore');
