import React from 'react';

/**
 * Return a list of errors
 *
 * @param  {object} errors Object with error fields to loop through
 *
 * @return {HTML}
 */
function loopThroughErrors(errors) {
    for (let field in errors) {
        return (<p className="error">{errors[field]}</p>);
    }
}

/**
 * Render Form Errors to React
 *
 * @param  {object} errors Object with error fields to loop through
 *
 * @return {HTML}
 */
export function renderFormErrors(errors) {
    return (
        <div className="form-errors">{ loopThroughErrors(errors) }</div>
    );
}
