import { ENDPOINTS } from './api';

export const USER_ORGANISATION = {
    id: 'user-org',
    title: 'Invite user',
    fields: [
        {
            id: 'organisation_id',
            label: 'Organisation',
            required: true,
            type: 'select',
            placeholder: 'Select an organisation',
            options: {
                allowCreate: false,
                array: false,
                autoload: true,
                isMulti: false,
                limit: 9999
            },
            endpoint: ENDPOINTS.LIST_ORGANISATIONS
        },
        {
            id: 'role',
            label: 'User permission',
            required: true,
            type: 'select',
            placeholder: 'Select a role',
            values: [
                {
                    label: 'Admin',
                    value: 'admin'
                },
                {
                    label: 'User',
                    value: 'user'
                }
            ]
        },
        {
            id: 'first_name',
            label: 'First name',
            required: true,
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            placeholder: 'Add their email address'
        }
    ]
};

export const USER_TNZ = {
    id: 'user-tnz',
    title: 'Invite TNZ user',
    fields: [
        {
            id: 'first_name',
            label: 'First name',
            required: true,
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            placeholder: 'Add their email address'
        }
    ],
    hiddenValues: {
        organisation_id: null, // eslint-disable-line camelcase
        role: 'admin'
    }
};

export const USER = {
    id: 'user',
    title: 'Invite user',
    fields: [
        {
            id: 'role',
            label: 'Role',
            required: true,
            type: 'select',
            placeholder: 'Select a role',
            values: [
                {
                    label: 'Admin',
                    value: 'admin'
                },
                {
                    label: 'User',
                    value: 'user'
                }
            ]
        },
        {
            id: 'first_name',
            label: 'First name',
            required: true,
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            placeholder: 'Add their email address'
        },
    ]
};

export const USER_WITH_ORGANISATION = {
    id: 'user',
    title: 'Invite user',
    fields: [
        {
            id: 'role',
            label: 'Role',
            required: true,
            type: 'select',
            placeholder: 'Select a role',
            values: [
                {
                    label: 'Admin',
                    value: 'admin'
                },
                {
                    label: 'User',
                    value: 'user'
                }
            ]
        },
        {
            id: 'first_name',
            label: 'First name',
            required: true,
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            placeholder: 'Add their email address'
        },
        {
            id: 'organisation_id',
            label: 'Organisation',
            required: true,
            type: 'select-async',
            placeholder: 'Select an organisation',
            options: {
                allowCreate: false,
                array: false,
                autoload: false,
                isMulti: true
            },
            endpoint: ENDPOINTS.LIST_ORGANISATIONS
        },
    ]
};

export const USER_CURRENT = {
    id: 'user',
    title: 'Edit user',
    fields: [
        {
            id: 'first_name',
            label: 'First name',
            required: true,
            type: 'text',
            placeholder: 'Add their first name'
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
            type: 'text',
            placeholder: 'Add their last name'
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            type: 'email',
            placeholder: 'Add their email address'
        }
    ]
};
