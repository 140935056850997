import Dialog from '../component/dialog';
import Footer from './partial/footer';
import Header from './partial/header';
import Notification from '../component/notification';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Base extends Component {
    render() {
        return (
            <div className="container">
                <Header />

                {this.props.children}

                <Footer />
                <Dialog />
                <Notification />
            </div>
        );
    }
}

Base.propTypes = {
    children: PropTypes.element
};

export default Base;
