import Alt from '../../flux';
import classnames from 'classnames';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import Form from '../../component/form';
import FormActions from '../../action/form-actions';
import FormControls from '../../component/form-controls';
import FormStore from '../../store/form-store';
import PropTypes from 'prop-types';
import { request } from '../../utility/request';
import { ADMIN_USER, AIRLINE } from '../../config/organisation';
import { formHasErrors, processDataToApi, responseHasErrors } from '../../utility/form';
import React, { Component } from 'react';

// Setup all the form fields being used in this view
const ALL_FORM_FIELDS = [...AIRLINE.fields, ...ADMIN_USER.fields];

class NewOrganisation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            loading: true,
            isValid: new Set()
        };
    }


    // Mounting
    UNSAFE_componentWillMount() {
        // Refresh the store
        Alt.recycle(FormStore);

        // Pass all fields to the formActions to setup store
        FormActions.setup(ALL_FORM_FIELDS);
    }

    componentDidMount() {
        setTimeout(() => {
            // Remove loading state
            this.setState({ loading: false });
        }, FAUX_LOADING_TIME);
    }


    // Handlers
    handleSubmit(event) {
        event.preventDefault();

        // Add 'submitting' status
        FormActions.submit();

        // Validate
        if (!formHasErrors(ALL_FORM_FIELDS)) {
            this.submit();
        }
    }


    // Helpers
    submit() {
        let data = {};

        // Set loading state
        this.setState({ loading: true });

        // Process the current form data
        data = processDataToApi(ALL_FORM_FIELDS);

        // Submit request
        request(ENDPOINTS.AIRLINE, data, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    this.setState({ loading: false });
                    if (!responseHasErrors(response)) {
                        this.props.history.goBack();
                    }
                }, FAUX_LOADING_TIME);
            });
    }


    // Rendering
    render() {
        let buttonTypes = ['save', 'cancel'],
            mainClass,
            title = 'Create Airline';

        mainClass = classnames({
            'main': true,
            'is-loading': this.state.loading
        });

        return (
            <DocumentTitle title={title}>
                <main role="main" className={mainClass}>
                    <div className="main-inner">
                        <form ref="form" action="/" className="editing-form || constrain-width" onSubmit={this.handleSubmit.bind(this)}>
                            <Form {...AIRLINE}/>
                            <Form {...ADMIN_USER}/>
                            <FormControls types={buttonTypes}/>
                        </form>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

NewOrganisation.propTypes = {
    match: PropTypes.object
};

export default NewOrganisation;
