import DatePicker from '../component/date-picker';
import DocumentTitle from '../utility/document-title';
import Select from 'react-select';
import ToolTip from '../component/tool-tip';
import React, { Component } from 'react';

const CONFIG = {
    singleSelect: {
        placeholder: 'Choose one option',
        options: [
            { value: '1', label: 'One' },
            { value: '2', label: 'Two' },
            { value: '3', label: 'Three' },
            { value: '4', label: 'Four' },
            { value: '5', label: 'Five' },
            { value: '6', label: 'Six' }
        ]
    },
    multiSelect: {
        placeholder: 'Choose multiple options or add your own',
        allowCreate: true,
        addLabelText: 'Press enter to add "{label}" as an option?',
        multi: true,
        options: [
            { value: '1', label: 'Bahamas' },
            { value: '2', label: 'Bangladesh' },
            { value: '3', label: 'Barbados' },
            { value: '4', label: 'Belarus' },
            { value: '5', label: 'Cameroon' },
            { value: '6', label: 'Canada' },
            { value: '7', label: 'Denmark' },
            { value: '8', label: 'Dominican Republic' },
            { value: '9', label: 'Egypt' },
            { value: '10', label: 'El Salvador' },
            { value: '11', label: 'Fiji' },
            { value: '12', label: 'Finland' },
            { value: '13', label: 'France' },
        ]
    },
    radioGroup: {
        options: [
            {
                value: '1',
                label: 'IMP',
                description: 'International Media Programme (IMP) visitors are taken on an itinerary of New Zealand. The IMP media then return home to create their story.',
            },
            {
                value: '2',
                label: 'Content',
                description: 'Media stories about New Zealand without hosting media under the International Media Programme. The results generated are called Content Results.',
            }
        ]
    }
};

class StyleGuide extends Component {
    render() {
        return (
            <DocumentTitle title="Style Guide">
                <main role="main" className="main || style-guide">
                    <div className="main-inner">
                        <div className="constrain-width">

                            <header role="heading" className="page-header">
                                <h1>Style Guide</h1>
                                <p>A working site style guide built for design and code quality control.</p>
                            </header>

                            <section className="section">
                                <h2>Colour</h2>
                                <hr/>

                                {/* Base Theme Colors */}
                                <ul className="colours">
                                    <li className="primary-shade-4">Primary Shade 4</li>
                                    <li className="primary-shade-3">Primary Shade 3</li>
                                    <li className="primary-shade-2">Primary Shade 2</li>
                                    <li className="primary-shade-1">Primary Shade 1</li>
                                    <li className="primary">Primary</li>
                                    <li className="primary-tint-1">Primary Tint 1</li>
                                    <li className="primary-tint-2">Primary Tint 2</li>
                                    <li className="primary-tint-3">Primary Tint 3</li>
                                    <li className="primary-tint-4">Primary Tint 4</li>
                                    <li className="secondary">Secondary</li>
                                    <li className="secondary-tint-1">Secondary Tint 1</li>
                                    <li className="secondary-tint-2">Secondary Tint 2</li>
                                    <li className="tertiary-shade-2 light">Tertiary Shade 2</li>
                                    <li className="tertiary-shade-1 light">Tertiary Shade 1</li>
                                    <li className="tertiary light">Tertiary</li>
                                    <li className="quarternary">Quarternary</li>
                                    <li className="quinary">Quinary</li>
                                    <li className="senary">Senary</li>
                                </ul>
                            </section>

                            <section className="section">
                                <h2>Typography</h2>
                                <hr/>

                                {/* Heading Styles: h1, h2, h3, h4, h5, h6 */}
                                <h1>Heading 1</h1>
                                <h1 className="thin">Heading 1: Thin</h1>
                                <h2>Heading 2</h2>
                                <h2 className="thin">Heading 2: Thin</h2>
                                <h3>Heading 3</h3>
                                <h4>Heading 4</h4>
                                <h5>Heading 5</h5>
                                <h6>Heading 6</h6>

                                {/* Paragraph: strong, em, a */}
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                                <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                                <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                            </section>

                            <section className="section">
                                <h2>Buttons</h2>
                                <hr/>

                                {/* Buttons: primary, secondary, disabled */}
                                <div className="button-group">
                                    <a className="button">Button</a>&nbsp;
                                    <button className="button">
                                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                                            <use xlinkHref="/assets/icons/_sprite.svg#bin"></use>
                                        </svg>
                                        Button
                                    </button>&nbsp;
                                    <button className="button disabled">Disabled Button</button>&nbsp;
                                    <br />
                                    <a className="button medium">Medium Button</a>
                                    <a className="button small">Small Button</a>
                                </div>
                                <div className="button-group">
                                    <a className="button primary">Primary Button</a>&nbsp;
                                    <button className="button primary">Primary Button</button>&nbsp;
                                    <button className="button primary disabled">Disabled Primary Button</button>&nbsp;
                                    <br />
                                    <a className="button primary medium">Medium Primary Button</a>
                                    <a className="button primary small">Small Primary Button</a>
                                </div>
                                <div className="button-group">
                                    <a className="button secondary">Secondary Button</a>&nbsp;
                                    <button className="button secondary">
                                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                                            <use xlinkHref="/assets/icons/_sprite.svg#pencil"></use>
                                        </svg>
                                        Secondary Button
                                    </button>&nbsp;
                                    <button className="button secondary disabled">Disabled Secondary Button</button>&nbsp;
                                    <br />
                                    <a className="button secondary medium">Medium Secondary Button</a>
                                    <a className="button secondary small">Small Secondary Button</a>
                                </div>
                                <div className="button-group dark">
                                    <a className="button tertiary">Tertiary Button</a>&nbsp;
                                    <button className="button tertiary">
                                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                                            <use xlinkHref="/assets/icons/_sprite.svg#cog"></use>
                                        </svg>
                                        Tertiary Button
                                    </button>&nbsp;
                                    <button className="button tertiary disabled">Disabled Tertiary Button</button>&nbsp;
                                    <br />
                                    <a className="button tertiary medium">Medium Tertiary Button</a>
                                    <a className="button tertiary small">Small Tertiary Button</a>
                                </div>
                                <div className="button-group">
                                    <a className="button quarternary">Quarternary Button</a>&nbsp;
                                    <button className="button quarternary">
                                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                                            <use xlinkHref="/assets/icons/_sprite.svg#globe"></use>
                                        </svg>
                                        Quarternary Button
                                    </button>&nbsp;
                                    <button className="button quarternary disabled">Disabled Quarternary Button</button>&nbsp;
                                    <br />
                                    <a className="button quarternary medium">Medium Quarternary Button</a>
                                    <a className="button quarternary small">Small Quarternary Button</a>
                                </div>
                                <div className="button-group">
                                    <a className="button quinary">Quinary Button</a>&nbsp;
                                    <button className="button quinary">
                                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                                            <use xlinkHref="/assets/icons/_sprite.svg#pencil"></use>
                                        </svg>
                                        Quinary Button
                                    </button>&nbsp;
                                    <button className="button quinary disabled">Disabled Quinary Button</button>&nbsp;
                                    <br />
                                    <a className="button quinary medium">Medium Quinary Button</a>
                                    <a className="button quinary small">Small Quinary Button</a>
                                </div>
                            </section>

                            <section className="section">
                                <h2>Lists</h2>
                                <hr/>

                                {/* Lists: strong, em, a */}
                                <ul>
                                    <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                                    <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                                    <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                                    <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                                </ul>
                                <ol>
                                    <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                                    <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                                    <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                                    <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                                </ol>
                            </section>

                            <section className="section">
                                <h2>Pills</h2>
                                <hr/>

                                <div className="pill">Default</div>
                                <div className="pill warning">Warning</div>
                                <div className="pill primary">Primary</div>
                            </section>

                            <section className="section">
                                <h2>Icons</h2>
                                <hr/>

                                <div className="icon-grid">

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#arrow-left"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#arrow-right"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#bin"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#book"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#calendar"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#chevron"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#cog"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#cross"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#disk"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#envelope"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#globe"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#hamburger"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#help"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#lock-plus"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#lock-question-mark"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#lock-tick"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#message"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#pencil"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#power"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#radio"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#caution-badge"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#tick-badge"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#tv"></use>
                                    </svg>

                                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg">
                                        <use xlinkHref="/assets/icons/_sprite.svg#view"></use>
                                    </svg>

                                </div>
                            </section>

                            <section className="section">
                                <h2>Forms</h2>
                                <hr/>

                                <form action="/" className="form">
                                    <div role="group" className="fieldset">

                                        {/* Text */}
                                        <div className="field has-error">
                                            <label className="label" htmlFor="field-1">Label for text field</label>
                                            <ToolTip content="This is a small label" />
                                            <input type="text" className="input" id="field-1" placeholder="Placeholder text" required/>
                                            <p className="error">This is an error message</p>
                                        </div>

                                        {/* Textarea */}
                                        <div className="field">
                                            <label className="label" htmlFor="field-2">Label for textarea field</label>
                                            <ToolTip size="large" content="This is a label with a large amount of content. You can insert HTML here too like <strong> and <em> tags for example." />
                                            <textarea id="field-2" className="input" placeholder="Placeholder text" required></textarea>
                                        </div>

                                        {/* Single Select */}
                                        <div className="field">
                                            <label className="label" htmlFor="select-1">Select</label>
                                            <Select className="input || select" id="select-1" {...CONFIG.singleSelect} />
                                        </div>

                                        {/* Multi Select */}
                                        <div className="field">
                                            <label className="label" htmlFor="select-2">Multi Select</label>
                                            <div className="allow-none-wrapper">
                                                <Select className="input || select multiple" id="select-2" {...CONFIG.multiSelect} />
                                                <div className="allow-none">
                                                    <input className="option-input" type="checkbox" id="allow-none" />
                                                    <label className="option-label" htmlFor="allow-none">None</label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Date */}
                                        <div className="field">
                                            <label className="label" htmlFor="field-1">Single date</label>
                                            <ToolTip content="This is a small label" />
                                            <DatePicker />
                                        </div>

                                        {/* Radio */}
                                        <div className="field">
                                            <label className="label" htmlFor="field-1">Radio Group</label>
                                            <div role="group" className="input || radio-group">
                                                <div className="item">
                                                    <input className="input" name="type" type="radio" value="1" id="option-1" selected/>
                                                    <label className="label" htmlFor="option-1">
                                                        <span className="title || style-h3">{ CONFIG.radioGroup.options[0].label }</span>
                                                        <span className="description">{ CONFIG.radioGroup.options[0].description }</span>
                                                    </label>
                                                </div>
                                                <div className="item">
                                                    <input className="input" name="type" type="radio" value="2" id="option-2" />
                                                    <label className="label" htmlFor="option-2">
                                                        <span className="title || style-h3">{ CONFIG.radioGroup.options[1].label }</span>
                                                        <span className="description">{ CONFIG.radioGroup.options[1].description }</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

export default StyleGuide;
