import ButtonDropdown from '../../component/button-dropdown';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import FilterStore from '../../store/filter-store';
import { formatFilters } from '../../utility/fields';
import List from '../../component/list';
import OrganisationFilters from '../../component/organisation-filters';
import { ORGANISATIONS } from '../../config/tables';
import { prepareListEndpoint } from '../../utility/list';
import PropTypes from 'prop-types';
import { queryPrefix } from '../../utility/format';
import { request } from '../../utility/request';
import UserStore from '../../store/user-store';
import React, { Component } from 'react';

const STARTING_PAGE = '1';

class ListOrganisations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            currentPage: null,
            loading: true,
            pagination: {}
        };

        this.onChange = this.onChange.bind(this);
    }


    // Life Cycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { number } = nextProps.match.params;

        this.populateData(number);
    }

    UNSAFE_componentWillMount() {
        const { number } = this.props.match.params;

        this.populateData(number);

        UserStore.listen(this.onChange);
    }

    componentWillUnmount() {
        UserStore.unlisten(this.onChange);
    }


    // Handlers
    handleSubmit(filters) {
        const endpointParams = this.prepareEndpointParams(filters);

        this.setState({
            endpointParams: endpointParams,
        }, () => {
            // Reset Page to first
            this.props.history.push('/organisations/page/1');
        });

        this.populateData(STARTING_PAGE, filters);
    }


    // Event
    onChange() {
        const currentOrganisationId = UserStore.getState().user.organisationId;

        if (this.state.userOrganisationId !== currentOrganisationId) {
            this.reloadData();
            this.setState({
                userOrganisationId: currentOrganisationId
            });
        }
    }


    // Helpers
    getEndpointParams() {
        const filters = formatFilters(FilterStore.getState().filters);
        const preparedEndpointParams = this.prepareEndpointParams(filters);

        // Check if the state endpoint params matches the filters in local storage
        if (this.state.endpointParams !== preparedEndpointParams) {
            // Update the state endpoint params with the updated local storage filters
            this.setState({
                endpointParams: preparedEndpointParams,
            });
        }

        return preparedEndpointParams;
    }

    populateData(number) {
        // Get the latest endpoint params
        const endpointParams = this.getEndpointParams();
        const endpointPrepared = prepareListEndpoint(endpointParams, this.state.limit, number);

        this.setState({ currentPage: parseInt(number), loading: true });

        // Make request
        request(ENDPOINTS.LIST_ORGANISATIONS + endpointPrepared)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.data) {
                    const { data, meta } = response;
                    const tableHeader = ORGANISATIONS.tableHeaderData;
                    let newPermissions = this.state.permissions;

                    // Remove status column title if can't view status
                    if (!meta.permissions.can_view_status) {
                        tableHeader[tableHeader.length - 1].data.value = ''; // eslint-disable-line no-magic-numbers
                    }

                    // Only set permissions the first time
                    if (!newPermissions) {
                        newPermissions = meta.permissions;
                    }

                    this.setState({
                        data: data,
                        eav: meta.eav,
                        pagination: meta.pagination,
                        permissions: newPermissions,
                        tableHeader: tableHeader,
                        total: meta.stories_generated || meta.pagination.total
                    }, () => {
                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, FAUX_LOADING_TIME);
                    });
                }
            });
    }

    prepareEndpointParams(filters) {
        const { region } = filters;
        let endpointParams = '';

        // Add region
        if (region) {
            endpointParams += `${queryPrefix(endpointParams)}region=${region.value}`;
        }

        return endpointParams;
    }

    reloadData() {
        this.populateData(1); // eslint-disable-line no-magic-numbers
    }


    // Render
    render() {
        const buttonConfig = {
            class: 'create-organisations',
            label: 'Create operator',
            action: '/operators/new',
            buttons: [
                {
                    label: 'Create RTO',
                    action: '/rto/new'
                },
                {
                    label: 'Create event/film',
                    action: '/event-organiser/new'
                },
                {
                    label: 'Create airline',
                    action: '/airline/new'
                },
                {
                    label: 'Create offshore agency',
                    action: '/offshore-agency/new'
                }
            ]
        };

        return (
            <DocumentTitle title="Organisations">
                <main role="main" className="main || dashboard">
                    <div className="main-inner">

                        <div className="subpage-banner">
                            <div className="banner-actions">
                                <div className="inner || constrain-width large">

                                    <div className="title">
                                        <h1 className="style-h3 medium">Organisations</h1>
                                    </div>

                                    <OrganisationFilters onSubmit={this.handleSubmit.bind(this)}/>

                                    <ButtonDropdown {...buttonConfig} />

                                </div>
                            </div>
                        </div>

                        <List
                            data={this.state.data}
                            format="organisations"
                            header={ORGANISATIONS.tableHeaderData}
                            number={this.state.currentPage}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            limit={this.state.pagination.per_page}
                            view={ORGANISATIONS.view} />
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ListOrganisations.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default ListOrganisations;
