import Alt from '../flux';

class UserActions {
    logout() {
        return this.updateToken(null);
    }

    updateUser(user) {
        return user;
    }

    updateToken(token) {
        return token;
    }
}

export default Alt.createActions(UserActions);
