import Alt from '../flux';
import FormActions from '../action/form-actions';

class FormStore {
    constructor() {
        this.state = this.getDefaultStateFromConfig();

        this.state = {
            fields: {},
            submitting: false,
            validation: {}
        };

        // Bind the form actions
        this.bindListeners({
            handleSetup: FormActions.SETUP,
            handleSubmit: FormActions.SUBMIT,
            handleFieldError: FormActions.ERROR,
            handleFieldUpdate: FormActions.UPDATE
        });

        this.exportPublicMethods({
            isSubmitting: this.isSubmitting
        });
    }


    // Accessors
    isSubmitting() {
        return Boolean(this.state.submitting);
    }


    // Helper
    getDefaultStateFromConfig() {
        return {
            fields: {},
            submitting: false,
            validation: {}
        };
    }


    // Handler
    handleFieldError(field) {
        // Update fields using reference id in state[fields]
        this.state.validation[field.id] = field.error;
    }

    handleFieldUpdate(field) {
        if (field.value === undefined || field.value === null) {
            field.value = '';
        }

        // Update fields using reference id in state[fields]
        this.state.fields[field.id] = field.value;
        this.state.validation[field.id] = false;

        // Only validate required fields check for required field
        if (field.value !== '' && field.value !== null && field.value.length !== 0) { // eslint-disable-line
            this.state.validation[field.id] = true;
        }
    }

    handleSetup(rawFields) {
        let fields = {},
            validation = {};

        // Set empty values as default
        rawFields.map((item) => {
            let { id } = item;

            // Set empty values as default
            fields[id] = '';

            // Set validation only for ones that are required
            if (item.hasOwnProperty('required')) {
                validation[id] = false;
            } else {
                validation[id] = true;
            }
        });

        this.state.fields = fields;
        this.state.validation = validation;
    }

    handleSubmit(status) {
        this.state.submitting = status;
    }
}

export default Alt.createStore(FormStore, 'FormStore');
