import DocumentTitle from '../../utility/document-title';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class ResetPasswordSuccess extends Component {
    render() {
        return (
            <DocumentTitle title="Reset Password">
                <main role="main" className="main">
                    <div className="main-inner">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="badge || new-password-success"></div>
                            <h1 className="style-h3 || title">Success!</h1>
                            <div className="constrain-width tiny">
                                <p className="text">You have successfully reset your password, would you like to sign in?</p>
                                <Link className="button primary" to="/">Sign in</Link>
                            </div>
                        </div>

                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

export default ResetPasswordSuccess;
