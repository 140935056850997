import ButtonDropdown from '../component/button-dropdown';
import DialogActions from '../action/dialog-actions';
import { FORM_CONTROLS_HOOK } from '../config/app';
import Permission from '../service/permission-service';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class ResultFormControls extends Component {
    constructor(props) {
        super(props);
    }


    // Handlers
    handleCancel() {
        DialogActions.confirm('You will lose any unsaved changes.', this.back.bind(this));
    }


    // Helpers
    back() {
        this.props.history.goBack();
    }


    // Render
    renderCancelButton() {
        return (<button type="button" className="item || link" onClick={this.handleCancel.bind(this)}>Cancel</button>);
    }

    renderDeleteButton() {
        return (<button type="button" className="item || link" onClick={this.props.handleDelete.bind(this)}>Delete</button>);
    }


    // Render
    renderPrimaryActions() {
        let buttonConfig,
            { status, uploading } = this.props;

        buttonConfig = {
            class: 'item || align-top',
            buttons: []
        };

        /*
            Results: View all
            Results: View unpublished
            Results: Create
            Results: Edit all
            Results: Save as Draft
            Results: Save as Ready For Review
            Results: Save as Approved
            Results: Save as Published
            Results: Delete all
        */

        // Draft
        if (status === 'draft' && Permission.check('Results: Save as Ready For Review')) {
            // First button
            buttonConfig.label = 'Ready for review';
            buttonConfig.action = this.props.handleSave.bind(this, 'ready_for_review');
        }

        // Ready
        if (status === 'ready_for_review') {
            // Can't approve users should see status in button
            if (!Permission.check('Results: Save as Approved')) {
                return (<button type="button" className="item || button primary disabled" disabled>Ready for review</button>);
            }

            // First button
            buttonConfig.label = 'Approve';
            buttonConfig.action = this.props.handleSave.bind(this, 'approved');
        }

        // Approved
        if (status === 'approved') {
            return (<button type="button" className="item || button primary disabled" disabled>Approved</button>);
        }

        // Upload in progress
        if (uploading === true) {
            return (
                <ButtonDropdown {...buttonConfig} class={`${buttonConfig.class} is-disabled`} />
            );
        }

        if (buttonConfig.label) {
            return (<ButtonDropdown {...buttonConfig} />);
        }

        return null;
    }

    renderSecondaryActions() {
        const { uploading } = this.props;
        const query = new URLSearchParams(this.props.location.query);

        let buttonConfig = {
            class: 'item || secondary align-top',
            label: 'Save and close',
            action: this.props.handleSave.bind(this),
            buttons: [],
        };


        // New
        if (this.props.location && query.get('new')) {
            return (<ButtonDropdown {...buttonConfig} />);
        }

        // Upload in progress
        if (uploading === true) {
            return (
                <ButtonDropdown label="Upload in progress" class={`${buttonConfig.class} is-loading`} />
            );
        }

        // Other
        buttonConfig.buttons.push({
            label: 'Save and create new',
            action: this.props.handleCreateNew.bind(this)
        });

        buttonConfig.buttons.push({
            label: 'Save and create syndicated',
            action: this.props.handleCreateSyndicated.bind(this)
        });

        return (<ButtonDropdown {...buttonConfig} />);
    }

    render() {
        if (!this.props.status) return null;

        return (
            <div className="page-actions" data-hook={FORM_CONTROLS_HOOK}>
                <div className="inner || constrain-width">
                    <div className="button-group">
                        {/* this.renderCancelButton() */}
                        {this.renderPrimaryActions()}
                        {this.renderSecondaryActions()}
                        {this.renderDeleteButton()}
                    </div>
                </div>
            </div>
        );
    }
}

ResultFormControls.propTypes = {
    handleContinue:             PropTypes.func,
    handleCreateNew:            PropTypes.func,
    handleCreateSyndicated:     PropTypes.func,
    handleDelete:               PropTypes.func,
    handleSave:                 PropTypes.func,
    location:                   PropTypes.object,
    status:                     PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    uploading:                  PropTypes.bool
};

ResultFormControls.defaultProps = {
    handleContinue:             function() { console.warn('handleContinue'); }, // eslint-disable-line
    handleCreateNew:            function() { console.warn('handleCreateNew'); }, // eslint-disable-line
    handleCreateSyndicated:     function() { console.warn('handleCreateSyndicated');}, // eslint-disable-line
    handleDelete:               function() { console.warn('handleDelete'); }, // eslint-disable-line
    handleSave:                 function() { console.warn('handleSave'); }, // eslint-disable-line
    status: false
};

export default withRouter(ResultFormControls);
