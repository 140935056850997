import * as format from '../../utility/format';
import Alt from '../../flux';
import classnames from 'classnames';
import DialogActions from '../../action/dialog-actions';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import jump from 'jump.js';
import NotificationActions from '../../action/notification-actions';
import Permission from '../../service/permission-service';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { request } from '../../utility/request';
import ResultActions from '../../action/result-actions';
import ResultForm from '../../component/result-form';
import ResultFormControls from '../../component/result-form-controls';
import ResultStore from '../../store/result-store';
import scrollObservable from '../../utility/listener/window-scroll';
import { ABOUT_THE_STORY, COVERAGE_DETAILS, COVERAGE_DETAILS_PUBLISHED, STORY_DETAIL, STORY_DETAIL_PUBLISHED } from '../../config/result';
import { formHasErrors, processResultFromApi, processResultToApi, responseHasErrors, shouldValidate } from '../../utility/result';
import { getAllFields, getFieldIdsFromArray, reduceFieldsByResultType } from '../../utility/fields';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const ALL_FORMS = [
    'about-the-story',
    'story-detail',
    'coverage-details'
];

const SECTION_NAMES = {
    'about-the-story': 'About the story',
    'story-detail': 'Story detail',
    'coverage-details': 'Coverage details'
};

class Result extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            headerSticky: false,
            loading: true,
            open: [],
            order: ALL_FORMS,
            status: ResultStore.getState().fields.status,
            isSyndicated: false,
            uploading: ResultStore.getState().uploading,
            heading: ResultStore.getState().fields.story_title,
            focus: ResultStore.getState().focus,
            showing: new Set(ALL_FORMS),
            valid: new Set(),
            validated: new Set(),
            validFields: ResultStore.getState().validation,
            referrer: location.search
        };

        this.windowScroll = null;
    }


    // Lifecycle
    componentDidMount() {
        this.loadResult();

        this.windowScroll = scrollObservable.subscribe(this.updateHeaderElements.bind(this));

        ResultStore.listen(this.onStoreChange.bind(this));

        // Check if user has already scrolled when component mounts
        this.updateHeaderElements();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            loading: true,
            id: nextProps.match.params.id
        }, () => {
            this.loadResult();
        });
    }


    componentWillUnmount() {
        this.windowScroll.unsubscribe();

        ResultStore.unlisten(this.onStoreChange.bind(this));
    }


    // Accessor
    isOpen(id) {
        let { open } = this.state;

        if (open.length > 0 && open.indexOf(id) !== -1) { // eslint-disable-line no-magic-numbers
            return true;
        }

        return false;
    }

    isValid(id) {
        return this.state.valid.has(id);
    }

    isValidated(id) {
        return this.state.validated.has(id);
    }


    // Actions
    closeResult() {
        const pageNumber = this.getPageReferrer();

        this.props.history.push(`/dashboard/page/${pageNumber}`);
        NotificationActions.push({ message: 'The result was successfully saved.', delay: 1000 });
    }

    deleteResult() {
        this.setState({ loading: true });

        request(`${ENDPOINTS.RESULTS}/${this.state.id}`, {}, 'DELETE')
            .then((response) => {
                if (response.ok) {
                    this.props.history.push('/dashboard/page/1');
                    NotificationActions.push({ message: 'The result was successfully deleted.', delay: 1000 });

                    // Reset local store
                    Alt.recycle(ResultStore);

                    return true;
                }

                NotificationActions.push({ message: 'Failed to delete result. Please refresh and try again.', type: 'error' });

                return false;
            });
    }

    loadResult() {
        const query = new URLSearchParams(this.props.location.query);

        // Refresh the store
        Alt.recycle(ResultStore);

        // Load in any existing data for the result
        request(`${ENDPOINTS.RESULTS}/${this.state.id}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                let data = response.data;

                // Only render if people can edit
                if (data.meta.permissions.can_edit) {
                    processResultFromApi(data);

                    // Update status in state
                    this.setState({
                        status: data.status,
                        isSyndicated: data.is_syndicated
                    });

                    // add form validation indicators if not new
                    if (!query.get('new')) {
                        this.markValidators();
                    }

                    this.focusOnForm(ALL_FORMS[0]);
                } else {
                  this.props.history.push('/dashboard');
                }
            });

        // Add initial 'loading' state to screen
        setTimeout(() => {
            this.setState({ loading: false });
        }, FAUX_LOADING_TIME);
    }

    markValidators(bOpenFormErrors = false) {
        let { valid, validated } = this.state,
            formsWithErrors = [],
            invalidFields,
            validationObject;

        // Validate all forms don't pass currentForm
        validationObject = formHasErrors();

        invalidFields = validationObject.invalidFields;

        // Search current forms to check if they are invalid
        ALL_FORMS.forEach((form) => {
            let formFields = this.getFieldsForForm(form, this.getResultType()),
                formIsValid = true;

            // Use invalidFields to mark which are invalid
            invalidFields.forEach((field) => {
                if (formFields.indexOf(field) !== -1) { // eslint-disable-line no-magic-numbers
                    formIsValid = false;
                    return;
                }
            });

            validated.add(form);

            // Mark forms as valid/invalid
            if (formIsValid) {
                valid.add(form);
            } else {
                valid.delete(form);
                formsWithErrors.push(form);
            }
        });

        this.setState({
            valid: valid,
            validated: validated
        });

        if (bOpenFormErrors) {
            this.setState({
                open: formsWithErrors,
            });
        }
    }

    saveResult(newStatus = false, continueAction = false) {
        let isValid = true,
            { open: currentForm, status, valid, validated } = this.state,
            validationObject;

        // We need to pass a status
        if (newStatus) {
            status = newStatus;
        }


        // Validate all forms don't pass currentForm
        validationObject = formHasErrors();

        // Validate if newStatus or current status isn't draft
        if (shouldValidate(status)) {
            isValid = validationObject.isValid;
        }
        else {
            // Even draft should at least have story_title.
            if (validationObject.invalidFields.includes('story_title')) {
                isValid = null;
            }
        }

        if (!isValid) this.openFormsWithErrors();

        if (isValid) {
            this.updateResult(currentForm, status, continueAction);

            // Add current form to valid and validated state
            validated.clear();

            // Remove 'submitting' status
            ResultActions.submit(false);
        }

        this.setState({
            valid: valid,
            validated: validated
        });
    }

    syndicateResult() {
        this.setState({ loading: true });

        request(ENDPOINTS.RESULTS_SYNDICATED, { id: this.state.id }, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                let id = response.data.id

                // Redirect to syndicated
                this.props.history.push(`/result/${id}?open=coverage-details`);
                NotificationActions.push({
                    message: 'You are now editing the new syndicated result.',
                    delay: 1000
                });
            });
    }

    onStoreChange() {
        this.setState({
            focus: ResultStore.getState().focus,
            heading: ResultStore.getState().fields.story_title,
            uploading: ResultStore.getState().uploading,
            validFields: ResultStore.getState().validation
        });
    }


    // Handlers
    handleContinue() {
        this.saveResult();
        this.markValidators();
    }

    handleCreateSyndicated() {
        this.saveResult(false, 'syndicate');
    }

    handleCreateNew() {
        this.saveResult(false, 'new');
    }

    handleDelete() {
        DialogActions.confirm('You are about to delete this result.', this.deleteResult.bind(this));
    }

    handleEdit(id) {
        let { valid, validated } = this.state;

        this.markValidators();

        this.setState({
            open: [`${id}`],
            valid: valid,
            validated: validated
        });
    }

    handleFocusOnField() {
        const validationObject = formHasErrors();

        const isValid = validationObject.isValid;

        if (!isValid) {
            this.markValidators(true);
            ResultActions.submit();
        }
    }

    handleProgressIndicatorClick(id, event) {
        if (event) event.preventDefault();

        // Scroll to section
        jump(`#${id}`, this.getScrollConfig());

        this.focusOnForm(id);
    }

    handleProgressInvalidClick(formId, fieldId, event) {
        if (event) event.preventDefault();

        // Scroll to section
        jump(`[for="${fieldId}"]`, this.getScrollConfig());

        this.refs[formId].focusOnField(fieldId);
    }

    handleSave(newStatus = false) {
        this.saveResult(newStatus, 'close');
    }

    handleSubmit(event) {
        event.preventDefault();

        this.saveResult();
    }


    // Getters
    getFieldsForForm(form, resultType) {
        let fields;

        switch (form) {
            case 'story-detail':
                fields = getFieldIdsFromArray(reduceFieldsByResultType(STORY_DETAIL.fields, resultType));
                break;
            case 'about-the-story':
                fields = getFieldIdsFromArray(reduceFieldsByResultType(ABOUT_THE_STORY.fields, resultType));
                break;
            case 'coverage-details':
                fields = getFieldIdsFromArray(reduceFieldsByResultType(COVERAGE_DETAILS.fields, resultType));
                break;
            default:
                fields = getAllFields(resultType);
                break;
        }

        return fields;
    }

    openFormsWithErrors() {
        // Update validators for each form
        this.markValidators(true);
        ResultActions.submit();

        NotificationActions.push({ type: 'error', message: 'There are errors in the form you tried to submit.' });
    }

    getResultType() {
        return ResultStore.getState().fields.result_type;
    }

    getPageReferrer() {
        const { referrer } = this.state;
        const DEFAULT_PAGE = 1;

        const page = queryString.parse(referrer);

        return page.referrer || DEFAULT_PAGE;
    }

    getTitle() {
        let title = '';

        switch (this.getResultType()) { // eslint-disable-line default-case
            case 'content':
                title = 'Content';
                break;
            case 'imp':
                title = 'IMP';
                break;
        }

        title = `${title} result`;

        return title;
    }


    // Helper
    getFormConfig(formId) {
        switch (formId) {
            case 'about-the-story':
                return ABOUT_THE_STORY;
            case 'story-detail':
                return STORY_DETAIL;
            case 'coverage-details':
                return COVERAGE_DETAILS;
            default:
                return null;
        }
    }

    getPositionFromTop(element) {
        let currentElement = element,
            yPosition = 0;

        while (currentElement) {
            yPosition += (currentElement.offsetTop - currentElement.scrollTop + currentElement.clientTop);
            currentElement = currentElement.offsetParent;
        }

        return yPosition;
    }

    getScrollConfig() {
        const extraOffset = 10;
        const headerOffset = (this.refs['sticky-header'].clientHeight + extraOffset) * -1; // eslint-disable-line no-magic-numbers

        let duration = 450;

        // Prevent double jumping on concurrent events
        if (this.isJumping) {
            duration = 0; // eslint-disable-line no-magic-numbers
        }

        this.isJumping = true;

        return {
            duration,
            offset: headerOffset,
            callback: () => {
                this.isJumping = false;
            }
        };
    }

    stripHtml(html) {
        const tag = document.createElement('div');
        tag.innerHTML = html;

        return tag.innerText;
    }

    showField(field) {
        const resultType = this.getResultType();
        const isSyndicated = this.state.isSyndicated;

        if (field.displayOnImp === false && resultType === 'imp') {
            return false;
        }

        if (field.displayOnContent === false && resultType === 'content') {
            return false;
        }

        if (field.displayOnPnP === false && resultType === 'partners_and_projects') {
            return false;
        }

        if (field.onlyOnSyndicated === true && isSyndicated === false) {
            return false;
        }

        return true;
    }

    focusOnForm(formId) {
        // TODO: Fix this / find react way of doing becuase this is terrible
        document.querySelector(`#${formId} input:not([type="hidden"])`).focus();
    }

    updateOpenSections() {
        let { open, order, showing } = this.state,
            nextIndex,
            nextOpen = '',
            nextShowing = showing;
        const query = new URLSearchParams(this.props.location.query);

        // If creating new result
        if (query.get('new')) {
            let onlyOpen = open[0];

            // If in 'edit', move to the next stage
            if (order.indexOf(onlyOpen) !== -1 && order.indexOf(onlyOpen) < order.length - 1) { // eslint-disable-line no-magic-numbers
                nextIndex = order.indexOf(onlyOpen) + 1; // eslint-disable-line no-magic-numbers
                nextOpen = order[nextIndex];

                // Add 'next' state to 'showing'
                nextShowing.add(nextOpen);
            }

            // If 'is new', move to the dashboard from the last page
            if (order.indexOf(onlyOpen) === order.length - 1) { // eslint-disable-line no-magic-numbers
                this.props.history.push('/dashboard');
                NotificationActions.push({ message: 'Result successfully created.', delay: 1000 });
            }
        }

        this.setState({
            open: [nextOpen],
            showing: nextShowing
        });
    }

    updateHeaderElements() {
        const headerElement = this.refs['sticky-header'];
        const headerFromTop = headerElement.offsetTop;
        const windowTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

        this.setState({
            headerSticky: (headerFromTop < windowTop)
        });
    }

    handleResultTitleChange() {
        const titleHtml = this.refs['editable-title'];

        if (titleHtml && titleHtml.value !== this.state.heading) {
            const titleText = this.stripHtml(titleHtml.value);
            ResultActions.update('story_title', titleText);
        }
    }

    updateResult(currentForm, newStatus = false, continueAction = false, override = false) {
        let data,
            endpoint = `${ENDPOINTS.RESULTS}/${this.state.id}`,
            { status } = this.state;

        this.setState({ loading: true });

        if (status === 'published' && Permission.check('Results: Edit all published results' === false)) {
            // Only send rtos and the EAV back to the server if already published
            data = {
                rtos: processResultToApi(this.getFieldsForForm(currentForm)).rtos,
                eav: processResultToApi(this.getFieldsForForm(currentForm)).eav
            };
        } else {
            // Create data object
            data = processResultToApi(this.getFieldsForForm(currentForm));
            data.status = status;

            // Add newStatus to data if set
            if (newStatus !== status) {
                data.status = newStatus;
            }
        }

        // Override will force save
        if (override) {
            endpoint += '?force=true';
        }

        // Update result
        return request(endpoint, data, 'PATCH')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    // Check for the override
                    if (response.hasOwnProperty('request_confirmation')) {
                        this.setState({ loading: false });

                        // Resubmit request on confirm and pass ovveride param
                        DialogActions.confirm(response.request_confirmation.message, this.updateResult.bind(this, currentForm, newStatus, continueAction, true));
                        return false;
                    }

                    // Handle errors in response
                    if (!responseHasErrors(response)) {
                        // Update save status to force render of next buttons
                        if (newStatus !== status) {
                            this.setState({
                                open: [], // Close empty forms
                                status: newStatus
                            }, () => {
                                const pageNumber = this.getPageReferrer();

                                this.props.history.push(`/dashboard/page/${pageNumber}`);
                                NotificationActions.push({
                                    message: `The result status was updated to ${format.resultStatus(newStatus).label.toLowerCase()}.`,
                                    delay: 1000
                                });
                            });
                            return;
                        }

                        // Close
                        if (continueAction === 'close') {
                            this.closeResult();
                            return;
                        }

                        // Redirect to new
                        if (continueAction === 'new') {
                            this.props.history.push('/result/new');
                            NotificationActions.push({
                                message: 'Result successfully saved.',
                                delay: 1000
                            });
                            return;
                        }

                        // Create syndicated
                        if (continueAction === 'syndicate') {
                            this.syndicateResult();
                            return;
                        }

                        // Remove loading state and update open sections
                        this.setState({ loading: false }, this.updateOpenSections.bind(this));
                        return;
                    }

                    // Show error notification
                    this.setState({ loading: false }, () => {
                        NotificationActions.push({ type: 'error', delay: 500, message: 'There are errors in the form you tried to submit.' });
                    });

                    return false;
                }, FAUX_LOADING_TIME);
            });
    }


    // Renders
    renderForms() {
        if (this.state.status === 'published' && Permission.check('Results: Edit all published results') === false) {
            return [
                <ResultForm {...STORY_DETAIL_PUBLISHED}
                    key="STORY_DETAIL_PUBLISHED"
                    fieldType="Result"
                    isOpen={true}
                    isShowing={true}
                    isValid={this.isValid('story-detail')}
                    onEdit={this.handleEdit.bind(this, 'story-detail')}
                    onFieldFocus={this.handleFocusOnField.bind(this)}
                    showField={this.showField.bind(this)}
                    validated={this.isValidated('story-detail')} />,
                <ResultForm {...COVERAGE_DETAILS_PUBLISHED}
                    key="COVERAGE_DETAILS_PUBLISHED"
                    fieldType="Result"
                    isOpen={true}
                    isShowing={true}
                    isValid={this.isValid('coverage-details')}
                    onEdit={this.handleEdit.bind(this, 'coverage-details')}
                    onFieldFocus={this.handleFocusOnField.bind(this)}
                    showField={this.showField.bind(this)}
                    validated={this.isValidated('coverage-details')} />
            ];
        }

        return [
            <ResultForm {...ABOUT_THE_STORY}
                formId="about-the-story"
                key="2"
                ref="about-the-story"
                fieldType="Result"
                isOpen={true}
                isShowing={true}
                isValid={this.isValid('about-the-story')}
                onEdit={this.handleEdit.bind(this, 'about-the-story')}
                onFieldFocus={this.handleFocusOnField.bind(this)}
                showField={this.showField.bind(this)}
                validated={this.isValidated('about-the-story')} />,
            <ResultForm {...STORY_DETAIL}
                formId="story-detail"
                key="3"
                ref="story-detail"
                fieldType="Result"
                isOpen={true}
                isShowing={true}
                isValid={this.isValid('story-detail')}
                onEdit={this.handleEdit.bind(this, 'story-detail')}
                onFieldFocus={this.handleFocusOnField.bind(this)}
                showField={this.showField.bind(this)}
                validated={this.isValidated('story-detail')} />,
            <ResultForm {...COVERAGE_DETAILS}
                formId="coverage-details"
                key="4"
                ref="coverage-details"
                fieldType="Result"
                isOpen={true}
                isShowing={true}
                isValid={this.isValid('coverage-details')}
                onEdit={this.handleEdit.bind(this, 'coverage-details')}
                onFieldFocus={this.handleFocusOnField.bind(this)}
                showField={this.showField.bind(this)}
                validated={this.isValidated('coverage-details')} />
        ];
    }

    renderProgressIndicator() {
        // TODO: Possibly move to it's own component
        const { focus, headerSticky, validFields } = this.state;
        const progressIndicatorClass = classnames('main-sidebar || progress-indicator', {
            'is-sticky': headerSticky
        });

        return (
            <aside className={progressIndicatorClass}>
                <ul className="list">
                    {ALL_FORMS.map((resultId, key) => {
                        const itemClass = classnames('item', {
                            'is-valid': this.isValid(resultId),
                            'is-invalid': (this.isValidated(resultId) && !this.isValid(resultId)),
                            'is-focussed': (focus === resultId)
                        });

                        const formFields = this.getFormConfig(resultId).fields;

                        return (
                            <li className={itemClass} key={key}>
                                <a className="link" href={`#${resultId}`} onClick={(event) => this.handleProgressIndicatorClick(resultId, event)}>
                                    {SECTION_NAMES[resultId]}
                                    {this.renderProgressIcon(resultId)}
                                </a>

                                {(this.isValidated(resultId) && !this.isValid(resultId)) &&
                                    <div className="info">
                                        <ul className="fields">
                                            {formFields.map((field, index) => {
                                                const { id: fieldId, label: fieldLabel } = field;
                                                const validField = validFields[fieldId];

                                                if (validField === false && this.showField(field)) {
                                                    return (
                                                        <li className="field" key={`field-${index}`} onClick={(event) => this.handleProgressInvalidClick(resultId, fieldId, event)}>
                                                            {fieldLabel}
                                                        </li>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </ul>
                                    </div>
                                }
                            </li>
                        );
                    })}
                </ul>
            </aside>
        );
    }

    renderProgressIcon(resultId) {
        if ((this.isValidated(resultId) && !this.isValid(resultId))) {
            // Is not valid
            return (
                <div className="icon">
                    <svg viewBox="0 0 40 40" width="40" height="40" className="svg" aria-hidden="true" focusable="false">
                        <title>Caution Badge</title>
                        <use xlinkHref="/assets/icons/_sprite.svg#caution-badge"></use>
                    </svg>
                </div>
            );
        }

        return (<div className="icon"></div>);
    }

    render() {
        const { heading, headerSticky, loading, status, uploading } = this.state;
        const { location } = this.props;
        const query = new URLSearchParams(this.props.location.query);

        const mainClass = classnames('main is-gapless', {
            'is-loading': loading
        });

        const mainHeadingClass = classnames('main-heading', {
            'is-sticky': headerSticky
        });

        let title = 'Create Result';

        if (!query.get('new')) {
            title = 'Editing Result';
        }

        return (
            <DocumentTitle title={title}>
                <main role="main" className={mainClass}>
                    <div className="main-inner">

                        <div className={mainHeadingClass} ref="sticky-header">
                            <div className="main-heading-wrapper">
                                <div className="main-title || constrain-width medium">
                                    <h3>
                                        <input
                                            className="editable-title"
                                            ref="editable-title"
                                            onChange={this.handleResultTitleChange.bind(this)}
                                            placeholder="Add a story title"
                                            type="text"
                                            value={heading} />
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="edit-wrapper || constrain-width">
                            {this.renderProgressIndicator()}
                            <form ref="form" action="/" className="editing-form" onSubmit={this.handleSubmit.bind(this)}>
                                {this.renderForms()}
                                <ResultFormControls
                                    ref={(formControls) => { this.formControls = formControls; }}
                                    handleContinue={this.handleContinue.bind(this)}
                                    handleCreateNew={this.handleCreateNew.bind(this)}
                                    handleCreateSyndicated={this.handleCreateSyndicated.bind(this)}
                                    handleDelete={this.handleDelete.bind(this)}
                                    handleSave={this.handleSave.bind(this)}
                                    location={location}
                                    status={status}
                                    uploading={uploading} />
                            </form>
                        </div>
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

Result.propTypes = ({
    location: PropTypes.object,
    match: PropTypes.object
});

export default withRouter(Result);
