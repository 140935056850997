import { APP_NAME } from '../config/app';
import PropTypes from 'prop-types';
import ReactDocumentTitle from 'react-document-title';
import React, { Component } from 'react';

class DocumentTitle extends Component {
    render() {
        let { title } = this.props;

        if (title) {
            title += ` | ${APP_NAME}`;
        } else {
            title = `${APP_NAME}`;
        }

        return (
            <ReactDocumentTitle title={title}>
                {this.props.children}
            </ReactDocumentTitle>
        );
    }
}

DocumentTitle.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string
};

export default DocumentTitle;
