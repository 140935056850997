import Alt from '../../flux';
import classnames from 'classnames';
import DialogActions from '../../action/dialog-actions';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import Form from '../../component/form';
import FormActions from '../../action/form-actions';
import FormControls from '../../component/form-controls';
import FormStore from '../../store/form-store';
import NotificationActions from '../../action/notification-actions';
import Permissions from '../../service/permission-service';
import PropTypes from 'prop-types';
import { request } from '../../utility/request';
import UserStore from '../../store/user-store';
import { withRouter } from 'react-router-dom';
import { formHasErrors, processDataFromApi, processDataToApi, responseHasErrors } from '../../utility/form';
import React, { Component } from 'react';
import {USER, USER_CURRENT, USER_TNZ, USER_WITH_ORGANISATION} from '../../config/user';

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: null,
            loading: true,
            userRoles: null
        };
    }


    // Mounting
    UNSAFE_componentWillMount() {
        this.updateFormFields();

        // Refresh the store
        Alt.recycle(FormStore);
    }

    componentDidMount() {
        // Get Airline from API
        request(`${ENDPOINTS.USER}/${this.props.match.params.id}`, false, 'GET')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // Show the add admin user form when not verified
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        userRoles: response.data.roles
                    }, () => {
                        this.updateFormFields(() => {
                            const { fields } = this.state;

                            // Pass all fields to the formActions to setup store
                            FormActions.setup(fields);
                            // Call FormActions on each field e.g processResultFromApi
                            processDataFromApi(fields, response.data);
                        });
                    });
                }, FAUX_LOADING_TIME);
            });
    }


    // Handlers
    handleDelete() {
        DialogActions.confirm('You are about to deactivate this user.', this.delete.bind(this));
    }

    handleSubmit(event) {
        event.preventDefault();

        // Add 'submitting' status
        FormActions.submit();

        // Validate
        if (!formHasErrors(this.state.fields)) {
            this.submit();
        }
    }


    // Helpers
    delete() {
        // Make API request to delete remote resource
        request(`${ENDPOINTS.USER}/${this.props.match.params.id}`, {}, 'DELETE')
            .then((response) => {
                if (response.ok) {
                    this.props.history.goBack();
                    NotificationActions.push({ message: 'The user was successfully deactivated.', delay: 1000 });
                    // Reset local store
                    Alt.recycle(FormStore);

                    return true;
                }

                return false;
            });
    }

    getFormConfig() {
        const canManageOrganisations = Permissions.check('Users: Manage user organisations');
        const { userRoles } = this.state;

        if (userRoles) {
            let canCreateResults = true;
            let isTnzAdmin = false;

            // Go through each user's role, then each
            // roles permissions to find out if
            // they can create results
            userRoles.forEach((role) => {
                let permissions = role.permissions
                permissions.forEach((permission) => {
                    if (permission.name === 'Results: Create') {
                        canCreateResults = false;
                    }
                    if (permission.name === 'Users: Delete all') {
                        isTnzAdmin = true;
                    }
                });
            });

            if (canManageOrganisations && canCreateResults) {
                return USER_WITH_ORGANISATION;
            }
            if (isTnzAdmin) {
                return USER_TNZ;
            }
        }

        // If a user is editing themselves
        if (this.props.match.params.id === UserStore.getState().user.id) {
            return USER_CURRENT;
        }

        return USER;
    }

    submit() {
        let data;

        // Set loading state
        this.setState({ loading: true });

        // Process the current form data
        data = processDataToApi(this.state.fields);
        data.organisations = data.organisations?.map((item) => {
            return item.value;
        });

        // Submit request
        request(`${ENDPOINTS.USER}/${this.props.match.params.id}`, data, 'PATCH')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    this.setState({ loading: false });
                    if (!responseHasErrors(response)) {
                        this.props.history.goBack();
                    }
                }, FAUX_LOADING_TIME);
            });
    }

    updateFormFields(callback) {
        const userFields = [...this.getFormConfig().fields];

        this.setState({
            fields: userFields
        }, callback);
    }


    // Renders
    render() {
        const form = this.getFormConfig();

        let buttonTypes = ['save', 'cancel', 'delete'],
            mainClass,
            title = 'Edit User';

        mainClass = classnames({
            'main': true,
            'is-loading': this.state.loading
        });

        // Update button types, form and title
        if (this.props.match.params.id === UserStore.getState().user.id) {
            // Only allow delete if not editing yourself
            buttonTypes = ['save', 'cancel'];
            title += ' (You)';
        }

        return (
            <DocumentTitle title={title}>
                <main role="main" className={mainClass}>
                    <div className="main-inner">
                        <form ref="form" action="/" className="editing-form || constrain-width" onSubmit={this.handleSubmit.bind(this)}>
                            <Form {...form} title={title}/>
                            <FormControls handleDelete={this.handleDelete.bind(this)} types={buttonTypes}/>
                        </form>
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

EditUser.propTypes = {
    match: PropTypes.object
};

export default withRouter(EditUser);
