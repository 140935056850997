import { ENDPOINTS } from '../config/api';
import FilterActions from '../action/filter-actions';
import FilterStore from '../store/filter-store';
import { formatFilters } from '../utility/fields';
import PropTypes from 'prop-types';
import { request } from '../utility/request';
import WindowedSelect from 'react-windowed-select';
import React, { useEffect, useState } from 'react';
import { getUsers } from '../utility/tanstack-query-request';

const DEFAULT_SELECT_OPTION = {
    id: '',
    sub_organisation : { // eslint-disable-line camelcase
        name : 'All'
    }
};

const LIMIT = 9999;

const ORGANISATION = '0';
const USER = '1';

const USER_SEARCH_BY_OPTIONS = [
    {
        id: ORGANISATION,
        name: 'Organisation',
        description: 'Search Organisation'
    },
    {
        id: USER,
        name: 'User',
        description: 'Search User'
    }
];

// This component mirrors './filters.jsx' so
// features from there could be ported here
// in the future

function UserFilters(props) {
    const [userFiltersState, setUserFiltersState] = useState({
        isLoadingUsers: false,
        isLoadingOrganisations: false,
        data: {
            organisations: [DEFAULT_SELECT_OPTION]
        },
        fields: FilterStore.getState().filters
    });

    const { usersQueryData, error, isLoading } = getUsers();

    useEffect(() => {
        userFiltersState.fields.searchBy = ORGANISATION;
        // Operators
        populateSelectOrganisationOptions();
    }, []);

    useEffect(() => {
        populateSelectUsersOptions({usersQueryData, error, isLoading});
    }, [usersQueryData, error, isLoading]);

    const prepareFilters = () => {
        const filters = formatFilters(userFiltersState.fields);

        const fieldsObject = {
            market : '',
            monthFrom : '',
            monthTo : '',
            resultType : '',
            rto : '',
            region : '',
            search : '',
            searchBy : filters.searchBy,
            user : '',
            yearFrom : '',
            yearTo : '',
            premium : '',
            impact : '',
            sortOrder : 'desc',
            userStatus : '',
            resultStatus: ''
        };

        setUserFiltersState({
            ...userFiltersState,
            fields: fieldsObject
        });

        return filters;
    };

    // Event
    const onSubmit = () => {
        const filters = prepareFilters();

        // Trigger props callback
        props.onSubmit(filters);
    };

    // Handler
    const handleSelectChange = (type, value) => {
        const filters = prepareFilters();

        const fieldsObject = {
            market : '',
            monthFrom : '',
            monthTo : '',
            resultType : '',
            rto : '',
            region : '',
            search : '',
            searchBy : filters.searchBy,
            user : '',
            yearFrom : '',
            yearTo : '',
            premium : '',
            impact : '',
            sortOrder : 'desc',
            userStatus : '',
            resultStatus: ''
        };

        fieldsObject[type] = value;

        setUserFiltersState({
            ...userFiltersState,
            fields: fieldsObject
        });

        FilterActions.save(fieldsObject);
    };

    const handleChange = (event) => {
        let field = event.target.getAttribute('name');
        let { fields } = userFiltersState;
        let value = event.target.value;

        fields[field] = value;

        setUserFiltersState({
            ...userFiltersState,
            fields
        });

        // Save the fields to filters local storage
        FilterActions.save(fields);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        onSubmit.bind(this);
    };

    const handleSearchByChange = (event) => {

        const { fields } = userFiltersState;

        switch (event.target.value) {
            case USER:
                fields.user = '';
                break;
            case ORGANISATION:
                fields.search = '';
                break;
            default:
                break;
        }

        setUserFiltersState({
            ...userFiltersState,
            fields
        });

        FilterActions.save(fields);
        handleChange(event);
    };

    // Helpers
    const selectedOption = (type) => {
        return FilterStore.getState().filters[type];
    };

    const getOptionsFromApi = (url, label) => {
        request(url)
            .then((response) => response.json())
            .then((response) => {
                const { data } = response;

                if (data) {
                    this.formatDataFromApi(data, label);
                }
            });
    };

    const formatDataFromApi = (apiData, type) => {
        let { data } = userFiltersState;
        options = apiData;

        // Add default option to front
        options.unshift(DEFAULT_SELECT_OPTION);

        // Assign market data
        data[type] = options;

        setUserFiltersState({
            ...userFiltersState,
            data
        });
    };

    const populateSelectOrganisationOptions = () => {
        setUserFiltersState({
            ...userFiltersState,
            isLoadingOrganisations: true
        });

        return request(`${ENDPOINTS.LIST_ORGANISATIONS}?limit=${LIMIT}&is_verified=true`)
            .then((response) => response.json())
            .then((response) => {
                let { data } = userFiltersState;

                data.organisations = response.data.map(({ id, sub_organisation: subOrganisation }) => {
                    const { name } = subOrganisation;

                    return {
                        value: id,
                        label: name
                    };
                });

                setUserFiltersState({
                    ...userFiltersState,
                    data,
                    isLoadingOrganisations: false
                });
            });
    };

    const populateSelectUsersOptions = ({usersQueryData, error, isLoading}) => {

        if (error) {
            setUserFiltersState({
                ...userFiltersState,
                isLoadingUsers: false
            });
            return;
        }

        if (isLoading) {
            setUserFiltersState({
                ...userFiltersState,
                isLoadingUsers: true
            });
            return;
        }

        if (usersQueryData) {
            let { data } = userFiltersState;

            data.users = usersQueryData?.data?.map(({ id, first_name: firstName, last_name: lastName }) => {
                const name = `${firstName} ${lastName}`;

                return {
                    value: id,
                    label: name
                };
            });

            setUserFiltersState({
                ...userFiltersState,
                data,
                isLoadingUsers: false
            });
        }
    };

    const renderSearchByOptions = () => {
        return USER_SEARCH_BY_OPTIONS.map((item, index) => {
            return (
                <option value={item.id} key={index}>{item.name}</option>
            );
        });
    };

    const displayUserSearchPlaceholder = () => {
        if (error) {
            return 'Error loading users';
        }

        if (isLoading) {
            return 'Loading...';
        }

        return USER_SEARCH_BY_OPTIONS[USER].description;
    };

    return (
        <form className="filter-bar" onSubmit={handleSubmit.bind(this)}>
            <div className="field-group || type || hide-to-desktop">
                <div className="select-by-wrapper">
                    <select
                        className="input || select"
                        name="searchBy"
                        onChange={handleSearchByChange.bind(this)}
                        value={selectedOption('searchBy')}
                    >
                        {renderSearchByOptions()}
                    </select>
                </div>
                {userFiltersState?.fields?.searchBy === ORGANISATION ?
                    <WindowedSelect
                        className="Select input || select"
                        classNamePrefix="Select"
                        name="organisation"
                        value={selectedOption('search')}
                        onChange={handleSelectChange.bind(this, 'search')}
                        options={userFiltersState?.data?.organisations}
                        isLoading={userFiltersState?.isLoadingOrganisations}
                        placeholder={USER_SEARCH_BY_OPTIONS[ORGANISATION].description}
                        isClearable={true}
                    />
                    :
                    <WindowedSelect
                        className="Select input || select"
                        classNamePrefix="Select"
                        name="user"
                        value={selectedOption('user')}
                        onChange={handleSelectChange.bind(this, 'user')}
                        options={userFiltersState?.data?.users}
                        placeholder={displayUserSearchPlaceholder()}
                        isClearable={true}
                        isLoading={isLoading}
                    />
                }
            </div>
            <div className="field-group">
                <button className="button primary || submit" type="submit" onClick={onSubmit.bind(this)}>
                    <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                        <use xlinkHref="/assets/icons/_sprite.svg#search"></use>
                    </svg>
                    <span className="button-label">Search</span>
                </button>
            </div>
        </form>
    );
}

UserFilters.propTypes = {
    onSubmit: PropTypes.func
};

UserFilters.defaultProps = {
    onSubmit: () => null
};

export default UserFilters;
