import UserStore from '../store/user-store';

class PermissionService {
    check(rawPermissions) {
        let hasPermission = false,
            permissions = rawPermissions,
            userPermissions = UserStore.getState().permissions;

        // Check if any permissions
        if (!Array.isArray(userPermissions)) {
            return hasPermission;
        }

        // Convert string to array
        if (!Array.isArray(permissions)) {
            permissions = [rawPermissions];
        }

        permissions.forEach((permission) => {
            if (userPermissions.includes(permission)) {
                hasPermission = true;
            }
        });

        return hasPermission;
    }
}

export default new PermissionService();
