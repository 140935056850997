import Alt from '../flux';

class FormActions {
    setup(fields) {
        return fields;
    }

    submit(status = true) {
        return status;
    }

    update(field, value) {
        return {
            id: field,
            value: value
        };
    }

    error(field, error) {
        return {
            id: field,
            error: error
        };
    }
}

export default Alt.createActions(FormActions);
