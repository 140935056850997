import * as format from '../utility/format';
import { DATE_FORMAT } from '../config/app';
import { ENDPOINTS } from '../config/api';
import moment from 'moment';
import UserStore from '../store/user-store';

const DEFAULTS = {
    text: '-',
    object: {
        name: '-',
        id: '-'
    },
    meta: {
        permissions: {
            can_edit: false, // eslint-disable-line camelcase
            can_delete: false, // eslint-disable-line camelcase
        }
    },
    organisation: {
        name: '-',
        type: '-'
    }
};


/**
 * Global format date
 * @param  {string} date Should be a date in the input date format
 * @return {string}
 */
export function date(date) {
    return moment(date, DATE_FORMAT.DEFAULT.input).format(DATE_FORMAT.DEFAULT.output);
}


/**
 * Lookup media icon using media type
 * @param  {string} mediaType Defines which icon to display
 * @return Either a string or false
 */
export function mediaIcon(mediaType) {
    switch (mediaType) {
        case 'Broadcast':
            return 'tv';
        case 'Print':
            return 'book';
        case 'Radio':
            return 'radio';
        case 'Social':
            return 'message';
        case 'Online':
            return 'computer';
        default:
            return false;
    }
}


/**
 * Format large numbers into currency e.g 123456789 = 123,456,789
 * @param  {int} number Number to format
 * @return {string}
 */
export function number(number) {
    return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}


/**
 * Lookup the reach label using media type
 *
 * @param  {type} string Media type captialised
 * @return {string} label
 */
export function reachLabel(type) {
    switch (type) {
        case 'Broadcast':
            return 'Broadcast viewership';
        case 'Print':
            return 'Print Circulation';
        case 'Radio':
            return 'Radio Listeners';
        case 'Social':
            return 'Social';
        case 'Online':
            return 'Unique online visitors';
        default:
            return 'Reach';
    }
}


/**
 * Lookup result type data using result type
 * @param  {string} resultType
 * @return {object} resultTypeObject Object with the associated values
 */
export function resultType(resultType) {
    let resultTypeObject = {};

    switch (resultType) {
        case 'imp':
            resultTypeObject.icon = 'imp';
            resultTypeObject.label = 'IMP result';
            break;

        case 'content':
            resultTypeObject.icon = 'content';
            resultTypeObject.label = 'Content result';
            break;

        case 'partners_and_projects':
            resultTypeObject.icon = 'partners_n_projects';
            resultTypeObject.label = 'Partnerships and Projects result';
            break;

        default:
            resultTypeObject.icon = 'content';
            resultTypeObject.label = 'Content result';
            break;
    }

    return resultTypeObject;
}


/**
 * Convert string into url slug
 * @param  {string} string That is going to be converted
 * @return {string}
 */
export function slug(string) {
    return string.replace(/\s|_+/g, '-').toLowerCase();
}


/**
 * Lookup result status data using status
 * @param  {string} status Lowercase
 * @return {object} statusObject Object with associated values
 */
export function resultStatus(resultStatus) {
    let statusObject = {};

    switch (resultStatus) {
        case 'approved':
            statusObject.label = 'Approved';
            statusObject.class = 'approved';
            break;
        case 'draft':
            statusObject.label = 'Draft';
            statusObject.class = 'draft';
            break;
        case 'published':
            statusObject.label = 'Published';
            statusObject.class = 'published';
            break;
        case 'ready_for_review':
            statusObject.label = 'Ready';
            statusObject.class = 'ready';
            break;
        default:
            statusObject.label = resultStatus;
            statusObject.class = '';
            break;
    }

    return statusObject;
}


/**
 * Lookup user status data using status
 * @param  {string} status Lowercase
 * @return {object} statusObject Object with associated values
 */
export function userStatus(userStatus) {
    let statusObject = {};

    switch (userStatus) {
        case 'disabled':
            statusObject.label = 'Deactivated';
            statusObject.class = 'disabled';
            break;
        case 'enabled':
            statusObject.label = 'Enabled';
            statusObject.class = 'enabled';
            break;
        case 'invited':
            statusObject.label = 'Invited';
            statusObject.class = 'invited';
            break;
        case 'pending':
            statusObject.label = 'Pending';
            statusObject.class = 'pending';
            break;
        default:
            statusObject.label = userStatus;
            statusObject.class = '';
            break;
    }

    return statusObject;
}


/**
 * Reformat row data to display
 *
 * @param  {arrary} row Array with object fields to reformat
 *
 * @return {array}
 */
export function resultRow(row) {
    let validSections = [],
        { country, eav, id, media_name: mediaName, media_type: mediaType, meta, reach, result_type: resultType, status, story_title: storyTitle, live_date: liveDate } = row;

    for (let key in meta.validation) {
        validSections.push(Boolean(Array.isArray(meta.validation[key])));
    }

    // Check data and reset defaults
    if (!country) country = DEFAULTS.object;
    if (!eav) eav = DEFAULTS.text;
    if (!id) id = DEFAULTS.text;
    if (!meta) meta = DEFAULTS.meta;
    if (!mediaName) mediaName = DEFAULTS.text;
    if (!mediaType) mediaType = DEFAULTS.object;
    if (!reach) reach = DEFAULTS.text;
    if (!storyTitle) storyTitle = DEFAULTS.text;

    // TODO: This should us the canSeeStatus flag instead
    if (!meta.permissions.can_view_status) status = false;

    return {
        permissions: {
            canEdit: meta.permissions.can_edit,
            canDelete: meta.permissions.can_delete
        },
        cells: [
            {
                cell: 'headline',
                data: {
                    icon: format.resultType(resultType).icon,
                    label: format.resultType(resultType).label,
                    value: storyTitle
                }
            },
            {
                cell: 'publication',
                data: {
                    value: mediaName
                }
            },
            {
                cell: 'market',
                data: {
                    value: country.name,
                    id: country.id
                }
            },
            {
                cell: 'media',
                data: {
                    value: mediaType.name,
                    id: mediaType.id
                }
            },
            {
                cell: 'reach',
                data: {
                    value: reach
                }
            },
            {
                cell: 'eav',
                data: {
                    value: eav
                }
            },
            {
                cell: 'live_date',
                data: {
                    value: liveDate
                }
            },
            {
                cell: 'status',
                data: {
                    id: id,
                    link: `/view/${id}`,
                    status: status,
                    validation: validSections
                }
            }
        ]
    };
}


/**
 * Format pending operator row data
 * @param  {object} row Row data
 * @return {object}
 */
export function pendingOperatorRow(row) {
    let deleteAction,
        { name, id, meta } = row;

    // Default delete action
    deleteAction = {
        confirm: `You are about to delete ${name}`,
        link: `${ENDPOINTS.LIST_OPERATORS}/${id}`,
        success: `You have successfully deleted ${name}`
    };

    return {
        permissions: {
            canDelete: meta.permissions.can_delete
        },
        cells: [
            {
                cell: 'headline',
                data: {
                    value: name
                }
            },
            {
                cell: 'edit-operator',
                data: {
                    deleteAction: deleteAction,
                    id: id,
                    link: `/operators/${id}`
                }
            }
        ]
    };
}


/**
 * Format organisation row data
 * @param  {object} row Row data
 * @return {object}
 */
export function organisationRow(row) {
    let deleteAction,
        link,
        { meta, sub_organisation: subOrganisation, type, type_display_name: typeDisplayName } = row,
        region = '';

    if (!meta) meta = DEFAULTS.meta;

    if (subOrganisation === null) {
        region = '';
    } else {
        // List single region
        if (subOrganisation.region) {
            region = subOrganisation.region.name;
        }
        // Loop through if regions exists
        if (subOrganisation.regions) {
            // Compile a list
            subOrganisation.regions.forEach((regionObject, index) => {
                if (index === 0) { // eslint-disable-line
                    region = regionObject.name;
                } else {
                    region += `, ${regionObject.name}`;
                }
            });
        }
    }

    // Default delete action
    deleteAction = {
        confirm: `You are about to delete ${subOrganisation.name}`,
        link: false,
        success: `You have successfully deleted ${subOrganisation.name}`
    };

    link = slug(`/${type === 'operator' ? 'operators' : type}/${subOrganisation.id}`);

    switch (type) {
        case 'airline':
            deleteAction.link = `${ENDPOINTS.AIRLINE}/${subOrganisation.id}`;
            break;
        case 'event_organiser':
            deleteAction.link = `${ENDPOINTS.EVENT_ORGANISER}/${subOrganisation.id}`;
            break;
        case 'offshore_agency':
            deleteAction.link = `${ENDPOINTS.OFFSHORE_AGENCY}/${subOrganisation.id}`;
            break;
        case 'operator':
            deleteAction.link = `${ENDPOINTS.OPERATOR}/${subOrganisation.id}`;
            break;
        case 'rto':
            deleteAction.link = `${ENDPOINTS.RTO}/${subOrganisation.id}`;
            break;
        default:
            // Other organisations can't be edited or deleted at the moment
            deleteAction = false;
            link = false;
            break;
    }

    return {
        permissions: {
            canEdit: meta.permissions.can_edit,
            canDelete: meta.permissions.can_delete
        },
        cells: [
            {
                cell: 'headline',
                data: {
                    value: subOrganisation.name
                }
            },
            {
                cell: 'default',
                data: {
                    value: typeDisplayName
                }
            },
            {
                cell: 'default',
                data: {
                    value: region
                }
            },
            {
                cell: 'edit-organisation',
                data: {
                    deleteAction: deleteAction,
                    link: link
                }
            }
        ]
    };
}


/**
 * Return '?' or '&' depending on if '?' already exists
 * @param  {string} String to be checked
 * @return {string}
 */
export function queryPrefix(endpoint) {
    if (endpoint.indexOf('?') !== -1) { // eslint-disable-line no-magic-numbers
        return '&';
    }

    return '?';
}


/**
 * Format user row data
 * @param  {object} row Row data
 * @return {object}
 */
export function userRow(row) {
    let activateAction,
        deleteAction,
        inviteAction,
        inviteDateString,
        { email, id, full_name: fullName, meta, current_organisation: organisation, status, isInUse, isInvitationSent } = row,
        organisationData = DEFAULTS.organisation;

    if (!meta) meta = DEFAULTS.meta;

    // Only if has organisation property (Admin won't)
    if (organisation === null) {
        organisationData.name = 'Tourism NZ';
        organisationData.type = '-';
    } else {
        organisationData.name = organisation.sub_organisation.name;
        organisationData.type = organisation.type_display_name;
    }

    if (organisation === null || organisation.meta.invites === null || organisation.meta.invites.sent_at === null) {
        inviteDateString = 'has previously been sent an invite';
    } else {
        inviteDateString = `was last sent an email invite on ${organisation.meta.invites.sent_at}`;
    }

    // Default delete action
    deleteAction = {
        confirm: `You are about to delete ${fullName}`,
        link: `${ENDPOINTS.LIST_USERS}/${id}`,
        success: `You have successfully deleted ${fullName}`
    };

    inviteAction = {
        confirm: `${fullName} (${organisationData.name}) ${inviteDateString}. Are you sure you'd like to resend the invite so they can complete registration?`,
        link: `${ENDPOINTS.USER_INVITE}/${id}`,
        success: `Invite successfully sent to ${fullName}.`
    };

    activateAction = {
        confirm: `${fullName} (${organisationData.name}). Are you sure you'd like to reactivate this user?`,
        link: `${ENDPOINTS.USER}/${id}`,
        success: `Successfully reactivated ${fullName}.`
    };

    // Don't allow the user to delet themselves
    if (id === UserStore.getState().user.id) {
        meta.permissions.can_delete = false; // eslint-disable-line camelcase

        fullName += ' (You)'; // eslint-disable-line
    }

    return {
        permissions: {
            canEdit: meta.permissions.can_edit,
            canDelete: meta.permissions.can_delete
        },
        cells: [
            {
                cell: 'headline',
                data: {
                    value: fullName
                }
            },
            {
                cell: 'default',
                data: {
                    value: email
                }
            },
            {
                cell: 'default',
                data: {
                    value: organisationData.name
                }
            },
            {
                cell: 'default',
                data: {
                    value: organisationData.type
                }
            },
            {
                cell: 'edit-user',
                data: {
                    activateAction: activateAction,
                    deleteAction: deleteAction,
                    inviteAction: inviteAction,
                    isInUse: isInUse,
                    isInvitationSent: isInvitationSent,
                    link: `/users/${id}`,
                    status: status
                }
            }
        ]
    };
}
