export const APP_NAME = 'My Tourism New Zealand';

export const CUSTOM_DIALOGS = {
    bulkEdit: 'bulk-edit',
    exportResults: 'export-results'
};

export const DATE_FORMAT = {
    DEFAULT: {
        format: 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
        input: 'YYYY-MM-DD',
        output: 'DD MMMM YYYY'
    },
    DISPLAY_DATE: {
        formatLongYear: 'DD/MM/YYYY',
        format: 'DD/MM/YY',
        seperator: '/'
    },
    RETURN_DATE: {
        format: 'YYYY-MM-DD'
    }
};

export const FAUX_LOADING_TIME = 400;

export const FORM_CONTROLS_HOOK = 'form-controls';

export const GA_UA_CODE = 'UA-9284980-4';

export const KEYWORD = '0';

export const ORGANISATION = '1';

export const USER = '2';

export const ZERO = 0;

export const SEARCH_BY_OPTIONS = [
    {
        id: KEYWORD,
        name: 'Keyword',
        description: 'Headline, author, media, event, film'
    },
    {
        id: ORGANISATION,
        name: 'Organisation',
        description: 'Organisation'
    },
    {
        id: USER,
        name: 'User',
        description: 'User'
    }
];
