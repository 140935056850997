import * as format from '../utility/format';
import { DATE_FORMAT } from '../config/app';
import DialogActions from '../action/dialog-actions';
import moment from 'moment';
import NotificationActions from '../action/notification-actions';
import PropTypes from 'prop-types';
import { request } from '../utility/request';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class Cell extends Component {
    constructor(props) {
        super(props);
    }


    // Handlers
    handleDelete(deleteAction) {
        DialogActions.confirm(deleteAction.confirm, this.deleteRequest.bind(this, deleteAction));
    }

    handleInvite(inviteAction) {
        DialogActions.confirm(inviteAction.confirm, this.inviteRequest.bind(this, inviteAction));
    }

    handleActivate(activateAction) {
        DialogActions.confirm(activateAction.confirm, this.activateRequest.bind(this, activateAction));
    }


    // Helpers
    deleteRequest(deleteAction) {
        request(deleteAction.link, false, 'DELETE')
            .then((response) => {
                return response.json();
            })
            .then(() => {
                this.props.history.push(location.pathname); // Smart reload using react router
                NotificationActions.push({ message: deleteAction.success, delay: 400 });
            });
    }

    inviteRequest(inviteAction) {
        request(inviteAction.link, false, 'POST')
            .then((response) => {
                return response.json();
            })
            .then(() => {
                this.props.history.push(location.pathname); // Smart reload using react router
                NotificationActions.push({ message: inviteAction.success, delay: 400 });
            });
    }

    activateRequest(activateAction) {
        const payload = {
            reactivate: true // eslint-disable-line camelcase
        };

        request(activateAction.link, payload, 'PATCH')
            .then((response) => {
                return response.json();
            })
            .then(() => {
                this.props.history.push(location.pathname); // Smart reload using react router
                NotificationActions.push({ message: activateAction.success, delay: 400 });
            });
    }

    cellClass(classString = '') {
        return `cell || cell-${this.props.cell} || ${classString}`;
    }


    // Renders
    renderDefault() {
        let value = '';

        if (this.props.data.value !== null) {
            value = this.props.data.value;
        }

        return (
            <div className={this.cellClass()}>
                <p>{value}</p>
            </div>
        );
    }

    renderEav() {
        return (
            <div className={this.cellClass()}>
                <p><span>$</span>{format.number(this.props.data.value)}</p>
            </div>
        );
    }

    renderEditOperator() {
        const { data, permissions } = this.props;
        const { deleteAction, link } = data;
        const { canDelete } = permissions;

        let deleteButon;

        if (deleteAction && canDelete) {
            deleteButon = (<button className="item delete" data-prevent="true" tabIndex="-1" onClick={this.handleDelete.bind(this, deleteAction)}>Delete</button>);
        }

        return (
            <div className={this.cellClass('actions || hide-on-mobile')}>
                <div className="button-group">
                    <Link className="item" data-prevent="true" data-link={link} tabIndex="-1" to={link}>Edit</Link>
                    {deleteButon}
                </div>
            </div>
        );
    }

    renderEditUser() {
        let { deleteAction, inviteAction, activateAction, link, status, isInUse, isInvitationSent } = this.props.data,
            // canDelete,
            canEdit,
            canInvite,
            statusObject;

        statusObject = format.userStatus(status);

        // Only users with permissions can see the edit button
        if (link && this.props.permissions.canEdit) {
            canEdit = (<Link className="item" data-prevent="true" data-link={link} tabIndex="-1" to={link}>Edit</Link>);
        }

        if (link && this.props.permissions.canEdit && isInvitationSent === false && isInUse === false) {
            canInvite = (<button className="item edit" data-prevent="true" tabIndex="-1" onClick={this.handleInvite.bind(this, inviteAction)}>Send Invite</button>);

        } else if (link && this.props.permissions.canEdit && (status === 'pending' || isInUse === false)) {
            canInvite = (<button className="item edit" data-prevent="true" tabIndex="-1" onClick={this.handleInvite.bind(this, inviteAction)}>Resend Invite</button>);
        }

        if (link && this.props.permissions.canEdit && status === 'disabled') {
            canInvite = (<button className="item edit" data-prevent="true" tabIndex="-1" onClick={this.handleActivate.bind(this, activateAction)}>Activate</button>);
        }

        if (deleteAction && this.props.permissions.canDelete) {
            // TODO TNZSLA-70: Temp fix is to disable delete button , Wouter/Bettina to confirm best course of action in deleting users
            // canDelete = (<button className="item delete" data-prevent="true" tabIndex="-1" onClick={ this.handleDelete.bind(this, deleteAction) }>Delete</button>);
        }

        return (
            <div className={this.cellClass('actions || hide-on-mobile')}>
                <p className={`status ${statusObject.class}`}>{statusObject.label}</p>
                <div className="button-group">
                    {canEdit}
                    {canInvite}
                </div>
            </div>
        );
    }

    renderHeadline() {
        const { icon, label, value } = this.props.data;

        let iconComponent;

        if (icon) {
            iconComponent = this.renderSvg(icon, label, value, 'left');
        }

        return (
            <div className={this.cellClass()} title={value}>
                <div className="cell-headline-inner">
                    {/* To make the text ellipsis work */}
                    {iconComponent &&
                        <p className="title">{iconComponent}</p>
                    }
                    <p className="title">{value}</p>
                </div>
            </div>
        );
    }

    renderHeaderStatus() {
        return (
            <div className={this.cellClass('actions || hide-on-mobile')}>
                <p>{this.props.data.value}</p>
            </div>
        );
    }

    renderMedia() {
        const { value } = this.props.data;
        const icon = format.mediaIcon(value);

        return (
            <div className={this.cellClass()}>{this.renderSvg(icon, value)}</div>
        );
    }

    renderStatus() {
        let { id, link, status, validation } = this.props.data,
            canEdit,
            extraClass = 'actions || hide-on-mobile',
            statusElement = false,
            statusObject;

        // Onlu users with permissions can see the edit button
        if (this.props.permissions.canEdit) {
            canEdit = (<Link className="item edit" data-prevent="true" tabIndex="-1" to={`/result/${id}?referrer=${this.props.pageNumber}`}>Edit</Link>);
        }

        // Only show status if passed
        if (status) {
            statusObject = format.resultStatus(status);
            statusElement = (
                <div className={`status ${statusObject.class}`}>
                    {validation && validation.map((item, index) => {
                        const stageClass = (item === true) ? 'is-complete' : 'is-incomplete';

                        return (<div className={`stage ${stageClass}`} key={index}></div>);
                    })}
                    {statusObject.label}
                </div>
            );
        }

        if (!status) extraClass += ' single-button';

        return (
            <div className={this.cellClass(extraClass)}>
                {statusElement}
                <div className="button-group">
                    <Link className="item" data-prevent="true" data-link={link} tabIndex="-1" to={link}>View</Link>
                    {canEdit}
                </div>
            </div>
        );
    }

    renderLiveDate() {
        return (
            <div className={this.cellClass()}>
                {
                    this.props.data.value ?
                        <p>{moment(this.props.data.value).format(DATE_FORMAT.DISPLAY_DATE.formatLongYear)}</p>
                        :
                        <p>-</p>
                }
            </div>
        );
    }

    renderReach() {
        return (
            <div className={this.cellClass()}>
                <p>{format.number(this.props.data.value)}</p>
            </div>
        );
    }

    renderEditOrganisation() {
        const { data, permissions } = this.props;
        const { link, deleteAction } = data;
        let canDelete,
            canEdit;

        // Only users with permissions can see the edit button
        if (link && permissions.canEdit) {
            canEdit = (
                <Link className="item" data-link={link} tabIndex="-1" to={`${link}?referrer=${this.props.pageNumber}`}>Edit</Link>
            );
        }

        if (deleteAction && permissions.canDelete) {
            canDelete = (<button className="item delete" data-prevent="true" tabIndex="-1" onClick={ this.handleDelete.bind(this, deleteAction) }>Delete</button>);
        }

        return (
            <div className={this.cellClass('actions || hide-on-mobile')}>
                <div className="button-group">
                    {canEdit}
                    {canDelete}
                </div>
            </div>
        );
    }

    render() {
        let cell = null;

        // Check which cell to render
        switch (this.props.cell) {
            case 'headline':
                cell = this.renderHeadline();
                break;
            case 'media':
                cell = this.renderMedia();
                break;
            case 'eav':
                cell = this.renderEav();
                break;
            case 'live_date':
                cell = this.renderLiveDate();
                break;
            case 'edit-operator':
                cell = this.renderEditOperator();
                break;
            case 'edit-organisation':
                cell = this.renderEditOrganisation();
                break;
            case 'edit-user':
                cell = this.renderEditUser();
                break;
            case 'reach':
                cell = this.renderReach();
                break;
            case 'status':
                cell = this.renderStatus();
                break;
            case 'header-status':
                cell = this.renderHeaderStatus();
                break;
            default:
                cell = this.renderDefault();
                break;
        }

        if (typeof cell === 'undefined') {
            return null;
        }

        return cell;
    }

    renderSvg(icon, label, key, classNames = '') {
        /**
         * Generates a 40x40 icon
         *
         * To address SVG caching bugs on IE:
         * 1. Set <use /> element as a separate React component
         * 2. Set the "key" property manually on <svg /> elements
         *
         * @see http://stackoverflow.com/questions/42773892/wrong-components-rendered-by-preact/42775667
         */

        const use = (<use xlinkHref={`/assets/icons/_sprite.svg?version=#${icon}`}></use>);

        return (<svg key={key} viewBox="0 0 40 40" width="40" height="40" className={`icon ${classNames}`} aria-hidden="true" focusable="false"><title>{label}</title>{use}</svg>);
    }
}

Cell.propTypes = {
    cell: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    pageNumber: PropTypes.number,
    permissions: PropTypes.object,
};

export default withRouter(Cell);
