import { useEffect } from 'react';
import { ZERO } from '../config/app';

const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(ZERO, ZERO);
    }, []);
};

export default useScrollToTop;
