import * as format from '../utility/format';
import Cell from '../component/cell';
import classnames from 'classnames';
import closest from '../utility/closest';
import FilterActions from '../action/filter-actions';
import FilterStore from '../store/filter-store';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            body: this.props.body,
            loading: this.props.loading,
            statusDropDownIsActive: false,
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            header: nextProps.header,
            loading: nextProps.loading
        });
    }


    // Handler
    handleRowClick(event, id, permissions) {
        let link = `/view/${id}`,
            linkObject,
            row = closest(event.target, '.row');

        // If can't edit or can't delete, dont let user see
        if (!(permissions.can_edit || permissions.can_delete)) {
            return;
        }

        linkObject = row.querySelector('[data-link]');

        // Bubble up to find a data-link attribute
        if (linkObject) {
            link = linkObject.getAttribute('data-link');
        }

        // Avoid clicks on link elements within the row e.g see the cell rendering
        if (!event.target.hasAttribute('data-prevent')) {
            this.props.history.push(link);
        }
    }

    handleSortHeaderClick() {
        const fields = FilterStore.getState().filters;

        fields.sortOrder = fields.sortOrder === 'desc' ? 'asc' : 'desc';

        FilterActions.save(fields);
        this.props.onSubmit(fields);
    }

    handleStatusHeaderClick(value) {
        this.props.onStatusHeaderClick(value);
    }


    // Render
    renderHeader(name, row) {
        return row.map((item, index) => {
            let key = `${name}-cell-${index}`;

            if (item.cell === 'sort') {
                const fields = FilterStore.getState().filters;

                const sortOrderClass = classnames('sort', {
                    'desc': fields.sortOrder === 'desc',
                    'asc': fields.sortOrder === 'asc',
                });

                return (
                    <div className={sortOrderClass} key={key} onClick={(event) => this.handleSortHeaderClick(event)}>
                        <Cell key={key} {...item}/>
                        <svg viewBox="0 0 40 40" width="15" height="15" className="icon">
                            <use xlinkHref="/assets/icons/_sprite.svg#chevron"></use>
                        </svg>
                    </div>
                );
            }

            if (item.cell === 'select') {
                const dropDownClass = classnames('dropdown || cell', {
                    'is-active': this.state.statusDropDownIsActive,
                });

                // eslint-disable-next-line no-magic-numbers
                if (item.data.values.length > 0) {
                    return (
                        <div className={dropDownClass} key={key}>
                            <div className="trigger">
                                <p>{item.data.title}</p>
                                <svg viewBox="0 0 40 40" width="15" height="15" className="svg || chevron">
                                    <use xlinkHref="/assets/icons/_sprite.svg#chevron"></use>
                                </svg>
                            </div>
                            <div className="children">
                                <div className="wrapper">
                                    {
                                        item.data.values.map((item, key) => {
                                            return <div className="item" key={key} onClick={() => this.handleStatusHeaderClick(item.value)}>{item.title}</div>;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    );
                }

                return null;
            }

            return (<Cell key={key} {...item}/>);
        });
    }

    renderRow(row, rowKey) {
        let newRow;

        // Format used to display the specfic row
        switch (this.props.format) {
            case 'organisations':
                newRow = format.organisationRow(row);
                break;
            case 'pending-operators':
                newRow = format.pendingOperatorRow(row);
                break;
            case 'users':
                newRow = format.userRow(row);
                break;
            default:
                newRow = format.resultRow(row);
        }

        return newRow.cells.map((item, index) => {
            let key = `${rowKey}-cell-${index}`,
                permissions = newRow.permissions;

            return (
                <Cell
                    key={key}
                    permissions={permissions}
                    pageNumber={this.props.number}
                    {...item} />
            );
        });
    }

    renderRows(name, rows) {
        return rows.map((item, index) => {
            let key = `${name}-row-${index}`;

            // Limit types which allow click action on row
            return (<div className="row || is-clickable" tabIndex="0" onClick={(event) => this.handleRowClick(event, item.id, item.meta.permissions)} key={key}>{this.renderRow(item, key)}</div>);
        });
    }

    render() {
        let tableClass = classnames('table', {
            'is-loading': this.state.loading
        });

        return (
            <div className={tableClass}>
                <div className="table-inner || constrain-width large">
                    <header className="table-header || hide-on-mobile">{this.renderHeader('header', this.props.header)}</header>
                    <div className="table-body">{this.renderRows('body', this.props.body)}</div>
                </div>
            </div>
        );
    }
}

Table.propTypes = {
    body: PropTypes.array.isRequired,
    format: PropTypes.string,
    number: PropTypes.number,
    header: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onStatusHeaderClick: PropTypes.func,
};

Table.defaultProps = {
    onSubmit: function() {} // eslint-disable-line
};

export default withRouter(Table);
