import { ALLOW_NONE_SUFFIX } from '../config/result';
import Field from './field';
import PropTypes from 'prop-types';
import ResultActions from '../action/result-actions';
import ResultStore from '../store/result-store';
import React, { Component } from 'react';

class ResultField extends Component {
    constructor(props) {
        super(props);

        this.onStoreChange = this.onStoreChange.bind(this);

        this.state = {
            isDisabled: this.getAllowNoneValue(),
            error: '',
            value: ResultStore.getState().fields[this.props.id],
            valueAllowNone: this.getAllowNoneValue()
        };
    }

    // Mounting
    componentDidMount() {
        ResultStore.listen(this.onStoreChange);
    }

    componentWillUnmount() {
        ResultStore.unlisten(this.onStoreChange);
    }


    // Handler
    handleChange(field, value) {
        ResultActions.update(field, value);
        ResultActions.submit();
    }

    handleFocus(field) {
        ResultActions.focus(field);

        this.props.onInputFocus(this.field);
    }

    handleAllowNoneChange(field, value) {
        // Disable input and clear input
        this.setState({
            isDisabled: value,
            valueAllowNone: value
        });

        // Add allow none flag
        ResultActions.update(field + ALLOW_NONE_SUFFIX, value);
    }

    handleUploading(value) {
        ResultActions.upload(value);
    }


    // Getters
    getAllowNoneValue() {
        // Use ALLOW_NONE_SUFFIX to attempt to get a field from the state
        const noneId = this.props.id + ALLOW_NONE_SUFFIX;
        const resultState = ResultStore.getState();
        const value = resultState.fields[noneId];

        return value;
    }


    // Events
    onStoreChange() {
        let error = '',
            fieldValidation = ResultStore.getState().validation[this.props.id];

        // Check validation if submitting
        if (ResultStore.isSubmitting() && fieldValidation === false) {
            error = 'This field is required';
        }

        // Has an error returned from the API
        if (typeof fieldValidation === 'string') {
            error = fieldValidation;
        }

        this.setState({ error: error });

        // Check if value has been updated
        if (this.state.value !== ResultStore.getState().fields[this.props.id]) {
            this.setState({
                value: ResultStore.getState().fields[this.props.id],
                isDisabled: this.getAllowNoneValue(),
                valueAllowNone: this.getAllowNoneValue()
            });
        }
    }


    // Helper
    focus() {
        this.field.focus();
    }


    // Render
    render() {
        // Don't render the hidden fields
        if (this.props.type === 'hidden') {
            return null;
        }

        return (
            <Field
                ref={(field) => { this.field = field; }}
                isDisabled={this.state.isDisabled}
                error={this.state.error}
                onUploadChange={this.handleUploading.bind(this)}
                onChange={this.handleChange}
                onAllowNoneChange={this.handleAllowNoneChange.bind(this)}
                onFocus={this.handleFocus.bind(this)}
                value={this.state.value}
                valueAllowNone={this.state.valueAllowNone}
                {...this.props}
            />);
    }
}

ResultField.propTypes = {
    id: PropTypes.string.isRequired,
    onInputFocus: PropTypes.func,
    type: PropTypes.string.isRequired
};

ResultField.defaultProps = {
    onInputFocus: () => null
};

export default ResultField;
