import moment from 'moment';
import { ABOUT_THE_STORY, COVERAGE_DETAILS, RESULT_TYPE, STORY_DETAIL } from '../config/result';

const FORMAT_MONTH = 'MMM';
const FORMAT_MONTH_EXPORT = 'MM';

/**
 * Return map of field ids for a given array
 *
 * @param  {array} fields Array of field objects
 *
 * @return {array}
 */
export function getFieldIdsFromArray(fields) {
    return fields.map((field) => field.id);
}

/**
 * Return array of all result form fields to display
 *
 * @param  {string} resultType Result type to filter fields by (i.e. 'imp', 'content')
 *
 * @return {array}
 */
export function getAllFields(resultType) {
    let fieldIds = [];

    fieldIds = fieldIds.concat(getFieldIdsFromArray(reduceFieldsByResultType(RESULT_TYPE.fields, resultType)));
    fieldIds = fieldIds.concat(getFieldIdsFromArray(reduceFieldsByResultType(STORY_DETAIL.fields, resultType)));
    fieldIds = fieldIds.concat(getFieldIdsFromArray(reduceFieldsByResultType(ABOUT_THE_STORY.fields, resultType)));
    fieldIds = fieldIds.concat(getFieldIdsFromArray(reduceFieldsByResultType(COVERAGE_DETAILS.fields, resultType)));

    return fieldIds;
}

/**
 * Return filtered array of fields to show for a given array
 *
 * @param  {array} fields     Array of fields to filter
 * @param  {string} resultType Result type to filter by
 *
 * @return {array}
 */
export function reduceFieldsByResultType(fields, resultType) {
    return fields.filter((field) => {
        if (field.displayOnImp === false && resultType === 'imp') {
            return;
        }

        if (field.displayOnContent === false && resultType === 'content') {
            return;
        }

        if (field.displayOnPnP === false && resultType === 'partners_and_projects') {
            return;
        }

        return true;
    });
}

/**
 * Function to format the filters for export and endpoint purposes
 *
 * @param objFilters object containing filter values
 *
 * @returns mixed | {object} if valid dates | {false} if dates invalid
 */
export function formatFilters(objFilters) {
    if (objFilters === undefined) {
        return;
    }

    let filters = {},
        {
            market,
            monthFrom,
            monthTo,
            resultType,
            rto,
            search,
            yearFrom,
            yearTo,
            searchBy,
            organisation,
            region,
            user,
            premium,
            impact,
            sortOrder,
            userStatus,
            resultStatus,
        } = objFilters;

    // Setup blank states to be passed
    filters.dateFrom = '';
    filters.dateTo = '';

    // Assign filters with values
    filters.market = market;
    filters.rto = rto;
    filters.search = search;
    filters.resultType = resultType;
    filters.searchBy = searchBy;
    filters.organisation = organisation;
    filters.region = region;
    filters.user = user;
    filters.premium = premium;
    filters.impact = impact;
    filters.sortOrder = sortOrder;
    filters.userStatus = userStatus;
    filters.resultStatus = resultStatus;

    // Check one date field supplied
    if (monthFrom !== '' || monthTo !== '' || yearFrom !== '' || yearTo !== '') {
        // Check all date fields are supplied
        if (monthFrom === '' || monthTo === '' || yearFrom === '' || yearTo === '') {
            filters = false;
        } else {
            monthFrom = moment(monthFrom, FORMAT_MONTH).format(FORMAT_MONTH_EXPORT);
            monthTo = moment(monthTo, FORMAT_MONTH).format(FORMAT_MONTH_EXPORT);

            // Add Filters
            filters.dateFrom = `${yearFrom}-${monthFrom}`;
            filters.dateTo = `${yearTo}-${monthTo}`;
        }
    }

    return filters;
}
