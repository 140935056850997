import Alt from '../flux';

class NotificationActions {
    push(notification) {
        // Shorthand for success messages just pass a string
        if (typeof notification === 'string') {
            return {
                type: 'success',
                message: notification,
            };
        }

        // Expect and return an object otherwise
        return notification;
    }
}

export default Alt.createActions(NotificationActions);
