import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const PAGE_BUTTON_LIMIT = 5;

class Pagination extends Component {
    constructor(props) {
        super(props);

        let { number, limit } = this.props;

        this.state = {
            currentPage: number,
            lastPage: false,
            limit: limit,
            offset: this.getOffset(number),
            total: false
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { current_page, last_page, total } = nextProps.data; // eslint-disable-line camelcase

        this.setState({
            currentPage: current_page, // eslint-disable-line camelcase
            lastPage: last_page, // eslint-disable-line camelcase
            offset: this.getOffset(current_page), // eslint-disable-line camelcase
            total: total
        });
    }


    // Helpers
    getOffset(number) {
        let lastChecked = 0, // eslint-disable-line no-magic-numbers
            newNumber = number,
            returnValue = false;

        if (isNaN(newNumber)) {
            newNumber = 0; // eslint-disable-line no-magic-numbers
        }

        // Loop through to find the right number
        while (returnValue === false) {
            let stepAhead = lastChecked + PAGE_BUTTON_LIMIT;

            if (newNumber <= stepAhead) {
                returnValue = lastChecked;
            } else {
                // Re assign step ahead
                lastChecked = stepAhead;
            }
        }

        return returnValue;
    }

    increasePageNumbers() {
        this.setState({ offset: this.state.offset + PAGE_BUTTON_LIMIT });
    }

    decreasePageNumbers() {
        let newOffset = this.state.offset - PAGE_BUTTON_LIMIT;

        if (newOffset < 0) { // eslint-disable-line no-magic-numbers
            newOffset = 0; // eslint-disable-line no-magic-numbers
        }

        this.setState({ offset: newOffset });
    }


    // Renders
    renderInformation() {
        let { limit, total } = this.state,
            text;

        // pagination total determines no results message
        if (total === 0) { // eslint-disable-line no-magic-numbers
            return (
                <div className="no-results">
                    <p>No results found.</p>
                </div>
            );
        }

        if (limit >= total) {
            limit = total; // Make sure we don't display the actual fetch limit of 999999
        }

        text = `Showing ${limit} of ${total} results`;

        // Default return text
        return (
            <div className="information">
                <p className="text">{text}</p>
            </div>
        );
    }

    renderPaginationButtons() {
        let { limit, total } = this.state;

        if (!total || limit >= total) {
            return null;
        }

        return (
            <div role="group" className="button-group">
                {this.renderPagePreviousButton()}
                {this.renderPageNumberButtons()}
                {this.renderPageNextButton()}
            </div>
        );
    }

    renderPageNumberButtons() {
        let buttons = [],
            { currentPage, offset, lastPage, total, limit } = this.state,
            lastNumber = offset + PAGE_BUTTON_LIMIT;

        let realMaxPages = Math.ceil(total / limit);
        if (realMaxPages < lastNumber) {
            lastNumber = realMaxPages;
        }
        if (lastPage === false) {
            return null;
        }

        // Set number of pages
        while (offset < lastNumber) {
            offset++; // Increment by 1

            // Button class
            let buttonClass = 'button';

            if (offset === currentPage) {
                buttonClass += ' is-active';
            }

            buttons.push((<Link role="button" className={buttonClass} key={`pagination-${offset}`} to={`/${this.props.view}/page/${offset}`}>{offset}</Link>));

            // Don't render more than possible
            if (offset === lastPage) {
                return buttons;
            }
        }

        return buttons;
    }

    renderPageNextButton() {
        let { offset, limit, total } = this.state,
            currentMax = Math.ceil((total / limit) / PAGE_BUTTON_LIMIT) * PAGE_BUTTON_LIMIT,
            setMax = offset + PAGE_BUTTON_LIMIT;

        if (currentMax === setMax) {
            return null;
        }

        return (
            <button role="button" className="button || next" onClick={() => { this.increasePageNumbers(); }}>
                <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                    <use xlinkHref="/assets/icons/_sprite.svg#arrow-right"></use>
                </svg>
            </button>
        );
    }

    renderPagePreviousButton() {
        if (this.state.offset === 0) { // eslint-disable-line no-magic-numbers
            return null;
        }

        return (
            <button role="button" className="button || previous" onClick={() => { this.decreasePageNumbers(); }}>
                <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                    <use xlinkHref="/assets/icons/_sprite.svg#arrow-left"></use>
                </svg>
            </button>
        );
    }

    render() {
        return (
            <div className="pagination">
                <div className="inner || constrain-width large">
                    {this.renderInformation()}
                    {this.renderPaginationButtons()}
                </div>
            </div>
        );
    }
}

Pagination.propTypes = {
    data: PropTypes.object,
    limit: PropTypes.number,
    number: PropTypes.number,
    view: PropTypes.string.isRequired // Determines urls
};

Pagination.defaultProps = {
    limit: 15,
    number: 1
};

export default Pagination;
