import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { FAUX_LOADING_TIME } from '../../config/app';
import List from '../../component/list';
import { PENDING_OPERATORS } from '../../config/tables';
import { prepareListEndpoint } from '../../utility/list';
import PropTypes from 'prop-types';
import { request } from '../../utility/request';
import React, { Component } from 'react';


class ListPendingOperators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            endpoint: `${ENDPOINTS.LIST_OPERATORS}?is_verified=false`,
            currentPage: null,
            loading: true,
            pagination: {}
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { number } = nextProps.match.params;

        this.populateData(number);
    }

    UNSAFE_componentWillMount() {
        const { number } = this.props.match.params;

        this.populateData(number);
    }


    // Helper
    populateData(number) {
        let endpoint = prepareListEndpoint(this.state.endpoint, this.state.limit, number);

        this.setState({ currentPage: parseInt(number), loading: true });

        // Make request
        request(endpoint, false, 'GET')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.setState({
                    data: response.data,
                    pagination: response.meta.pagination
                }, () => {
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, FAUX_LOADING_TIME);
                });
            });
    }


    // Render
    render() {
        return (
            <DocumentTitle title="Pending Operators">
                <main role="main" className="main || dashboard">
                    <div className="main-inner">

                        <div className="subpage-banner">
                            <div className="banner-actions">
                                <div className="inner || constrain-width large">

                                    <div className="title">
                                        <h1 className="style-h3 medium">Pending operators</h1>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <List
                            data={this.state.data}
                            format="pending-operators"
                            header={PENDING_OPERATORS.tableHeaderData}
                            number={this.state.currentPage}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            view={PENDING_OPERATORS.view} />
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ListPendingOperators.propTypes = {
    match: PropTypes.object
};

export default ListPendingOperators;
