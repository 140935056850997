// TODO: Move this into the /result sub folder
import classnames from 'classnames';
import DocumentTitle from '../utility/document-title';
import { ENDPOINTS } from '../config/api';
import { request } from '../utility/request';
import { RESULT_TYPE } from '../config/result';
import ResultActions from '../action/result-actions';
import ResultStore from '../store/result-store';
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const TRANSITION_DELAY = 400;

class ResultType extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            errors: [],
            resultType: ResultStore.getState().fields.result_type,
            status: ResultStore.getState().fields.status,
            loading: true
        };
    }


    // Mounting
    componentDidMount() {
        ResultStore.listen(this.onChange);

        setTimeout(() => {
            this.setState({ loading: false });
        }, TRANSITION_DELAY);
    }

    componentWillUnmount() {
        ResultStore.unlisten(this.onChange);
    }


    // Events
    onChange() {
        this.setState({
            resultType: ResultStore.getState().fields.result_type
        });
    }


    // Handlers
    handleClick(resultType) {
        event.preventDefault();

        this.setState({
            resultType: resultType
        });

        ResultActions.submit();
        this.createResult(resultType);
    }

    createResult(resultType) {
        let data = {
            result_type: resultType, // eslint-disable-line camelcase
            status: this.state.status,
            visit_start_date: resultType == 'content' || resultType == 'partners_and_projects' ? new Date() : '',
            visit_end_date: resultType == 'content' || resultType == 'partners_and_projects' ? new Date() : '',
        };

        return request(ENDPOINTS.RESULTS, data, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                let id = response.data.id

                this.setState({ loading: true }, () => {
                    this.props.history.push(`/result/${id}?new=true`);
                    return true;
                });
            });
    }

    // Render
    renderHeader() {
        return (
            <header role="header" className="section-intro" id={RESULT_TYPE.id}>
                <div className="inner">
                    <h2 className="title centered || thin">{RESULT_TYPE.title}</h2>
                </div>
            </header>
        );
    }

    renderFields() {
        let buttons = [];
        RESULT_TYPE.fields.map(((field) => {
            field.values.forEach((resultType, key) => {
                buttons.push(
                    <div className="item" key={key}>
                        <button className="result label" onClick={this.handleClick.bind(this, resultType.value)}>
                            <span className="title || style-h3">{resultType.label}</span><span className="description">{resultType.description}</span>
                        </button>
                    </div>
                );
            });
        }));

        if (buttons.length > 0) { // eslint-disable-line no-magic-numbers
            return (
                <div className="section-content">
                    <div className="inner full-width || constrain-width medium">
                        <div className="form">
                            <div role="group" className="fieldset">
                                <div className="field">
                                    <div role="group" className="input || radio-group">
                                        {buttons}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        let mainClass = classnames('main centered', {
            'is-loading': this.state.loading
        });

        return (
            <DocumentTitle title="Create Result">
                <main role="main" className={mainClass}>
                    <div className="main-inner">
                        <section className="page-section constrain-width" id={RESULT_TYPE.id}>
                            {this.renderHeader()}
                            {this.renderFields()}
                        </section>
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

ResultType.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(ResultType);