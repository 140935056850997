import DocumentTitle from '../../utility/document-title';
import Page from '../../component/page';
import React from 'react';
import useScrollToTop from '../../component/scroll-to-top';

function Content() {
    useScrollToTop();
    return (
        <DocumentTitle title="Terms of Use">
            <Page title="Terms of Use">
                <div>
                    <p>Version 1.0</p>
                    <h5>Introduction</h5>
                    <p>This My Tourism New Zealand portal available at my.tourismnewzealand.com (this ‘Site’) is owned and operated by the New Zealand Tourism Board trading as Tourism New Zealand (‘TNZ’, ‘we’, ‘us’, ‘our’). These terms of use constitute an agreement made between you, the Site user, (‘you’, ‘your’) and us. By accessing or using this Site you agree that you have read, understood and accepted these terms of use, and you agree to be bound by them. If you do not accept these terms of use and agree to be bound by them, then you must refrain from accessing and using this Site.</p>
                    <h5>Privacy</h5>
                    <p>Where we collect personal information about you as a result of your accessing or using this Site, our Privacy Policy will apply to that information. Accordingly, these terms of use must be read together with our Privacy Policy.</p>
                    <h5>Your use of this Site</h5>
                    <p>You agree not to use this Site for any purpose that is unlawful or to engage in any conduct that may impair or cause damage to the operation of this Site whether by way of a virus, corrupted file, or through any other means. Furthermore you agree not to copy, alter, modify, reproduce, communicate to the public, or otherwise deal with the content, software, text, graphics, layout or design of this Site except in accordance with these terms of use.</p>
                    <h5>Your account</h5>
                    <p>In order to access certain media coverage, materials, information, features, or services offered on this Site, you must receive an invitation to join and then activate your account. To activate your account, you must (among other things) complete your account registration details in the manner described on this Site (including confirming your email address and selecting a password, which we will use to verify your identity when you use this Site). You must ensure that your account registration details are complete and accurate when submitted to us. You certify that the information that you have given is true and correct. You must keep your account registration details up-to-date. We may at our discretion (at any time and without notice to you) suspend or terminate your account, your ability to use all or part of this Site, or your ability to use any media coverage, materials, information, features, or services available through this Site. You must not sell, exchange, trade, or auction your account.</p>
                    <h5>Your email address and password</h5>
                    <p>Your account registration details (including your email address and password) are personal to you. You must always maintain the confidentiality of your password and not disclose it to any third party. You agree that you are solely responsible for any use of this Site by any person using your email address and password and you agree to indemnify us against any claims arising out of your failure to maintain the confidentiality of your email address or password. You agree to not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity. We will not be liable for any loss that you may incur as a result of someone else using your password or account (whether with or without your knowledge). You agree to notify us immediately of any unauthorised use of your account or any other breach of security.</p>
                    <h5>Intellectual property</h5>
                    <p>The materials displayed and available on this Site are protected by copyright and other laws of New Zealand, and under similar laws and international conventions abroad. Copyright and other intellectual property rights may subsist in the material published or available on this Site, including the media coverage, text, graphics, photographs, still footage, illustrations, artwork, video, moving footage, films, music, audio visual, trademarks, logos, icons, sound recordings, layout, designs, source code, computer programs and software, audio, and visual elements (together, ‘the intellectual property’).</p>
                    <p>Except as expressly permitted under these terms of use, or with prior written permission, you may not in any form or by any means:</p>
                    <ul>
                        <li>use, copy, download, modify, adapt, reproduce, store, distribute, print, display, perform, publish, communicate, transmit, or otherwise deal in any way with the intellectual property; or</li>
                        <li>commercialise any media coverage, materials, information, products or services obtained from any part of this Site.</li>
                    </ul>
                        The software which operates this Site is proprietary software and you are not permitted to use it except as expressly allowed under these terms of use. Any other use or purported licensing, modification, enhancement, or interference is strictly prohibited.
                    <h5>Limited permission to use materials and information</h5>
                    <p>You are only permitted to view the media coverage displayed and available on this Site. In accordance with the above, you are not permitted to otherwise use, copy, download, modify, adapt, reproduce, store, distribute, print, display, perform, publish, communicate, transmit, or deal in any way with the media coverage without prior written permission.</p>
                    <p>Notwithstanding any provision to the contrary, you may use the information on this Site about the media coverage available on this Site, namely the estimated advertising value (EAV), the reach, the number of stories, the title and the operators mentioned, (the ‘media coverage information’) for the following purposes:</p>
                    <ul>
                        <li>to accurately quote the media coverage information for business reporting purposes, namely internal business use and/or reporting to your council or governing body.</li>
                        <li>You must not remove, cover, overlay, obscure or change any copyright notices, legends, or terms that we post on this Site.</li>
                    </ul>
                    <h5>Tourism New Zealand trade marks</h5>
                    <p>The name and logo of Tourism New Zealand and 100% Pure New Zealand belong to us. You must not use or reproduce or allow anyone to use or reproduce those trade marks for any reason without our prior written permission in each case.</p>
                    <h5>Feedback</h5>
                    <p>If you give us feedback about this Site or our services we may use that feedback for the purpose of improving our Site or our services (and for any other purpose we deem necessary or desirable) without being obliged to pay you any compensation. If you do send us feedback or ideas:</p>
                    <ul>
                        <li>they will be deemed to be non-confidential; and</li>
                        <li>we will not be required to provide any acknowledgement of their source.</li>
                    </ul>
                    <h5>Electronic communications</h5>
                    <p>You consent to receive electronic communications and information from us (or on our behalf) and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree to be bound by any agreement reached through electronic communications in terms of the Electronic Transactions Act 2002. You consent to receiving electronic messages and information sent by us (or on our behalf) for any purposes described in our Privacy Policy. You agree, pursuant to section 11(2) of the Unsolicited Electronic Messages Act 2007, that the person sending any such message need not include a functional unsubscribe facility in that message.</p>
                    <h5>Disclaimers</h5>
                    <p>We endeavour to ensure that the accuracy of information available through this Site and that the content of this Site is free from errors. However, we do not give any warranties or other assurances as to the content of information or material available on or through this Site (including the media coverage), its accuracy, operation, quality, functionality, completeness, timeliness or suitability for any particular purpose.</p>
                    <p>To the maximum extent permitted by law, and subject to any agreement we have with you that provides to the contrary, we provide this Site and all media coverage material, information, and services on an "as is" basis without any warranties, representations, or guarantees of any kind (whether, express, implied, statutory or otherwise) including, but not limited to, warranties of non-infringement or fitness for a particular purpose.</p>
                    <p>You acknowledge that if you rely on this Site or any media coverage or other material or information available through this Site, you do so solely at your own risk.</p>
                    <p>The views expressed in any third party material (including the media coverage) on or available through this Site are not necessarily the views of Tourism New Zealand, and we expressly disclaim all responsibility for any third party material on or available through this Site.</p>
                    <p>If any provision of these terms of use is held to be invalid or unenforceable for any reason, the remaining provisions shall, to the maximum extent possible, remain in full force and effect.</p>
                    <h5>Liability and indemnity</h5>
                    <p>To the maximum extent permitted by law, neither Tourism New Zealand or our suppliers will, under any circumstances, be liable in tort (including negligence), contract, or otherwise for any damages or losses (including, without limitation, loss of profits or revenue, financial loss, loss of or corruption to data, damages for loss of business opportunity, or any direct, indirect, incidental, special, consequential, or economic damages or losses) howsoever caused, which you may directly or indirectly suffer in connection with:</p>
                    <ul>
                        <li>the use of, or inability to use, the Site or any material (including any media coverage) or information available on or through the Site;</li>
                        <li>any material (including any media coverage) or information available on or through the Site; or</li>
                        <li>any action or decision taken as a result of accessing or using this Site, or any material (including any media coverage) or information available on or through the Site.</li>
                    </ul>
                    <p>If the exclusion of liability in the previous paragraph is held to be invalid in whole or in part, then our maximum aggregate liability to you for all damages, costs, and expense (other than for any damage, cost and expense that cannot be limited at law) will not exceed the amount of one New Zealand dollar (NZD$1.00).</p>
                    <p>You agree to indemnify, defend, and hold harmless Tourism New Zealand, and its officers, directors, shareholders, employees, consultants, licensors, agents, and related bodies corporate from any and all third-party claims, liability, damages, and costs (including reasonable lawyers' fees) arising from your use of, or reliance on, this Site or any material or information available on or through this Site (including the media coverage), your failure to comply with these terms of use, or from your violation of any applicable law.</p>
                    <h5>Availability</h5>
                    <p>Your use of this Site, the information and material available on or through this Site (including the media coverage), and any associated services may sometimes be subject to interruption, unavailability, restriction, or delay. Due to the nature of the Internet and electronic communications, we (and our service providers) do not warrant that this Site, the information or material available on or through this Site (including the media coverage), or any associated services will be error free, without interruption or delay, or free from defects. We will not be liable to you should this Site, the information or material available on or through this Site (including the media coverage), or the services supplied through this Site become unavailable, interrupted, restricted or delayed for any reason.</p>
                    <h5>Third party websites</h5>
                    <p>This Site may contain hyperlinks to third party websites (‘external hyperlink’). External hyperlinks are provided for your convenience only and may not remain current or be maintained. We make no endorsement of, and accept no responsibility for, any content accessible through any external hyperlink. We will not be liable for any damages or loss arising in any way out of or in connection with or incidental to any information or service provided by any third party. You are responsible for complying with any terms and conditions imposed by third party websites.</p>
                    <h5>Malicious code</h5>
                    <p>You acknowledge that despite all reasonable precautions taken on our part to prevent the introduction of viruses or other malicious code (together, ‘malicious code’) to this Site, we do not guarantee or warrant that this Site, or any material available on or through it (including the media coverage), does not contain malicious code. We will not be liable for any damages or harm attributable to malicious code. You are responsible for ensuring that the process that you employ for accessing and using this Site does not expose your computer system to the risk of interference or damage from malicious code.</p>
                    <h5>Security</h5>
                    <p>You acknowledge that despite all reasonable precautions taken on our part, there is a risk of unauthorised access to (or alteration of) your transmissions or data or the information contained on your computer system or on this Site. We do not accept responsibility or liability of any nature for any losses that you may sustain as a result of such unauthorised access or alteration. All information transmitted to you or from you is transmitted at your risk. You assume all responsibility and risks arising in relation to your use of this Site and the Internet. We do not accept responsibility for any interference or damage to your own computer system which may arise in connection with your accessing of this Site or any external hyperlink.</p>
                    <h5>Changes</h5>
                    <p>We may add to, modify, suspend, or remove this Site or any information, feature, specification, or material on or incorporated in this Site (at any time and without notice to you). We may change these terms of use from time to time by publishing the changed terms of use on this Site, and such changes will be effective immediately upon publication of the same. You should review the terms of use periodically to be aware of such changes. By continuing to access or use this Site following such publication you agree to be bound by the revised terms of use.</p>
                    <h5>Applicable laws</h5>
                    <p>Details contained on this Site have been prepared in accordance with New Zealand law and may not satisfy the laws of any other country. We make no representations or warranties as to whether or not the information or materials (including the media coverage) available from this Site is appropriate in other countries. If you choose to access this Site from outside New Zealand you are responsible for compliance with applicable local law.</p>
                    <h5>Governing law</h5>
                    <p>These terms of use (and any contracts between you and us which arise through your use of this Site) shall be governed by the laws of New Zealand and the courts of New Zealand shall have non-exclusive jurisdiction to hear and determine any dispute arising in relation to these terms of use (and any contracts between you and us which arise through your use of this Site).</p>
                    <p><small>These terms of use were last updated on 20th Oct 2016.</small></p>

                </div>
            </Page>
        </DocumentTitle>
    );
}

export default Content;
