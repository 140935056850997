import { getAllFields } from './fields';
import moment from 'moment';
import ResultActions from '../action/result-actions';
import ResultStore from '../store/result-store';
import { formatFieldErrorFromApi, formatFieldNameFromApi } from '../utility/form';

const VALIDATION_STATUS = [
    'approved',
    'ready_for_review',
    // 'published' TODO: Add this in when validation is better for published results
];

/**
 * Reformat the API data to update the form fields in the ResultStore
 *
 * @param  {array} fields Array of the current fields to update
 */
export function processResultFromApi(fields) {
    let fieldIds = getAllFields();

    // Cleanse API data
    fieldIds.map((id) => {
        let
            booleanFields = ['partial_support', 'explore_pass', 'is_high_impact', 'is_premium'],
            value = fields[id];

        if (value !== null && id !== 'media') {
            if (Array.isArray(value)) {
                value = value.map((object) => object.id);
            }

            if (booleanFields.indexOf(id) !== -1) { // eslint-disable-line
                value = fields[id] ? 1 : 0; // eslint-disable-line
            }

            if (id === 'visit_start_date' ||
                id === 'visit_end_date' ||
                id === 'live_date') {
                value = moment(value).toString();
            }
        }

        ResultActions.update(id, value);
    });
}


/**
 * Reformat the result form data for the API
 *
 * @param  {object} fields Array of the current fields on the form
 *
 * @return {object} formData formated form data
 */
export function processResultToApi(fields) {
    let data = ResultStore.getState().fields,
        formData = {};

    // Pass field values to data
    fields.forEach((field) => {
        formData[field] = data[field];
    });

    return formData;
}


/**
 * Check form response and update error state if applicable
 *
 * @param  {object} response Object returned from the API when a form is submitted
 *
 * @return {bool}
 */
export function responseHasErrors(response) {
    // Guard to check if response errors exist
    if (!response.hasOwnProperty('errors')) {
        return false;
    }

    let errors = response.errors;

    // Check for field errors
    if (errors.hasOwnProperty('fields')) {
        for (let prop in errors.fields) {
            ResultActions.error(formatFieldNameFromApi(prop), formatFieldErrorFromApi(errors.fields[prop]));
        }
    }

    return true;
}


/**
 * Check the saving status of the result needs validation
 *
 * @param  {string} status
 *
 * @return {bool}
 */
export function shouldValidate(status) {
    if (VALIDATION_STATUS.indexOf(status) !== -1) { // eslint-disable-line no-magic-numbers
        return true;
    }

    return false;
}


/**
 * Validate the fields from the result store and return a validation object
 *
 * @return {object} validationObject with isValid(bool) and invalidFields(array)
 */
export function formHasErrors() {
    const resultType = ResultStore.getState().fields.result_type;
    const validation = ResultStore.getState().validation;

    // Return object
    const validationObject = {
        isValid: true,
        invalidFields: []
    };

    // Validate all fields
    const fields = getAllFields(resultType);

    // Validation dependent on save-status
    for (let field in validation) {
        if (fields.indexOf(field) !== -1 && validation[field] === false) { // eslint-disable-line
            validationObject.invalidFields.push(field);
            validationObject.isValid = false;
        }
    }

    return validationObject;
}
