import Field from './field';
import FormActions from '../action/form-actions';
import FormStore from '../store/form-store';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FormField extends Component {
    constructor(props) {
        super(props);

        this.onStoreChange = this.onStoreChange.bind(this);

        this.state = {
            error: '',
            value: FormStore.getState().fields[this.props.id]
        };
    }


    // Mounting
    componentDidMount() {
        FormStore.listen(this.onStoreChange);
    }

    componentWillUnmount() {
        FormStore.unlisten(this.onStoreChange);
    }


    // Handler
    handleChange(field, result) {
        FormActions.update(field, result);
    }


    // Events
    onStoreChange() {
        // Check validation if submitting
        if (FormStore.isSubmitting()) {
            let error = '',
                fieldValidation = FormStore.getState().validation[this.props.id];

            // Is required
            if (fieldValidation === false) {
                error = 'This field is required';
            }

            // Has error
            if (typeof fieldValidation === 'string') {
                error = fieldValidation;
            }

            this.setState({ error: error });
        }

        // Check if value has been updated
        if (this.state.value !== FormStore.getState().fields[this.props.id]) {
            this.setState({ value: FormStore.getState().fields[this.props.id] });
        }

        // Check validation
        let validation = FormStore.getState().validation[this.props.id];
        if (typeof validation === 'string') {
            let error = FormStore.getState().validation[this.props.id];

            this.setState({ error: error });
        }
    }


    // Render
    render() {
        return (<Field {...this.props} onChange={this.handleChange} value={this.state.value} error={this.state.error}/>);
    }
}

FormField.propTypes = {
    id: PropTypes.string.isRequired
};

export default FormField;
