import { ALLOW_NONE_SUFFIX } from '../config/result';
import classnames from 'classnames';
import DatePicker from '../component/date-picker';
import DynamicNumber from 'react-dynamic-number';
import { ENDPOINTS } from '../config/api';
import { FAUX_LOADING_TIME } from '../config/app';
import NotificationActions from '../action/notification-actions';
import PropTypes from 'prop-types';
import { queryPrefix } from '../utility/format';
import ReactDom from 'react-dom';
import React, { Component } from 'react';
import { request } from '../utility/request'; // eslint-disable-line sort-imports
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ToolTip from '../component/tool-tip';
import UserStore from '../store/user-store';

const SELECT_OPTIONS = {
    joinValues: true,
    delimiter: ',',
    matchProp: 'label', // Only matches the label property for searching
    addLabelText: 'Press enter to add "{label}" as an option?' // Only applicable to allowCreate = true
};

const SimpleSelect = React.forwardRef((props, ref) => {
    const {value, options, onChange, isMulti, array: isArray, ...rest} = props

    const handleChange = React.useCallback((selectedOpt) => {
        if (isArray) {
            if (Array.isArray(selectedOpt)) {
                onChange(selectedOpt.map(iter => iter.value))
            } else {
                onChange(selectedOpt ? [selectedOpt.value] : [])
            }
        } else {
            onChange(selectedOpt ? selectedOpt.value : null)
        }
    }, [onChange, isArray])

    const selectedValue = React.useMemo(() => {
        return isArray ?
            options.filter(opt => (value || []).includes(opt.value)) :
            options.find(opt => opt.value === value)
    }, [value, options, isArray])


    return (
        <Select
            ref={ref}
            value={selectedValue}
            onChange={handleChange}
            options={options}
            isMulti={isMulti}
            {...rest}
        />
    )
})

const SimpleAsyncSelect = React.forwardRef((props, ref) => {
    let triggerFlag = props.id;
    const {endpoint, value, onChange, ...rest} = props
    const [selectedValues, setSelectedValues] = React.useState([])
    const [isInitialLoad, setIsInitialLoad] = React.useState(true)

    const loadAsyncOptions = React.useCallback((searchString, callback) => {
        if (!searchString) {
            callback([])
            return
        }
        // By default we will async all the data
        request(`${endpoint}?limit=99999&search=${searchString}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                callback(convertDataToOptions(response.data))
            })
            .catch((error) => {
                NotificationActions.push({ type: 'error', message: 'Failed to load page data. Please refresh and try again.' });
                console.warn('Parsing failed', endpoint, error); // eslint-disable-line
                callback([])
            });
    }, [endpoint])

    const handleChange = React.useCallback((selectedOpts) => {
        if (triggerFlag !== undefined && triggerFlag === 'organisation_id') {
            setSelectedValues(selectedOpts);
            selectedOpts !== null ? onChange(selectedOpts) : onChange(null);
        }
        else {
            setSelectedValues(selectedOpts)
            onChange(selectedOpts.isArray ? selectedOpts.map(opt => opt.value) : selectedOpts.value)
        }
    }, [onChange])

    // load initial selected values
    React.useEffect(() => {
        if (!isInitialLoad) {
            return
        }

        if (Array.isArray(value)) {
            Promise.all(
                value.map((id) =>
                    request(`${endpoint}/${id}`)
                        .then(res => res.json())
                        .then(res => res.data)
                )
            ).then((data) =>{
                if (triggerFlag !== undefined && triggerFlag === 'organisation_id') {
                    let tempValue = convertDataToOptions(data);
                    setSelectedValues(tempValue);
                    setIsInitialLoad(false)
                    onChange(tempValue);
                }
                else {
                    setSelectedValues(convertDataToOptions(data))
                    setIsInitialLoad(false)
                }
            })
        }
    }, [value, endpoint, isInitialLoad])

    return (
        <AsyncSelect
            ref={ref}
            value={selectedValues}
            onChange={handleChange}
            loadOptions={loadAsyncOptions}
            {...rest}
        />
    )
})


function convertDataToOptions(objectArray) {
    return objectArray.map((object) => {
        let option = {
            render: 'is-verified',
            label: object.name,
            value: object.id
        };

        if (object.hasOwnProperty('sub_organisation')) {
            option.label = object.sub_organisation.name;
        }

        if (object.hasOwnProperty('type') && object.type === 'rto') {
            option.info = {
                label: 'RTO',
                type: 'primary'
            };
        }

        if (object.hasOwnProperty('is_verified') && object.is_verified === false) {
            option.render = 'is-unverified';
        }

        if (object.hasOwnProperty('deleted') && object.deleted === true) {
            option.deleted = true;
        }

        return option;
    });
}

class Field extends Component {
    constructor(props) {
        super(props);

        this.handleAllowNoneChange = this.handleAllowNoneChange.bind(this);
        this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.renderSelectOption = this.renderSelectOption.bind(this);

        this.state = {
            data: [],
            error: this.props.error,
            inputValue: '',
            loading: false,
            options: [],
            value: this.props.value,
            valueAllowNone: this.props.valueAllowNone,
            fileUploadIsValid: true,
        };
    }


    // Mounting
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            error: nextProps.error,
            isDisabled: nextProps.isDisabled,
            value: nextProps.value,
            valueAllowNone: nextProps.valueAllowNone
        });
    }

    UNSAFE_componentWillMount() {
        this.populateSelectOptions();
    }


    // Helper
    addFileToState(result) {
        let field,
            value;

        field = this.props.id;

        // Set in state
        if (this.state.value === '') {
            value = [result];
        } else {
            value = [...this.state.value]; // Clone array
            value.push(result);
        }
        this.props.onChange(field, value);
    }

    populateSelectOptions() {
        let { endpoint, options, values } = this.props;

        // Detect if options should be 'lazy loaded' from the API as you type
        if (this.state.value === '' || this.state.value.length === 0) { // eslint-disable-line
            if (options && options.autoload === false) {
                return;
            }
        }

        if (!endpoint) {
            this.setState({ options: values });

            return;
        }

        // Add limit
        if (options && options.hasOwnProperty('limit')) {
            endpoint += `${queryPrefix(endpoint)}limit=${options.limit}`;
        }

        // Add is verified
        if (options && options.hasOwnProperty('isVerified')) {
            endpoint += `${queryPrefix(endpoint)}is_verified=${options.isVerified}`;
        }

        this.setState({ loading: true });

        // Get select 'options' from the API
        request(endpoint)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                let jsonData = json.data;

                this.setState({
                    data: jsonData,
                    loading: false,
                    options: convertDataToOptions(jsonData)
                });
            })
            .catch((error) => {
                NotificationActions.push({ type: 'error', message: 'Failed to load page data. Please refresh and try again.' });
                console.warn('Parsing failed', endpoint, error); // eslint-disable-line
            });
    }

    removeMediaItem(item) {
        let body = {
            file_key: item.file_key, // eslint-disable-line camelcase
            media_reference: item.id // eslint-disable-line camelcase
        };

        return request(ENDPOINTS.RESULTS_MEDIA + '/' + body.media_reference, body, 'DELETE')
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.status);
                }

                return response.json();
            })
            .catch((error) => {
                console.warn('Error with file removal', error); // eslint-disable-line

                NotificationActions.push({
                    message: 'File removal failed. Please try again.',
                    type: 'error'
                });
            });
    }

    storeFileCatalystCloud(details, event, file) {
        const token = UserStore.getState().token;

        // Get result reference from url
        var url = window.location.href;
        var lastSlash = url.lastIndexOf('/');
        var firstParameter = url.indexOf('?');
        // ... without parameter
        if (firstParameter == -1) {
            firstParameter = url.length;
        }
        // ... with trailing slash
        if (lastSlash + 1 == firstParameter) {
            firstParameter = lastSlash;
            lastSlash = url.substring(0, lastSlash).lastIndexOf('/');
        }
        var reference = url.substring(lastSlash + 1, firstParameter);

        var uploadData = new FormData();
        //uploadData.append('file', event.currentTarget.result);
        uploadData.append('file', file);
        uploadData.append('file_key', details.file_key);
        uploadData.append('name', details.name);
        uploadData.append('mime_type', details.mime_type);
        uploadData.append('reference', reference);

        fetch(ENDPOINTS.RESULTS_MEDIA, {
            method: 'POST',
            body: uploadData
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.status);
            }
            console.info('File stored on Catalyst Cloud'); // eslint-disable-line
            this.addFileToState(details);
         })
         .catch((error) => {
             console.warn('Error with file storage on Catalyst Cloud', error); // eslint-disable-line

             NotificationActions.push({
                 message: 'File upload failed. Please try again.',
                 type: 'error'
             });
         });
    }

    checkFileExtension(file) {
        if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
            return false;
        }
        return true;
    }

    checkFileSize(file) {
        if (file.size > 5242880) {
            return false;
        }
        return true;
    }


    // Helper
    focus() {
        if (this.field.focus) {
            this.field.focus();
        }

        /*    Workaround for the react-dynamic-number component
        *     Issue raised on Github: https://github.com/uhlryk/react-dynamic-number/issues/28
        */
        const domElement = ReactDom.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
        const inputElement = domElement.querySelector('input:not([type="hidden"])');
        inputElement.focus();
    }


    // Handlers
    handleInputChange(event) {
        this.handleChange(event.target.value);
    }

    handleFileUploadChange(event) {
        let file,
            result;

        file = event.target.files[0];
        result = {};


        if (file) {
            if (!this.validateFileUpload(file)) {
                return;
            }

            this.setState({ loading: true });

            // Set loading to global state
            this.props.onUploadChange(true);

            result.name = file.name;
            result.mime_type = file.type;

            request(ENDPOINTS.RESULTS_MEDIADATA, result, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                let { name: filename, mime_type: filetype, file_key: filekey } = json.data,
                reader = new FileReader();
                result.file_key = filekey;

                reader.onload = (loadevent) => {
                    this.storeFileCatalystCloud(result, loadevent, file);
                }
                reader.readAsDataURL(file);

                // Remove loading
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        inputValue: '' // Clear after each upload so the onChange will fire again
                    });
                    this.props.onUploadChange(false);
                }, FAUX_LOADING_TIME);
            });
        }
    }

    handleAllowNoneChange(event) {
        this.props.onAllowNoneChange(this.props.id, event.target.checked);
    }

    handleItemRemove(index) {
        let item,
            newState;

        this.setState({ loading: true });

        item = this.state.value[index];
        newState = [...this.state.value];

        // Remove item
        newState.splice(index, 1); // eslint-disable-line

        this.removeMediaItem(item)
            .then(() => {
                let field = this.props.id;

                this.props.onChange(field, newState);

                // Remove loading
                setTimeout(() => {
                    this.setState({ loading: false });
                }, FAUX_LOADING_TIME);
            });
    }

    handleChange(value) {
        let field = this.props.id,
            result = value,
            type = this.props.type;

        if (typeof value !== 'boolean' && this.props.options && this.props.options.array) {
            if (Number.isInteger(value)) {
                result = [value];
            } else if (value === '') {
                result = [];
            } else {
                result = value;
            }
        }

        // Strip out non-numeric characters
        if (type === 'number') {
            result = result.replace(/\D/g, '');
        }

        // Set in state
        this.props.onChange(field, result);
    }

    handleFocus() {
        const { formId } = this.props;

        this.props.onFocus(formId, true);
    }

    renderErrorLink(error) {
        return { __html: error };
    }

    // Render
    renderErrors() {
        const { error } = this.state;

        if (error) {
            // Checks whether there is an <a> tag on the error descrition and render it as a link for the user
            if (error.includes('</a>')) {
                return (<p className="error" dangerouslySetInnerHTML={this.renderErrorLink(error)} />);
            }
            return (<p className="error">{error}</p>);
        }
    }

    validateFileUpload(file) {
        if (!this.checkFileExtension(file) || !this.checkFileSize(file)) {
            this.setState({
                loading: false,
                inputValue: '',
                fileUploadIsValid: false
            });
            return false;
        }
        this.setState({
            fileUploadIsValid: true
        });
        return true;
    }



    renderInput() {
        let { allowNone, id, placeholder, values, options, type } = this.props,
            input,
            items;

        switch (type) {
            case 'email':
                input = (
                    <input
                        id={id}
                        type="email"
                        className="input"
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        onChange={this.handleInputChange}
                        onFocus={this.handleFocus}
                        placeholder={placeholder} />
                );
                break;
            case 'number':
                input = (
                    <DynamicNumber
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        separator={'.'}
                        thousand={true}
                        fraction={0}
                        integer={10}
                        positive={true}
                        negative={false}
                        onChange={this.handleInputChange}
                        onFocus={this.handleFocus}
                    />
                );
                break;
            case 'file':
                input = (
                    <div className="input || file-upload">
                        <input
                            id={id}
                            type="file"
                            className="file-input"
                            ref={(field) => { this.field = field; }}
                            onChange={this.handleFileUploadChange}
                            onFocus={this.handleFocus}
                            value={this.state.inputValue}
                            {...options} />
                        <label
                            className="button medium || file-label"
                            htmlFor={id}>Upload file</label>
                        {!this.state.fileUploadIsValid ? <p className="error">File type must be either PDF, JPG, JPEG. Max upload file size is 5MB</p> : null}
                    </div>
                );
                break;
            case 'text':
                input = (
                    <input
                        id={id}
                        type="text"
                        className="input"
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        onChange={this.handleInputChange}
                        onFocus={this.handleFocus}
                        placeholder={placeholder} />
                );
                break;
            case 'textarea':
                input = (
                    <textarea
                        id={id}
                        className="input"
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        onChange={this.handleInputChange}
                        onFocus={this.handleFocus}
                        placeholder={placeholder} />
                );
                break;
            case 'select':
                input = (
                    <SimpleSelect
                        id={id}
                        className="input || select"
                        ref={(field) => { this.field = field; }}
                        isDisabled={this.props.isDisabled || this.state.valueAllowNone}
                        value={this.state.value}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        placeholder={placeholder}
                        options={this.state.options}
                        {...SELECT_OPTIONS}
                        {...options}
                    />
                );
                break;
            case 'select-async': {
                input = (
                    <SimpleAsyncSelect
                        id={id}
                        className="input || select"
                        isDisabled={this.props.isDisabled || this.state.valueAllowNone}
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        placeholder={placeholder}
                        endpoint={this.props.endpoint}
                        {...SELECT_OPTIONS}
                        {...options} />
                );
                break;
            }
            case 'date':
                input = (
                    <DatePicker
                        ref={(field) => { this.field = field; }}
                        value={this.state.value}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus} />
                );
                break;
            case 'radio':
                items = values.map((data, index) => {
                    let { description, label, value } = data,
                        itemId = `${id}-${index}`;

                    return (
                        <div key={index} className="item">
                            <input
                                ref={(field) => { this.field = field; }}
                                name={id}
                                type="radio"
                                id={itemId}
                                className="input"
                                onChange={this.handleInputChange}
                                onFocus={this.handleFocus}
                                value={value} />
                            <label className="label" htmlFor={itemId}>
                                <span className="title || style-h3">{label}</span>
                                <span className="description">{description}</span>
                            </label>
                        </div>
                    );
                });

                input = (<div role="group" className="input || radio-group">{items}</div>);

                break;
            default:
        }


        // Wrap the field with allow none object
        if (allowNone) {
            return (
                <div className="allow-none-wrapper">
                    {input}
                    <div className="allow-none">
                        <input
                            name={`${id}_none`}
                            id={`${id}_none`}
                            className="option-input"
                            type="checkbox"
                            onFocus={this.handleFocus}
                            onChange={this.handleAllowNoneChange}
                            checked={this.state.valueAllowNone}
                        />
                        <label className="option-label" htmlFor={`${id}${ALLOW_NONE_SUFFIX}`}>None</label>
                    </div>
                </div>
            );
        }

        return input;
    }

    renderLabel() {
        let { id, label } = this.props;

        if (label) return (<label className="label" htmlFor={id}>{label}</label>);
    }

    renderOutput() {
        if (this.props.output) {
            return (
                <ul className="file-upload-list">
                    {this.renderOutputItems()}
                </ul>
            );
        }
    }

    renderOutputItems() {
        if (Array.isArray(this.state.value)) {
            return this.state.value.map((item, index) => {
                return (
                    <li className="item" key={index}>
                        <p>{item.name}</p>
                        <button type="button" className="remove" onClick={this.handleItemRemove.bind(this, index)}>×</button>
                    </li>
                );
            });
        }
    }

    renderSelectOption(option) {
        let { options } = this.props,
            pillText = null;

        // If has explainer text
        if (option.hasOwnProperty('info')) {
            const { info } = option;

            pillText = (
                <span className={`pill ${info.type}`}>
                    {info.label}
                </span>
            );
        }

        if (options) {
            // Default to new/current options which don't have a render method
            let renderClass = 'is-new is-unverified';

            if (option.render) {
                renderClass = option.render;
            }

            if (option.hasOwnProperty('deleted') && option.deleted === true) {
                renderClass = 'is-deleted';
            }

            return (
                <span className={`custom-render ${renderClass}`}>
                    {option.label}
                    {pillText}
                </span>
            );
        }

        return (
            <span className="custom-render">
                {option.label}
                {pillText}
            </span>
        );
    }

    renderToolTip() {
        let { toolTip } = this.props;

        if (toolTip) return (<ToolTip content={toolTip} size="large" />);
    }

    render() {
        let fieldClass = classnames('field', {
            'has-error': this.state.error,
            'is-loading': this.state.loading
        });

        return (
            <div className={fieldClass}>
                {this.renderLabel()}
                {this.renderToolTip()}
                {this.renderInput()}
                {this.renderOutput()}
                {this.renderErrors()}
            </div>
        );
    }
}

Field.propTypes = {
    allowNone: PropTypes.bool,
    endpoint: PropTypes.string,
    error: PropTypes.string,
    formId: PropTypes.string,
    input: PropTypes.string,
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    onUploadChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onAllowNoneChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    options: PropTypes.object,
    output: PropTypes.bool,
    toolTip: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
        PropTypes.string
    ]),
    valueAllowNone: PropTypes.bool,
    values: PropTypes.array
};

Field.defaultProps = {
    allowNone: false,
    error: '',
    isDisabled: false,
    onAllowNoneChange: function() { }, // eslint-disable-line no-empty-function
    onFocus: () => null,
    value: '',
    valueAllowNone: false
};

export default Field;
