import * as format from '../../utility/format';
import classnames from 'classnames';
import DialogActions from '../../action/dialog-actions';
import DocumentTitle from '../../utility/document-title';
import { ENDPOINTS } from '../../config/api';
import { Link } from 'react-router-dom';
import NotificationActions from '../../action/notification-actions';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactPlayer from 'react-player';
import { request } from '../../utility/request';
import ToolTip from '../../component/tool-tip';
import { TOOLTIPS } from '../../config/result';
import { FAUX_LOADING_TIME, FORM_CONTROLS_HOOK } from '../../config/app';
import React, { Component } from 'react';


class Result extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            loading: true
        };
    }


    // Lifecycle
    UNSAFE_componentWillMount() {
        // Get result data
        request(`${ENDPOINTS.RESULTS}/${this.state.id}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.setState({ data: response.data });

                setTimeout(() => {
                    this.setState({ loading: false });
                }, FAUX_LOADING_TIME);
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ id: nextProps.match.params.id });
    }


    // Handler
    handleAssetClick(name) {
        ReactGA.event({
            category: 'Result',
            action: 'Clicked Asset Link',
            label: name
        });
    }

    handleDuplicate() {
        DialogActions.confirm('You are about to duplicate this result.', this.duplicateAction.bind(this));
    }

    duplicateAction() {
        this.setState({ loading: true });

        request(ENDPOINTS.RESULTS_SYNDICATED, { id: this.props.match.params.id }, 'POST')
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                let syndicated = json.data;

                // Redirect to syndicated
                this.props.history.push(`/result/${syndicated.id}?open=coverage-details`);
                NotificationActions.push({
                    message: 'You are now editing the new syndicated result.',
                    delay: 1000
                });
            });
    }


    // Renders
    renderAssets(media) {
        if (media.length) {
            return (
                <div>
                    <h6>Assets:</h6>
                    <ul className="simple">
                        {this.renderAssetItem(media)}
                    </ul>
                </div>
            );
        }
    }

    renderAssetItem(media) {
        return media.map((item, index) => {
            let { file_url: url, name: name } = item;

            return (<li key={index}><a href={url} target="_blank" onClick={this.handleAssetClick.bind(this, name)}>{name}</a></li>);
        });
    }

    renderInfo() {
        let { meta, result_type: resultType, status } = this.state.data,
            statusObject = format.resultStatus(status);

        // Add guard to check for user's permissions
        if (!meta.permissions.can_view_status) {
            return null;
        }

        return (
            <section className="content-section">
                <div className="actions">
                    <div className={`status ${statusObject.class}`}>{statusObject.label}</div>
                    <div className="tag">
                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                            <use xlinkHref={`/assets/icons/_sprite.svg#${format.resultType(resultType).icon}`}></use>
                        </svg>
                        {format.resultType(resultType).label}
                    </div>
                </div>
            </section>
        );
    }

    renderMediaType() {
        let { media_type: mediaType } = this.state.data;

        if (mediaType) {
            return (
                <div className="tag">
                    <svg viewBox="0 0 40 40" width="40" height="40" className="icon">
                        <use xlinkHref={`/assets/icons/_sprite.svg#${format.mediaIcon(mediaType.name)}`}></use>
                    </svg>
                    {mediaType.name}
                </div>
            );
        }
    }

    renderOperators() {
        let items = [],
            { operators } = this.state.data;

        operators.forEach((item, index) => {
            items.push(<li key={index}>{item.name}</li>);
        });

        if (items.length > 0) { // eslint-disable-line
            return (
                <div>
                    <h6>Operators Mentioned:</h6>
                    <ul className="simple || operators-list">{items}</ul>
                </div>
            );
        }
    }

    renderPageActions() {
        if (!this.state.data) {
            return null;
        }

        const { status, meta, is_syndicated: isSyndicated } = this.state.data;

        if (!meta.permissions.can_edit) return; // eslint-disable-line

        if (status !== 'published') {
            return (
                <div className="page-actions" data-hook={FORM_CONTROLS_HOOK}>
                    <div className="button-group" role="group">
                        <Link className="button primary" to={`/result/${this.state.id}`}>Edit</Link>
                    </div>
                </div>
            );
        }

        if (status === 'published' && isSyndicated) {
            return (
                <div className="page-actions" data-hook={FORM_CONTROLS_HOOK}>
                    <div className="button-group" role="group">
                        <button className="button primary" onClick={this.handleDuplicate.bind(this)}>Duplicate Syndicated Result</button>
                    </div>
                </div>
            );
        }
    }

    renderSyndicatedTag() {
        let { is_syndicated: isSyndicated } = this.state.data;

        if (isSyndicated) {
            // TODO: Show the tool tip once the info has been supplied
            return (<h6 className="thin || note">This result is syndicated {/* <ToolTip content={TOOLTIPS.SYNDICATED} position="right"/> */}</h6>);
        }
    }

    renderText(label, text) {
        if (text) {
            return (
                <div>
                    <h6>{label}</h6>
                    <p>{text}</p>
                </div>
            );
        }
    }

    renderUrl(label = 'URL', url) {
        if (url) {
            return (
                <div>
                    <h6>{label}</h6>
                    <p><a target="_blank" href={url}>{url}</a></p>
                </div>
            );
        }
    }

    renderVideo(url) {
        if (url) {
            return (
                <div className="video">
                    <ReactPlayer url={url} controls/>
                </div>
            );
        }
    }

    renderResult() {
        if (!this.state.data) {
            return null;
        }

        let {
            author,
            article_url: articleUrl,
            country,
            eav,
            live_date: liveDate,
            media,
            media_name: mediaName,
            media_type: mediaType,
            reach,
            story_summary: storySummary,
            story_title: storyTitle,
            video_url: videoUrl
        } = this.state.data;

        // Fallback values
        if (!country) {
            country = {
                name: '-'
            };
        }

        if (!eav) {
            eav = '-';
        }

        if (!mediaType) {
            mediaType = {
                name: '-'
            };
        }

        if (!reach) {
            reach = '-';
        }

        return (
            <div className="constrain-width">
                <div role="group" className="page-navigation">
                    <button role="button" className="button medium secondary block || back" onClick={() => { this.props.history.goBack(); }}>
                        <svg viewBox="0 0 40 40" width="40" height="40" className="icon left">
                            <use xlinkHref="/assets/icons/_sprite.svg#arrow-left"></use>
                        </svg>
                        Back
                    </button>
                </div>
                <div className="result-view">
                    <div className="content">
                        <header role="header" className="content-section">
                            <h1 className="style-h2">{storyTitle}</h1>
                        </header>
                        <section className="content-section">
                            <div className="actions">{this.renderMediaType()}</div>
                            {this.renderText('Author:', author)}
                            {this.renderText('Media Outlet:', mediaName)}
                            {this.renderText('Live date:', format.date(liveDate))}
                        </section>
                        <section className="content-section">
                            {this.renderText('Summary:', storySummary)}
                            {this.renderUrl('URL', articleUrl)}
                            {this.renderUrl('Video URL', videoUrl)}
                            {this.renderVideo(videoUrl)}
                            {this.renderAssets(media)}
                            <p className="small">This media story itself and associated documents are for viewing only. While you may share a summary of the information, you are not permitted to copy, download or distribute any individual stories.</p>
                        </section>
                    </div>
                    <main className="sidebar">
                        {this.renderInfo()}
                        <section className="content-section">
                            <div className="text-total">
                                <h6 className="title || thin">Country of origin/market:</h6>
                                <p className="style-h2 pure-pakati">{country.name}</p>
                            </div>
                            <div className="text-total">
                                <h6 className="title || thin">Estimated advertising value <ToolTip content={TOOLTIPS.EAV} size="large" position="right"/></h6>
                                <p className="style-h2 pure-pakati">${format.number(eav)}</p>
                            </div>
                            <div className="text-total">
                                <h6 className="title || thin">{format.reachLabel(mediaType.name)} <ToolTip content={TOOLTIPS.REACH} size="large" position="right"/></h6>
                                <p className="style-h2 pure-pakati">{format.number(reach)}</p>
                            </div>
                            {this.renderSyndicatedTag()}
                        </section>
                        <section className="content-section">
                            {this.renderOperators()}
                        </section>
                    </main>
                </div>
            </div>
        );
    }

    render() {
        let mainClass,
            storyTitle = this.state.data ? this.state.data.story_title : ''; // Page still needs to render if the data isn't loaded yet

        mainClass = classnames({
            'main': true,
            'is-loading': this.state.loading
        });

        return (
            <DocumentTitle title={`Result: ${storyTitle}`}>
                <main role="main" className={mainClass}>
                    <div className="main-inner">
                        {this.renderResult()}
                        {this.renderPageActions()}
                    </div>
                </main>
            </DocumentTitle>
        );
    }
}

Result.propTypes = ({
    match: PropTypes.object
});

export default Result;
