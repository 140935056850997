/* Travel up the dom and return element matching selector */
export default function closest(element, selector) {
    let firstChar = selector.charAt(0); // eslint-disable-line no-magic-numbers

    // Get closest match
    for (let i = element; i !== document; i = i.parentNode) {
        // If selector is a class
        if (firstChar === '.') {
            if (i.classList.contains(selector.substr(1))) { // eslint-disable-line no-magic-numbers
                return i;
            }
        }

        // If selector is an ID
        if (firstChar === '#') {
            if (i.id === selector.substr(1)) { // eslint-disable-line no-magic-numbers
                return i;
            }
        }

        // If selector is a data attribute
        if (firstChar === '[') {
            // return attribue and value as an array
            let attributes = selector.substr(1, selector.length - 2).replace(/"/g, '').split('='); // eslint-disable-line no-magic-numbers
            if (i.getAttribute(attributes[0]) === attributes[1]) { // eslint-disable-line no-magic-numbers
                return i;
            }
        }

        // If selector is a tag
        if (i.tagName.toLowerCase() === selector) {
            return i;
        }
    }

    return false;
}
