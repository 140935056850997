import classnames from 'classnames';
import FormField from '../component/field-form';
import PropTypes from 'prop-types';
import ResultField from '../component/field-result';
import React, { Component } from 'react';

const FIELDS = {
    'Field': FormField,
    'Result': ResultField
};

class ResultsForm extends Component {
    constructor(props) {
        super(props);

        const { isValid, validated } = this.props;

        this.state = {
            isValid: isValid,
            validated: validated
        };

        this.fields = {};
    }


    // Mounting
    UNSAFE_componentWillReceiveProps({ isValid, validated }) {
        this.setState({
            isValid: isValid,
            validated: validated
        });
    }


    // Helper
    focusOnField(id) {
        this.fields[id].focus();
    }


    // Render
    renderActions() {
        const { isEditable, isOpen, onEdit } = this.props;

        if (isEditable && isOpen === false) {
            return (
                <div role="group" className="actions">
                    {/* <button type="button" className="button quinary medium">Remove</button> */}
                    <button type="button" className="button secondary medium" onClick={onEdit.bind(this)}>Edit</button>
                </div>
            );
        }
    }

    renderFields() {
        const { fields, fieldType, formId, onFieldFocus, showField } = this.props;
        const FieldType = FIELDS[fieldType];

        return fields.map((props, index) => {
            // This function is passed as a prop to hide specific fields
            if (showField(props)) {
                return (
                    <FieldType
                        key={index}
                        ref={(field) => { this.fields[props.id] = field; }}
                        formId={formId}
                        onInputFocus={onFieldFocus}
                        {...props}/>
                );
            }
        });
    }

    renderForm() {
        const { isEditable, isOpen } = this.props;

        if (isOpen && isEditable) {
            return (
                <div className="section-content">
                    <div className="form">
                        <div role="group" className="fieldset">{this.renderFields()}</div>
                    </div>
                </div>
            );
        }
    }

    renderHeader() {
        return (
            <header role="header" className="section-intro" id={this.props.id}>
                <h3 className="title || thin">{this.props.title}</h3>
                {this.renderActions()}
            </header>
        );
    }

    render() {
        const { id, isOpen, isShowing } = this.props;
        const { isValid, validated } = this.state;
        const sectionClass = classnames('page-section is-results', {
            'is-open': isOpen,
            'is-valid': validated && isValid,
            'is-invalid': validated && isValid === false
        });

        if (isShowing) {
            return (
                <section className={sectionClass} id={id}>
                    {this.renderHeader()}
                    {this.renderForm()}
                </section>
            );
        }

        return null;
    }
}

ResultsForm.propTypes = {
    fields: PropTypes.array.isRequired,
    fieldType: PropTypes.string,
    formId: PropTypes.string,
    id: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    isOpen: PropTypes.bool,
    isShowing: PropTypes.bool,
    isValid: PropTypes.bool,
    onEdit: PropTypes.func,
    onFieldFocus: PropTypes.func,
    showField: PropTypes.func,
    title: PropTypes.string.isRequired,
    validated: PropTypes.bool
};

ResultsForm.defaultProps = {
    fieldType: 'Field',
    isEditable: true,
    isOpen: true,
    isShowing: true,
    isValid: false,
    onEdit: function() {}, // eslint-disable-line
    scrollIntoView: () => null,
    showField: function() { return true; }, // eslint-disable-line
    validated: false
};

export default ResultsForm;
